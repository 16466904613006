import e from "express";
import { javascriptSDKs, javaSDKs, pythonSDKs, VALID_OTP_REGEX } from "../constants";

declare global {
    interface Window {
        stAnalytics: any;
        gtag: (command: string, action: string, params: object) => void;
    }
}

export function isMobileDevice(): boolean {
    let width = window.innerWidth;
    if (width < 480) {
        return true;
    }
    return false;
}

export function isMobileOrTabletDevice(): boolean {
    let width = window.innerWidth;
    if (width < 1025) {
        return true;
    }
    return false;
}

export function emailHasCorrectFormat(email: string): boolean {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function otpHasCorrectFormat(otp: string): boolean {
    return VALID_OTP_REGEX.test(otp);
}

export function isValidName(name: string): boolean {
    name = name.trim();
    return name.indexOf(" ") !== -1;
}

export function dockerVersionAvailablility(currentVersion: string, docType: string, databaseId: string) {
    const docPrefixForDockerAndGit = docType === "pro" ? "-pro" : "";
    // check if version is greater than 1.0, if not
    if (
        parseInt(currentVersion.split(".").join("")) <= 10 ||
        (databaseId === "mongodb" && currentVersion === "1.1") ||
        (databaseId === "sqlite" && (currentVersion === "1.1" || currentVersion === "2.0"))
    ) {
        return {
            dockerCommand: "",
            gitUrl: "",
            availability: false
        };
    } else {
        return {
            dockerCommand: `docker pull supertokens/supertokens-${databaseId}${docPrefixForDockerAndGit}:${currentVersion}`,
            gitUrl: `https://github.com/supertokens/supertokens-docker-${databaseId}${docPrefixForDockerAndGit}/tree/${currentVersion}/README.md`,
            availability: true
        };
    }
}

export function getAnalytics() {
    return new Promise((res, rej) => {
        let numberOfRetries = 20;
        const analytics = window.stAnalytics;
        if (analytics) {
            res(analytics.getInstance());
            return;
        }

        let interval = setInterval(() => {
            const stAnalytics = window.stAnalytics;
            numberOfRetries--;
            if (stAnalytics) {
                clearInterval(interval);
                res(stAnalytics.getInstance());
                return;
            }
            if (numberOfRetries < 0) {
                clearInterval(interval);
                rej("Already waited for 2 seconds");
            }
        }, 100);
    });
}

export async function getCurrentUserId(): Promise<string> {
    try {
        const stInstance: any = await getAnalytics();
        return stInstance.getUserId();
    } catch (error) {
        return "";
    }
}

export function sendButtonAnalytics(eventName: string, version = "v5", options?: Object) {
    getAnalytics().then((stAnalytics: any) =>
        stAnalytics.sendEvent(
            eventName,
            {
                type: "button_click",
                ...options
            },
            version
        )
    );
}

export function identifyUser(properties: Record<string, unknown>) {
    getAnalytics().then((stAnalytics: any) => {
        stAnalytics.identifyAmplitudeUser && stAnalytics.identifyAmplitudeUser(properties);
    });
}

export function sendGenericAnalyticsEvent(eventName: string) {
    getAnalytics().then((stAnalytics: any) => stAnalytics.sendGenericEvent(eventName));
}

export async function sendGenericAnalyticsEventAsync(eventName: string) {
    try {
        const stAnalytics = (await getAnalytics()) as any;
        await stAnalytics.sendGenericEvent(eventName);
    } catch (error) {
        throw error;
    }
}

export const sendUserSettingsAnalytics = (eventName: string, payload: Record<string, unknown>, version = "v1") => {
    getAnalytics().then((stAnalytics: any) => {
        if (stAnalytics === undefined) {
            console.log("mocked event send:", eventName, version, payload);
            return;
        }
        stAnalytics.sendEvent(
            eventName,
            {
                type: "user_settings",
                ...payload
            },
            version
        );
    });
};

export const sendAuthAnalytics = (eventName: string, payload: Record<string, unknown>, version = "v1") => {
    getAnalytics().then((stAnalytics: any) => {
        if (stAnalytics === undefined) {
            console.log("mocked event send:", eventName, version, payload);
            return;
        }
        stAnalytics.sendEvent(
            eventName,
            {
                type: "auth",
                ...payload
            },
            version
        );
    });
};

export const sendOrganizationAnalytics = (eventName: string, payload: Record<string, unknown>, version = "v1") => {
    getAnalytics().then((stAnalytics: any) => {
        if (stAnalytics === undefined) {
            console.log("mocked event send:", eventName, version, payload);
            return;
        }
        stAnalytics.sendEvent(
            eventName,
            {
                type: "organization",
                ...payload
            },
            version
        );
    });
};

export function sendAsyncButtonAnalytics(eventName: string, version = "v1", options?: Object) {
    setTimeout(() => {
        sendButtonAnalytics(eventName, version, options);
    }, 0);
}

export function sendPageViewAnalytics(eventName: string, version = "v5", options?: Object) {
    getAnalytics().then((stAnalytics: any) =>
        stAnalytics.sendEvent(
            eventName,
            {
                type: "page_view",
                ...options
            },
            version
        )
    );
}

export function sendScrollAnalytics(eventName: string, version = "v5", options?: Object) {
    getAnalytics().then((stAnalytics: any) =>
        stAnalytics.sendEvent(
            eventName,
            {
                type: "scroll",
                ...options
            },
            version
        )
    );
}

export function isDev() {
    return window.location.host.startsWith("localhost");
}
export function isTestEnv() {
    return window.location.host === "test.supertokens.com";
}

export function getCurrentDomain() {
    const { hostname, port } = window.location;
    return port ? hostname + ":" + port : hostname;
}

export function getMajorVersion(strVersion: string) {
    return parseInt(strVersion.split(".")[0]);
}

// used for Header analytics
export function getPathAndSelectedPage() {
    const currentPageToEventData: { [path: string]: string } = {
        home: "home",
        blog: "blog",
        pricing: "pricing",
        contribute: "contribute",
        docs: "documentation",
        "product-roadmap": "product_roadmap",
        "use-oss": "use_oss",
        "dashboard-saas": "dashboard_saas",
        signup: "signup"
    };

    let path;
    let pageSelected;
    if (window.location.pathname.toLowerCase() === "/") {
        path = "home";
    } else {
        path = window.location.pathname.split("/")[1];
    }
    pageSelected = currentPageToEventData[path.toLowerCase()];
    return { path, pageSelected, currentPageToEventData };
}

export function copyTextToClipboard(str: string) {
    return new Promise((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(str)
                .then(() => resolve(true))
                .catch(err => reject(err));
        } else {
            const el = document.createElement("textarea");
            el.value = str;
            el.setAttribute("readonly", "");
            el.style.position = "absolute";
            el.style.left = "-9999px";
            document.body.appendChild(el);
            let selection = document.getSelection();
            let selected;
            if (selection !== null) {
                selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
            }
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            selection = document.getSelection();
            if (selection !== null && selected !== null && selected !== undefined) {
                selection.removeAllRanges();
                selection.addRange(selected);
            }
            resolve(true);
        }
    });
}

export function mapStatusToPillColorClass(status: "Active" | "Stopped" | "Restarting") {
    const colorNameMap: Record<string, string> = {
        active: "green",
        stopped: "red",
        restarting: "yellow"
    };

    return colorNameMap[status.toLowerCase()];
}

export const monthData = [
    {
        name: "January",
        shortName: "Jan"
    },
    {
        name: "February",
        shortName: "Feb"
    },
    {
        name: "March",
        shortName: "Mar"
    },
    {
        name: "April",
        shortName: "Apr"
    },
    {
        name: "May",
        shortName: "May"
    },
    {
        name: "June",
        shortName: "Jun"
    },
    {
        name: "July",
        shortName: "Jul"
    },
    {
        name: "August",
        shortName: "Aug"
    },
    {
        name: "September",
        shortName: "Sep"
    },
    {
        name: "October",
        shortName: "Oct"
    },
    {
        name: "November",
        shortName: "Nov"
    },
    {
        name: "December",
        shortName: "Dec"
    }
];

export function getLocationPath() {
    if (window.top !== null) {
        return window.top.location.pathname.toLowerCase();
    }

    return window.location.pathname.toLowerCase();
}

export function getLocationHref() {
    if (window.top !== null) {
        return window.top.location.href.toLowerCase();
    }

    return window.location.href.toLowerCase();
}

export function getSDKLanguage(sdkName: string | undefined): string {
    sdkName = sdkName === undefined ? "" : sdkName;
    if (javascriptSDKs.includes(sdkName)) {
        return "javascript";
    }

    if (pythonSDKs.includes(sdkName)) {
        return "python";
    }

    if (javaSDKs.includes(sdkName)) {
        return "java";
    }

    return "unknown";
}

export function getStripeRedirectURLs(path?: string) {
    const currentLocation = `${window.location.origin}${path ? path : window.location.pathname}`;
    return {
        cancelURL: currentLocation + "?paymentSuccess=false",
        successURL: currentLocation + "?paymentSuccess=true"
    };
}

export function sendEventToGoogleAnalytics(eventName: string, payload: Record<string, unknown>) {
    if (isDev() || isTestEnv()) {
        console.log("Sending a google analytics event", { eventName, payload });
        return;
    }
    try {
        if (typeof window !== "undefined" && "gtag" in window) {
            const gtagWindow = window as Window & typeof globalThis;
            gtagWindow.gtag("event", eventName, payload);
        }
    } catch (e) {
        // do nothing
    }
}
