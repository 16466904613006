import React, { ReactNode, createContext, useEffect, useState } from "react";
import { UserInfoResponse, getUserInformation } from "../api/user/info";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { identifyUser } from "../utils";

type Context = {
    user: UserInfoResponse | null;
    updateUser: (user: UserInfoResponse | null) => void;
    isLoadingUser: boolean;
};
export const UserContext = createContext<Context | undefined>(undefined);
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<UserInfoResponse | null>(null);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const session = useSessionContext();

    function updateUser(user: UserInfoResponse | null) {
        setUser(user);
    }
    async function fetchUserDetails() {
        try {
            setIsLoadingUser(true);
            const user = await getUserInformation();
            setUser(user);
            if (user) {
                identifyUser({
                    email: user.email,
                    name: user.name,
                    paymentInfoShared: user.paymentInfoShared,
                    loginMethod: user.loginMethod.type
                });
            }
        } catch (e) {}
        setIsLoadingUser(false);
    }
    useEffect(() => {
        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (session.loading) return;

        if (!session.doesSessionExist) {
            // if no session, reset user
            setUser(null);
        } else if (session.doesSessionExist && !user) {
            fetchUserDetails();
        }
    }, [session]);

    return <UserContext.Provider value={{ user, updateUser, isLoadingUser }}>{children}</UserContext.Provider>;
};
