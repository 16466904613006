import * as React from "react";
import { CoreSelectionProps } from "./useOssTypes";

export function CoreSelection({ cores, setSelectedCore }: CoreSelectionProps) {
    const [option, setOption] = React.useState(cores[0]);

    React.useEffect(() => {
        const defaultCore = cores[0];
        setOption(defaultCore);
        setSelectedCore(defaultCore);
    }, [cores, setOption, setSelectedCore]);

    const handleCoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const optionSelected = (e.target as HTMLSelectElement).value;
        if (optionSelected !== null) {
            setOption(optionSelected);
            setSelectedCore(optionSelected);
        }
    };

    return (
        <div className="useoss-core-select-container">
            <label htmlFor="core-selection" className="grey-text item-selection__heading">
                Please select core version:
            </label>
            <select
                className="useoss-core-select-options"
                id="core-selection"
                value={option}
                onChange={handleCoreChange}
            >
                {cores !== undefined &&
                    cores.map((core: string) => {
                        return (
                            <option key={core} value={core}>
                                {core}
                            </option>
                        );
                    })}
            </select>
        </div>
    );
}
