import React, { useState, useEffect, MutableRefObject } from "react";

import "../../style/saas-tooltip.module.css";

type Props = {
    elementRef?:
        | React.RefObject<HTMLImageElement>
        | React.RefObject<HTMLDivElement>
        | MutableRefObject<null>;
    elementId?: string;
    children: React.ReactNode;
    trigger: "click" | "hover";
    cancellable?: boolean;
    showTooltipCallback?: Function;
    maxWidth?: string;
    css?: React.CSSProperties;
    minHeight?: string;
    minWidth?: string;
};

const SaasTooltip = (props: Props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPos, setTooltipPos] = useState({
        left: 0,
        top: 0
    });
    const [isUserHoveringTooltip, setIsUserHoveringTooltip] = useState(false);

    const getElement = (): any => {
        let element = null;

        if (props.elementRef !== undefined && props.elementRef.current !== undefined) {
            element = props.elementRef.current;
        } else if (props.elementId !== undefined) {
            element = document.getElementById(props.elementId);
        }

        return element;
    };

    useEffect(() => {
        const element = getElement();

        if (element !== null && element !== undefined) {
            if (props.trigger === "click") {
                element.addEventListener("click", showTooltipOnEvent);
            } else if (props.trigger === "hover") {
                element.addEventListener("mouseover", showTooltipOnEvent);
                if (!props.cancellable) {
                    element.addEventListener("mouseout", hideTooltip);
                }
            }
        }

        return () => {
            const element = getElement();

            if (element) {
                if (props.trigger === "click") {
                    element.removeEventListener("click", showTooltipOnEvent);
                } else if (props.trigger === "hover") {
                    element.removeEventListener("mouseover", showTooltipOnEvent);
                    if (!props.cancellable) {
                        element.removeEventListener("mouseout", hideTooltip);
                    }
                }
            }
        };
    }, [props.elementRef, props.elementId, showTooltip]);

    const showTooltipOnEvent = () => {
        if (showTooltip && props.trigger === "click") {
            hideTooltip();
        } else {
            const element = getElement();
            calculateTooltipPosition();
            setShowTooltip(true);
            if (props.showTooltipCallback) props.showTooltipCallback();

            if (element !== undefined) {
                element.classList.add("active");
            }
        }
    };

    const hideTooltip = () => {
        // We add this delay to make sure that there is enough time to detect if the user is hovering the tooltip
        setTimeout(() => {
            setShowTooltip(false);
            if (props.trigger === "click") {
                setIsUserHoveringTooltip(false);
            }
            const element = getElement();
            if (element !== undefined) {
                element.classList.remove("active");
            }
        }, 100);
    };

    const calculateTooltipPosition = () => {
        const element = getElement();
        if (element !== undefined) {
            const { left, top, height, width } = element.getBoundingClientRect();

            const newLeft = window.scrollX + left + width + 10;
            const newTop = window.scrollY + top + height / 2;

            setTooltipPos({
                left: newLeft,
                top: newTop
            });
        }
    };

    const shouldShow = showTooltip || isUserHoveringTooltip;

    return (
        <>
            <div
                className="saas-tooltip"
                style={{
                    ...tooltipPos,
                    opacity: shouldShow ? 1 : 0,
                    pointerEvents: shouldShow ? "all" : "none",
                    ...props.css,
                    maxWidth: props.maxWidth ? props.maxWidth : "none",
                    minHeight: props.minHeight,
                    minWidth: props.minWidth
                }}
                onMouseEnter={() => {
                    if (showTooltip) {
                        setIsUserHoveringTooltip(true);
                    }
                }}
                onMouseLeave={() => setIsUserHoveringTooltip(false)}
            >
                {(props.trigger === "click" || props.cancellable) && (
                    <button className="saas-tooltip__close" onClick={hideTooltip}>
                        <img src="static/assets/dashboard/close-icon.png" alt="Close tooltip" />
                    </button>
                )}

                {props.children}
            </div>
            {shouldShow && props.cancellable && <div onClick={hideTooltip} className="saas-tooltip__close-bg"></div>}
        </>
    );
};

export default SaasTooltip;
