import { getAnalytics } from "../../utils";

const createAppOrEnvAnalytics = {
    //when users clicks on create new Env/App button on dashboard.
    createNewAppOrEnv: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env",
                {
                    type: "button_click",
                    button_text: "Create New Env / App"
                },
                "v5"
            )
        );
    },
    //when user clicks on continue button on create new app/env flow.
    createNewAppOrEnvContinue: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env_continue",
                {
                    type: "button_click",
                    button_text: "Continue"
                },
                "v5"
            )
        );
    },
    //when user clicks on go back button on create new app/env flow.
    createNewAppOrEnvGoBack: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env_go_back",
                {
                    type: "button_click",
                    button_text: "Go Back"
                },
                "v5"
            )
        );
    },
    //when user clicks on copy button to copy curl command to create app/env via cli
    createNewAppOrEnvCommandCopy: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env_command_copy",
                {
                    type: "button_click",
                    button_text: "copy"
                },
                "v5"
            )
        );
    },
    //when user clicks create now button
    createNewAppOrEnvCreateNow: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env_create_now",
                {
                    type: "button_click",
                    button_text: "Create Now"
                },
                "v5"
            )
        );
    },
    //when closes create new app modal
    createNewAppOrEnvModalClose: (step: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_create_new_app_or_env_close",
                {
                    type: "button_click",
                    button_text: "close",
                    step
                },
                "v5"
            )
        );
    },
    //when clicked on the delete button for a specific app on dashboard
    deleteAppOrEnv: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_delete_app_or_env",
                {
                    type: "button_click",
                    button_text: "Delete"
                },
                "v5"
            )
        );
    },
    //when clicked on the delete button for a specific app on dashboard
    deleteAppOrEnvModalClose: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_delete_app_or_env_modal_close",
                {
                    type: "button_click",
                    button_text: "close"
                },
                "v5"
            )
        );
    },
    //when clicked on the delete button for a specific app on dashboard
    deleteAppOrEnvModalCancel: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_delete_app_or_env_modal_cancel",
                {
                    type: "button_click",
                    button_text: "Cancel"
                },
                "v5"
            )
        );
    },
    //when clicked on the delete button for a specific app on dashboard
    deleteAppOrEnvDeleteConfirmation: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_delete_app_or_env_modal_delete_confirmation",
                {
                    type: "button_click",
                    button_text: "Yes, Delete"
                },
                "v5"
            )
        );
    }
};

export default createAppOrEnvAnalytics;
