import { GetCompatibilityResponse } from "../api/compatibility";
import getSupportedCores from "../api/plugin/dependency/cores";

export async function fetchAllAvailableCores(plugin: "mysql" | "postgresql" | "mongodb"): Promise<string[]> {
    let cores = (await getSupportedCores(plugin, "FREE")).cores;
    return cores;
}

export function isLoginSupported(compatibilityData: GetCompatibilityResponse, db: string): boolean {
    if (compatibilityData.coreToDriver !== undefined) {
        for (const [key, value] of Object.entries(compatibilityData.coreToDriver)) {
            if (parseFloat(key) >= 3.0 && value.length > 0 && db !== "mongodb") {
                return true;
            }
        }
    }
    return false;
}

export function changeSdkDataOrder(sdkData: any) {
    let oldDriversArr = sdkData.drivers;
    const nodeItem = oldDriversArr.find((driver: { id: string }) => driver.id === "node");
    const newDriversArr = oldDriversArr.filter((driver: { id: string }) => driver.id !== "node");
    if (nodeItem !== undefined) {
        newDriversArr.unshift(nodeItem);
        sdkData.drivers = newDriversArr;
    }
}

export function changeDbDataOrder(dbData: any) {
    let oldPlugins = dbData.plugins;
    const mySqlItem = oldPlugins.find((plugin: { id: string }) => plugin.id === "mysql");
    const postgresItem = oldPlugins.find((plugin: { id: string }) => plugin.id === "postgresql");
    const newPlugins = oldPlugins.filter(
        (plugin: { id: string }) => plugin.id !== "mysql" && plugin.id !== "postgresql"
    );
    if (postgresItem !== undefined) {
        newPlugins.unshift(postgresItem);
    }
    if (mySqlItem !== undefined) {
        newPlugins.unshift(mySqlItem);
    }
    dbData.plugins = newPlugins;
}

export function getSdkLabel(sdkData: any, sdkId: string) {
    for (const sdkItem of sdkData) {
        if (sdkItem.id === sdkId) {
            return sdkItem.displayName;
        }
    }
}

export function getDbLabel(dbData: any, dbId: string) {
    for (const dbItem of dbData) {
        if (dbItem.id === dbId) {
            return dbItem.displayName;
        }
    }
}

export function getCurlCommand(dbId: string, platformId: string, coreVersion: string): string {
    return `curl 'https://api.supertokens.com/0/user/app/download?pluginName=${dbId}&os=${platformId}&core=${coreVersion}&api-version=0' --output supertokens.zip`;
}

/**
 * Version comparison method, which returns the max version from two versions
 * For reference: https://github.com/supertokens/supertokens-node/blob/master/lib/ts/utils.ts
 */
export function maxVersion(version1: string, version2: string): string {
    let splittedv1 = version1.split(".");
    let splittedv2 = version2.split(".");
    let minLength = Math.min(splittedv1.length, splittedv2.length);
    for (let i = 0; i < minLength; i++) {
        let v1 = Number(splittedv1[i]);
        let v2 = Number(splittedv2[i]);
        if (v1 > v2) {
            return version1;
        } else if (v2 > v1) {
            return version2;
        }
    }
    if (splittedv1.length >= splittedv2.length) {
        return version1;
    }
    return version2;
}

/**
 * Compares two versions and returns boolean
 * @param version1 First version string
 * @param version2 Second version string
 * @returns boolean true if version1 >= version2, else returns false
 */
export function compareVersions(version1: string, version2: string): boolean {
    if (maxVersion(version1, version2) === version1) {
        return true;
    }

    return false;
}
