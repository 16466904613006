import React from "react";

import Blog from "./assets/resources-header/blog";
import Customers from "./assets/resources-header/customers";
import Roadmap from "./assets/resources-header/roadmap";
import Support from "./assets/resources-header/support";

import { getPathAndSelectedPage, sendButtonAnalytics } from "../../utils";

import "../../style/resources-header-dropdown.module.css";

const data = [
    {
        title: "Roadmap",
        href: "/product-roadmap",
        icon: Roadmap
    },
    {
        title: "Blog",
        href: "/blog",
        icon: Blog
    },
    {
        title: "Customers",
        href: "/customers",
        icon: Customers
    },
    {
        title: "Support",
        href: "/discord",
        icon: Support
    }
];
export default function ResourcesHeaderDropdown({
    type,
    closeDropdown,
    theme
}: {
    type?: "mobile" | "desktop";
    closeDropdown?: () => void;
    theme?: "light" | "dark";
}) {
    function handleItemClick(itemPath: string) {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== itemPath && pageSelected !== undefined) {
            sendButtonAnalytics("button_header_resources_page", "v5", {
                page_selected: pageSelected
            });
        }
        if (closeDropdown) {
            closeDropdown();
        }
    }
    return (
        <div
            className={`resources-container ${type === "mobile" ? "resources-mobile" : ""} ${
                theme === "light" ? "light-theme-resources-container" : ""
            }`}
        >
            {data.map(item => (
                <a
                    key={item.title}
                    className="resources-item"
                    href={item.href}
                    onClick={() => handleItemClick(item.href)}
                >
                    <div className="resources-item_icon">{<item.icon theme={theme} />}</div>
                    <div className="resources-item_title">{item.title}</div>
                </a>
            ))}
        </div>
    );
}
