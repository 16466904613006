import React from "react"

import authAnalytics from "./AuthAnalytics"

import "../style/auth-customization-form.module.css"

export enum ThemeMode {
  Dark = "dark",
  Light = "light"
}

interface IThemeToggleProps{
  themeMode: ThemeMode
}

const themeOptions = [
  {
    value: ThemeMode.Light,
    iconURI: '/static/assets/themeModeLightIcon.svg'
  },
  {
    value: ThemeMode.Dark,
    iconURI: '/static/assets/themeModeDarkIcon.svg'
  },
]

const CommonContainerWrapper: React.FC = (props) => {
  const themeMode:ThemeMode = localStorage.getItem("themeMode") as ThemeMode || ThemeMode.Dark;

  return (
    <div>
      <ThemeToggler themeMode={themeMode}/>
      <div className={`auth-customization-form ${themeMode}`}>
        <div className="auth-customization-form__form-panel">
          <div className="auth-customization-form__form-panel__flex grid">
            {props.children}
            <a
              href="/discord"
              className="auth-customization-form__form-panel__flex__discord"
              target="_blank"
              onClick={authAnalytics.joinDiscordLinkClickAction}
            >
              <img src={`/static/assets/discord-icon.svg`} alt="Join our Discord" />
              <span className="auth-customization-form__form-panel__flex__discord__text">
                Join us on <span className="discord-white">Discord</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ThemeToggler:React.FC<IThemeToggleProps> = ({themeMode}) => {

  const toggleTheme = () => {
    localStorage.setItem("themeMode", themeMode === "dark" ? "light" : "dark");
    window.location.reload();
  }

  return(<div id="toggle-container" role="button" onClick={toggleTheme} className={themeMode}>
    {themeOptions.map(({value: theme, iconURI})=><div className={"icon-container" + (theme === themeMode ? " selected " : "")} key={theme}>
      <img className="icon" alt={theme} src={iconURI} />
    </div>)}
  </div>)
}

export default CommonContainerWrapper
