import React from "react";

import "../../style/sdk-docs-guides-banner.module.css";

const SdkDocsBanner = () => {
  return (
    <>
      <p>
        These are the SDK reference docs. If you’re looking for docs to implement SuperTokens, you might want to check out the <a href="/docs/guides">
          User Guides
          <span className="sdk-banner-image-container">
            <img src="/static/assets/sdk-docs-guides-banner-books-icon.svg" alt="" />
          </span>
        </a>

      </p>
    </>
  );
};

export default SdkDocsBanner;