function getMySQLContent(dockerVersion: string) {
    return `
version: '3'
  
services:
  db:
    image: mysql:latest
    environment:
      MYSQL_ROOT_PASSWORD: root
      MYSQL_USER: supertokens_user
      MYSQL_PASSWORD: somePassword
      MYSQL_DATABASE: supertokens
    ports:
      - 3306:3306
    networks:
      - app_network
    restart: unless-stopped
    healthcheck:
      test: [ "CMD", "mysqladmin", "ping", "-h", "localhost" ]
      timeout: 20s
      retries: 10

  supertokens:
    image: registry.supertokens.io/supertokens/supertokens-mysql${dockerVersion}
    depends_on:
      db:
        condition: service_healthy
    ports:
      - 3567:3567
    environment:
      MYSQL_CONNECTION_URI: mysql://supertokens_user:somePassword@db:3306/supertokens
    networks:
      - app_network
    restart: unless-stopped
    healthcheck:
      test: >
        bash -c 'exec 3<>/dev/tcp/127.0.0.1/3567 && echo -e "GET /hello HTTP/1.1\\r\\nhost: 127.0.0.1:3567\\r\\nConnection: close\\r\\n\\r\\n" >&3 && cat <&3 | grep "Hello"'
      interval: 10s
      timeout: 5s
      retries: 5

networks:
  app_network:
    driver: bridge
  `.trim();
}

function getPostgresContent(dockerVersion: string) {
    return `
version: '3'
  
services:
  db:
    image: 'postgres:latest'
    environment:
      POSTGRES_USER: supertokens_user 
      POSTGRES_PASSWORD: somePassword 
      POSTGRES_DB: supertokens
    ports:
      - 5432:5432
    networks:
      - app_network
    restart: unless-stopped
    healthcheck:
      test: ['CMD', 'pg_isready', '-U', 'supertokens_user', '-d', 'supertokens']
      interval: 5s
      timeout: 5s
      retries: 5

  supertokens:
    image: registry.supertokens.io/supertokens/supertokens-postgresql${dockerVersion}
    depends_on:
      db:
        condition: service_healthy
    ports:
      - 3567:3567
    environment:
      POSTGRESQL_CONNECTION_URI: "postgresql://supertokens_user:somePassword@db:5432/supertokens"
    networks:
      - app_network
    restart: unless-stopped
    healthcheck:
      test: >
        bash -c 'exec 3<>/dev/tcp/127.0.0.1/3567 && echo -e "GET /hello HTTP/1.1\\r\\nhost: 127.0.0.1:3567\\r\\nConnection: close\\r\\n\\r\\n" >&3 && cat <&3 | grep "Hello"'
      interval: 10s
      timeout: 5s
      retries: 5

networks:
  app_network:
    driver: bridge
  `.trim();
}

type fileContentByDBNameType = Record<string, string>;

const dockerComposeFileContents: fileContentByDBNameType = {
    postgresql: getPostgresContent(""),
    mysql: getMySQLContent("")
};

export function getDockerComposeFileContentsForDb(db: string, dockerVersion: string): string {
    if (db === "postgresql") {
        return getPostgresContent(dockerVersion);
    }

    if (db === "mysql") {
        return getMySQLContent(dockerVersion);
    }

    return "";
}

export default dockerComposeFileContents;
