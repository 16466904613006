import React, { useState } from "react";
import getIcon from "./common/getDropdownIcon";
import "../../style/custom-header-dropdown.module.css";

export default function CustomHeaderDropdown({
    children,
    title,
    className,
    type,
    close,
    theme = "dark"
}: {
    children: React.ReactNode;
    title: string;
    className?: string;
    type?: "mobile" | "desktop";
    close?: () => void;
    theme?: "light" | "dark";
}) {
    const [isOpen, setIsOpen] = useState(false);

    switch (type) {
        case "mobile": {
            return (
                <div className={`${"custom_header_dropdown_mobile"} ${isOpen ? "open" : ""}`}>
                    <div
                        className={"custom_header_dropdown_target_mobile"}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <span className={"custom_header_dropdown_target_mobile_title"}>{title}</span>
                        <span className={"custom_header_dropdown_icon_mobile"}>{getIcon(isOpen)}</span>
                    </div>

                    <div className={`${"custom_header_dropdown_content_mobile"} ${isOpen ? "open" : ""}`}>
                        <div className={"custom_header_dropdown_content_inner_mobile"}>
                            {" "}
                            {React.Children.map(children, child =>
                                React.isValidElement(child)
                                    ? React.cloneElement(child as React.ReactElement<{ closeDropdown?: () => void }>, {
                                          closeDropdown: () => {
                                              setIsOpen(false);
                                              if (close) {
                                                  close();
                                              }
                                          }
                                      })
                                    : child
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        default: {
            return (
                <div
                    className={`${"custom_header_dropdown"} ${className || ""}`}
                    onMouseOver={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                >
                    <span
                        className={`custom_header_dropdown_target ${theme === "light" ? "light-theme-target" : ""} ${
                            isOpen ? "open" : ""
                        }`}
                    >
                        {title}
                    </span>
                    <span className={"custom_header_dropdown_icon"}>{getIcon(isOpen, theme)}</span>
                    <div className={`${"custom_header_dropdown_content"} ${isOpen ? "open" : ""}`}>
                        <div className={"custom_header_dropdown_content_inner"}>
                            {React.Children.map(children, child =>
                                React.isValidElement(child)
                                    ? React.cloneElement(
                                          child as React.ReactElement<{
                                              closeDropdown?: () => void;
                                              theme: "light" | "dark";
                                          }>,
                                          {
                                              closeDropdown: () => {
                                                  setIsOpen(false);
                                                  if (close) {
                                                      close();
                                                  }
                                              },
                                              theme
                                          }
                                      )
                                    : child
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
