import React, { createContext, PropsWithChildren, useReducer } from 'react'
import { ToastNotificationProps } from '../components/elements/Notification'

type PopupAction<PopupType> =
  | {
      type: 'add'
      payload: PopupType
    }
  | {
      type: 'remove'
      itemId: number
    }

type PopupContextItem<PopupType> = PopupType & {id: number}

export type ToastNotificationContextItem = PopupContextItem<ToastNotificationProps>

export type PopupContentContextProps = {
  toasts: ToastNotificationContextItem[]
  showToast: (toast: ToastNotificationProps) => void
  removeToast: (toastId: number) => void,
}

export function toastNotificationReducer(
  state: ToastNotificationContextItem[],
  action: PopupAction<ToastNotificationProps>
): ToastNotificationContextItem[] {
  if (action.type === 'add') {
    return [...state, { ...action.payload, id: new Date().getTime() }]
  }
  if (action.type === 'remove') {
    return state.filter((toast) => toast.id !== action.itemId)
  }
  return state
}

const POPUP_CONTENT_CONTEXT_DEFAULT_VALUE: PopupContentContextProps = {
  toasts: [],
  showToast: () => { },
  removeToast: () => { },
}
/** 
 * Context that handle the queue of popup elements e.g. 
 ** ToastNotification, 
 ** Modal (not yet implemented using context) 
 */
export const PopupContentContext = createContext<PopupContentContextProps>(POPUP_CONTENT_CONTEXT_DEFAULT_VALUE)

export const PopupContentContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [toasts, dispatchToast] = useReducer(toastNotificationReducer, [])

  const showToast = (payload: ToastNotificationProps) =>
    dispatchToast({
      type: 'add',
      payload,
    })

  const removeToast = (itemId: number) => dispatchToast({ type: 'remove', itemId })

  return (
    <PopupContentContext.Provider value={{ toasts, showToast, removeToast }}>
      {children}
    </PopupContentContext.Provider>
  )
}
