import React from "react";

import aboutUs from "./assets/company-header/about-us";
import careers from "./assets/company-header/careers";
import { sendAsyncButtonAnalytics } from "../../utils";

import "../../style/company-header-dropdown.module.css";

const data = [
    {
        title: "About Us",
        href: "/about-us",
        icon: aboutUs,
        eventName: "button_header_company_aboutUs"
    },
    {
        title: "Careers",
        href: "/careers",
        icon: careers,
        eventName: "button_header_company_careers"
    }
];
export default function CompanyHeaderDropdown({
    type,
    closeDropdown,
    theme
}: {
    type?: "mobile" | "desktop";
    closeDropdown?: () => void;
    theme?: "light" | "dark";
}) {
    function handleItemClick(eventName: string) {
        sendAsyncButtonAnalytics(eventName, "v1");
        if (closeDropdown) {
            closeDropdown();
        }
    }
    return (
        <div
            className={`company-container ${type === "mobile" ? "company-mobile" : ""} ${
                theme === "light" ? "light-theme-company-container" : ""
            }`}
        >
            {data.map(item => (
                <a
                    key={item.title}
                    className="company-item"
                    href={item.href}
                    onClick={() => handleItemClick(item.eventName)}
                >
                    <div className="company-item_icon">{<item.icon theme={theme} />}</div>
                    <div className="company-item_title">{item.title}</div>
                </a>
            ))}
        </div>
    );
}
