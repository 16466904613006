import * as React from "react";
import { DatabaseSelectionProps } from "./useOssTypes";

export function DatabaseSelection({ selectedSdk, setDb, idPrefix, dbData }: DatabaseSelectionProps) {
    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    const handleTabClick = (e: React.MouseEvent) => {
        e.preventDefault();
        const tabLink = e.target as HTMLElement;
        if (tabLink.dataset.tabIndex) {
            setActiveTabIndex(+tabLink.dataset.tabIndex);
        }
        if (tabLink.dataset.tabKey) {
            const db = tabLink.dataset.tabKey;
            setDb(db);
        }
    };

    return (
        <div className="item-selection">
            <h3 className="grey-text item-selection__heading">Please select your database:</h3>
            <ol className="useoss-tabs">
                {dbData.map((item: { id: string; displayName: string }, index: number) => {
                    const linkId = `${idPrefix}-${selectedSdk}-${item.id}-1`;
                    return (
                        <li
                            key={item.id}
                            className={`useoss-tabs__tab ${index === activeTabIndex ? "useoss-tabs__tab--active" : ""}`}
                        >
                            <a
                                id={linkId}
                                href="#"
                                onClick={handleTabClick}
                                data-tab-index={index}
                                data-tab-key={item.id}
                            >
                                {item.displayName}
                            </a>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
}
