import axios from "axios";

export async function getDocVersions(
    sdk: string
): Promise<{
    versions: string[];
}> {
    const URL = window.location.origin + `/sdk/versions?sdk=${sdk}`;

    try {
        const result = await axios.get(URL);
        return result.data;
    } catch (err) {
        throw err;
    }
}
