import { getLocationPath, sendButtonAnalytics, sendScrollAnalytics } from "./utils";

// maps html Ids to event names for analytics
export const idToEventNameMapping: {
    [id: string]: {
        eventName: string;
        metaData?: any;
    };
} = {
    // blog read antcs
    "detecting-session-osw-2020": {
        eventName: "page_blog_article",
        metaData: {
            option_clicked: "detecting-session-hijacking-using-rotating-refresh-tokens-OSW-2020"
        }
    },
    "opportunity-for-supertokens": {
        eventName: "page_blog_lastsection_markasdone"
    },
    "demo-app-button": {
        eventName: "button_home_demoapp_cta"
    },
    "home-sidenav-github": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "github"
        }
    },
    "home-sidenav-discord": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "discord"
        }
    },
    "home-sidenav-twitter": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "twitter"
        }
    },
    "pricing-sidenav-github": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "github"
        }
    },
    "pricing-sidenav-discord": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "discord"
        }
    },
    "pricing-sidenav-twitter": {
        eventName: "button_sidebar_socialmediaprofile",
        metaData: {
            option_clicked: "twitter"
        }
    },
    "cta-404page": {
        eventName: "button_404page_cta",
        metaData: {
            option_clicked: "Go to docs"
        }
    },
    "tab-1-id": {
        eventName: "button_blog_tabs_keyword",
        metaData: {
            option_clicked: "all"
        }
    },
    "tab-2-id": {
        eventName: "button_blog_tabs_keyword",
        metaData: {
            option_clicked: "sessions"
        }
    },
    "tab-3-id": {
        eventName: "button_blog_tabs_keyword",
        metaData: {
            option_clicked: "business"
        }
    },
    "tab-4-id": {
        eventName: "button_blog_tabs_keyword",
        metaData: {
            option_clicked: "miscellaneous"
        }
    },
    "hiring-cta-top": {
        eventName: "button_hiringbanner_cta_top"
    },
    "hiring-cta-top-tab": {
        eventName: "button_hiringbanner_cta_top"
    },
    "hiring-cta-top-landscape": {
        eventName: "button_hiringbanner_cta_top"
    },
    "hiring-cta-top-mobile": {
        eventName: "button_hiringbanner_cta_top"
    },
    "hiring-cta-bottom": {
        eventName: "button_hiringbanner_cta_bottom"
    },
    "hiring-cta-bottom-portrait": {
        eventName: "button_hiringbanner_cta_bottom"
    },
    "hiring-cta-bottom-landscape": {
        eventName: "button_hiringbanner_cta_bottom"
    },
    "hiring-cta-bottom-tab": {
        eventName: "button_hiringbanner_cta_bottom"
    },
    "pricing-link-to-product-roadmap-page": {
        eventName: "button_pricing_comingsoon_productroadmaplink"
    },
    "home-link-to-product-roadmap-page": {
        eventName: "button_home_comingsoon_productroadmaplink"
    },
    copy_command_cli: {
        eventName: "button_landing_cli_command",
        metaData: {
            button_text: "npx create-supertokens-app"
        }
    },
    "getstarted-top": {
        eventName: "button_home_landing_cta",
        metaData: {
            button_text: "Get Started"
        }
    },
    Oauthtalk: {
        eventName: "button_home_conferencetalk"
    },
    "15minutevideo": {
        eventName: "button_home_implementationvideolink"
    },
    "login-1": {
        eventName: "button_home_STfeatures_toggle",
        metaData: {
            option_clicked: "login"
        }
    },
    "sessionmanagement-1": {
        eventName: "button_home_STfeatures_toggle",
        metaData: {
            option_clicked: "session management"
        }
    },
    "pricing-page-cta-landing-top": {
        eventName: "button_pricing_landing_cta",
        metaData: {
            button_text: "Start Implementation",
            option_clicked: "top-section"
        }
    },
    "pricing-page-cta-landing-bottom": {
        eventName: "button_pricing_bottom_cta",
        metaData: {
            button_text: "Start Implementation",
            option_clicked: "bottom-section"
        }
    },
    "download-selfhosted-top": {
        eventName: "button_pricing_selfhosted_cta",
        metaData: {
            button_text: "Download",
            option_clicked: "top-section"
        }
    },
    "download-selfhosted-bottom": {
        eventName: "button_pricing_selfhosted_cta",
        metaData: {
            button_text: "Download",
            option_clicked: "bottom-section"
        }
    },
    "getstarted-SaaS-top": {
        eventName: "button_pricing_SaaS_cta",
        metaData: {
            button_text: "Get Started",
            option_clicked: "top-section"
        }
    },
    "getstarted-SaaS-bottom": {
        eventName: "button_pricing_SaaS_cta",
        metaData: {
            button_text: "Get Started",
            option_clicked: "bottom-section"
        }
    },
    "themesavailable-2": {
        eventName: "button_pricing_STfeatures_links",
        metaData: {
            option_clicked: "themes-available"
        }
    },
    "rotating-refresh-tokens-1": {
        eventName: "button_pricing_STfeatures_links",
        metaData: {
            option_clicked: "rotating-refresh-tokens"
        }
    },
    "getstarted-bottom": {
        eventName: "button_home_bottom_cta",
        metaData: {
            button_text: "Join our discord"
        }
    },
    "comparison-table-true": {
        eventName: "page_pricing_comparisonchart_viewed"
    },
    "testimonial-1": {
        eventName: "button_home_testimonials_toggle"
    },
    "themesavailable-1": {
        eventName: "button_home_STfeatures_links",
        metaData: {
            option_clicked: "themes-available"
        }
    },
    "how-we-manage-sessions": {
        eventName: "button_home_STfeatures_links",
        metaData: {
            option_clicked: "how-we-manage-sessions"
        }
    },
    "github-readme": {
        eventName: "button_home_STfeatures_links",
        metaData: {
            option_clicked: "githubreadme"
        }
    },
    "improve-conversions": {
        eventName: "button_home_comingsoon_toggle",
        metaData: {
            option_clicked: "2-factor auth"
        }
    },
    "user-management": {
        eventName: "button_home_comingsoon_toggle",
        metaData: {
            option_clicked: "magic links"
        }
    },
    "enterprise-ready": {
        eventName: "button_pricing_pricingphilosophy_links",
        metaData: {
            option_clicked: "enterprise-ready"
        }
    },
    "docker-node-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Nodejs"
        }
    },
    "docker-go-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Golang"
        }
    },
    "docker-flask-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Flask"
        }
    },
    "docker-laravel-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Laravel"
        }
    },
    "docker-javalin-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Javalin"
        }
    },
    "docker-fastapi-1": {
        eventName: "button_selfhosted_docker_SDK",
        metaData: {
            option_clicked: "Fast API"
        }
    },
    "docker-node-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-go-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-flask-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-laravel-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-javalin-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-fastapi-mysql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "docker-node-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-go-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-flask-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-laravel-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-javalin-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-fastapi-postgresql-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "docker-node-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "docker-go-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "docker-flask-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "docker-laravel-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "docker-javalin-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "docker-fastapi-mongodb-1": {
        eventName: "button_selfhosted_docker_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "discord-pricing-1": {
        eventName: "button_pricing_contactus",
        metaData: {
            option_clicked: "discord"
        }
    },
    "email-pricing-1": {
        eventName: "button_pricing_contactus",
        metaData: {
            option_clicked: "email us"
        }
    },
    "binary-node-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Nodejs"
        }
    },
    "binary-go-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Golang"
        }
    },
    "binary-flask-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Flask"
        }
    },
    "binary-laravel-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Laravel"
        }
    },
    "binary-javalin-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Javalin"
        }
    },
    "binary-fastapi-1": {
        eventName: "button_selfhosted_binary_SDK",
        metaData: {
            option_clicked: "Fast API"
        }
    },
    "binary-node-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-go-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-flask-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-laravel-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-javalin-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-fastapi-mysql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MySQL"
        }
    },
    "binary-node-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-go-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-flask-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-laravel-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-javalin-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-fastapi-postgresql-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "PostgreSQL"
        }
    },
    "binary-node-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "binary-go-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "binary-flask-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "binary-laravel-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "binary-javalin-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "binary-fastapi-mongodb-1": {
        eventName: "button_selfhosted_binary_database",
        metaData: {
            option_clicked: "MongoDB"
        }
    },
    "link-to-hackernews-post": {
        eventName: "button_home_hackernews_cta",
        metaData: {}
    },
    "email-enterprise-section": {
        eventName: "button_pricing_enterprise_emailme",
        metaData: {}
    },
    "email-discord-section": {
        eventName: "button_pricing_enterprise_discord",
        metaData: {}
    },
    "discord-consultancy": {
        eventName: "button_consultancy_joindiscord",
        metaData: {}
    },
    demo_button_landing: {
        eventName: "button_home_landing_getademo",
        metaData: {
            button_text: "Consult an expert"
        }
    },
    demo_button_bottom: {
        eventName: "button_home_bottom_getademo",
        metaData: {}
    },
    "benefit-tab-1": {
        eventName: "button_home_benefits_tabs",
        metaData: {
            option_clicked: "benefit 1"
        }
    },
    "benefit-tab-2": {
        eventName: "button_home_benefits_tabs",
        metaData: {
            option_clicked: "benefit 2"
        }
    },
    "benefit-tab-3": {
        eventName: "button_home_benefits_tabs",
        metaData: {
            option_clicked: "benefit 3"
        }
    },
    st_vs_keycloak__link: {
        eventName: "button_pricing_comparisons_pdf",
        metaData: {
            option_clicked: "st_vs_keycloak"
        }
    },
    st_vs_auth0__link: {
        eventName: "button_pricing_comparisons_pdf",
        metaData: {
            option_clicked: "st_vs_auth0"
        }
    },
    "consultancy-getstarted-top": {
        eventName: "button_ambassador_landing_cta",
        metaData: {
            button_text: "Join Us"
        }
    },
    "github-repo": {
        eventName: "button_ambassador_github_repo"
    },
    "devrel-person": {
        eventName: "button_ambassador_devrel_link"
    },
    "consultancy-getstarted-bottom": {
        eventName: "button_ambassador_bottom_cta",
        metaData: {
            button_text: "Join the mission"
        }
    },
    "passwordless-launch-cta": {
        eventName: "button_launchbanner_cta",
        metaData: {
            button_text: "learn more"
        }
    },
    "recipe-emailpassword": {
        eventName: "button_home_loginrecipes_toggle",
        metaData: {
            option_clicked: "emailpassword"
        }
    },
    "recipe-thirdpartyemailpassword": {
        eventName: "button_home_loginrecipes_toggle",
        metaData: {
            option_clicked: "thirdpartyemailpassword"
        }
    },
    "recipe-sociallogin": {
        eventName: "button_home_loginrecipes_toggle",
        metaData: {
            option_clicked: "sociallogin"
        }
    },
    "recipe-passwordless": {
        eventName: "button_home_loginrecipes_toggle",
        metaData: {
            option_clicked: "passwordless"
        }
    },
    "demo-emailpassword": {
        eventName: "button_home_loginrecipes_viewdemo",
        metaData: {
            option_clicked: "emailpassword"
        }
    },
    "demo-thirdpartyemailpassword": {
        eventName: "button_home_loginrecipes_viewdemo",
        metaData: {
            option_clicked: "thirdpartyemailpassword"
        }
    },
    "demo-sociallogin": {
        eventName: "button_home_loginrecipes_viewdemo",
        metaData: {
            option_clicked: "sociallogin"
        }
    },
    "techstack-nodejs": {
        eventName: "button_home_sessionmanagement_togglecode",
        metaData: {
            option_clicked: "nodejs"
        }
    },
    "techstack-golang": {
        eventName: "button_home_sessionmanagement_togglecode",
        metaData: {
            option_clicked: "golang"
        }
    },
    "techstack-python": {
        eventName: "button_home_sessionmanagement_togglecode",
        metaData: {
            option_clicked: "python"
        }
    },
    "howitworks-step1": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step1"
        }
    },
    "howitworks-step2": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step2"
        }
    },
    "howitworks-step3": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step3"
        }
    },
    "howitworks-step4": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step4"
        }
    },
    "howitworks-step5": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step5"
        }
    },
    "howitworks-step6": {
        eventName: "button_home_howitworks_steps",
        metaData: {
            step_number: "step6"
        }
    },
    "howitworks-next": {
        eventName: "button_home_howitworks_actionbuttons",
        metaData: {
            action_clicked: "next"
        }
    },
    "howitworks-back": {
        eventName: "button_home_howitworks_actionbuttons",
        metaData: {
            action_clicked: "back"
        }
    },
    "userguide-passwordless": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "passwordless"
        }
    },
    "userguide-emailpassword-sociallogin": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "sociallogin&emailpassword"
        }
    },
    "userguide-onlyemailpassword": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "onlyemailpassword"
        }
    },
    "userguide-onlysociallogin": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "onlysociallogin"
        }
    },
    "userguide-onlysessionmanagement": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "onlysessionmanagement"
        }
    },
    "userguide-thirdpartpasswordless": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "thirdpartypasswordless"
        }
    },
    "userguide-phonenumberpassword": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "phonenumberpassword"
        }
    },
    "userguide-onlyuserroles": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "userroles"
        }
    },
    "userguide-multifactorauth": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "multifactorauth"
        }
    },
    "userguide-microserviceauth": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "microserviceauth"
        }
    },
    "userguide-user-management": {
        eventName: "button_documentation_userguides_guides",
        metaData: {
            option_clicked: "user-management"
        }
    },
    "guides-references-apireference": {
        eventName: "button_documentation_userguides_references",
        metaData: {
            option_clicked: "http api reference"
        }
    },
    "guides-references-sdkreference": {
        eventName: "button_documentation_userguides_references",
        metaData: {
            option_clicked: "sdk reference"
        }
    },
    "http-api-reference": {
        eventName: "button_documentation_references_selected",
        metaData: {
            option_clicked: "http api reference"
        }
    },
    "sdk-reference": {
        eventName: "button_documentation_references_selected",
        metaData: {
            option_clicked: "sdk reference"
        }
    },
    "guides-cta-in-referencepage": {
        eventName: "button_documentation_references_guidescta",
        metaData: {
            button_text: "guides"
        }
    },
    discord_revoking_access_with_a_jwt_blacklist: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "revoking-access-with-a-jwt-blacklist"
        }
    },
    discord_social_login_with_email_password: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "how-to-set-up-social-and-email-password-login-with-reactjs"
        }
    },
    discord_adding_social_login_to_your_website_with_supertokens: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "adding-social-login-to-your-website-with-supertokens"
        }
    },
    discord_password_hashing_salting: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "password-hashing-salting"
        }
    },
    discord_building_a_login_screen_with_react_and_bootstrap: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "building-a-login-screen-with-react-and-bootstrap"
        }
    },
    discord_what_is_jwt: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "what-is-jwt"
        }
    },
    discord_oauth_vs_jwt: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "oauth-vs-jwt"
        }
    },
    discord_how_to_deploy_supertokens_with_react_nodejs_express_on_vercel: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "how-to-deploy-supertokens-with-react-nodejs-express-on-vercel"
        }
    },
    discord_connect_supertokens_to_database: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "connect-supertokens-to-database"
        }
    },
    discord_the_real_reason_okta_spent_on_auth0: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "the-real-reason-okta-spent-on-auth0"
        }
    },
    discord_auth0_alternatives_auth0_vs_okta_vs_cognito_vs_supertokens: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "auth0-alternatives-auth0-vs-okta-vs-cognito-vs-supertokens"
        }
    },
    discord_are_you_using_jwts_for_user_sessions_in_the_correct_way: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "are-you-using-jwts-for-user-sessions-in-the-correct-way"
        }
    },
    discord_how_to_use_supertokens_pre_built_ui_with_vuejs: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "how-to-use-supertokens-pre-built-ui-with-vuejs"
        }
    },
    discord_user_roles: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "user-roles"
        }
    },
    discord_passwordless_for_product_managers: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "passwordless-for-product-managers"
        }
    },
    discord_how_to_use_supertokens_custom_ui_with_vuejs: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "how-to-use-supertokens-custom-ui-with-vuejs"
        }
    },
    discord_supabase_auth_comparison_with_supertokens_integration: {
        eventName: "button_blog_discord_article",
        metaData: {
            option_clicked: "supabase-auth-comparison-with-supertokens-integration"
        }
    },
    github_star_banner_cta: {
        eventName: "button_blog_banner_cta",
        metaData: {
            option_clicked: "star-us-on-github"
        }
    },
    github_star_banner_cta1: {
        eventName: "button_blog_banner_cta",
        metaData: {
            option_clicked: "star-us-on-github"
        }
    }
};

export const addWebflowButtonAnalytics = (version = "v5") => {
    document.addEventListener("click", (e: MouseEvent) => {
        let id: string | undefined;
        const element = e.target as HTMLElement;

        // check if the current element has a valid id
        // valid id is an id which is a key in the idToEventNameMapping object
        if (element.id !== "" && idToEventNameMapping[element.id] !== undefined) {
            id = element.id;
        } else if (
            element.parentElement &&
            element.parentElement.id !== null &&
            element.parentElement.id !== "" &&
            idToEventNameMapping[element.parentElement.id] !== undefined
        ) {
            // check if it's immediate parent has a valid id
            id = element.parentElement.id;
        }

        if (id === undefined || id === "") {
            const closest = element.closest("[data-closest-parent]") as HTMLElement;
            if (closest !== null && closest !== undefined) {
                const value = closest.dataset.closestParent;
                if (value !== undefined && idToEventNameMapping[value] !== undefined) {
                    id = value;
                }
            }
        }

        // we go through all the parents of this element until we find a parent with a valid id
        // or we reach the document element
        if (id === undefined || id === "") {
            let parent = element.parentElement;
            while (parent !== null) {
                if (parent.id !== "" && idToEventNameMapping[parent.id]) {
                    id = parent.id;
                }

                parent = parent.parentElement;
            }
        }

        let info;
        if (id !== undefined && id !== "") {
            info = idToEventNameMapping[id];
        }
        if (info !== undefined) {
            sendButtonAnalytics(info.eventName, version, info.metaData);
        }
    });
};

export const addTwitterWallAntcs = (version = "v5") => {
    const twitterWall = document.getElementById("twitter-wall-main");
    if (twitterWall !== null) {
        twitterWall.addEventListener("click", (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const parentWithTwitterId = target.closest(`a[id^="twitter-wall-id"]`);
            if (parentWithTwitterId !== null) {
                sendButtonAnalytics("button_home_twitterwall_testimonials");
            }
        });
    }
};

// for each blog page, add ids of the elements to this map for which
// you want to send the event "page_blog_lastsection_markasdone" when that element
// becomes visible in user's viewport
const blogIdsToTrack: {
    [pathname: string]: string[];
} = {
    "/blog/the-real-reason-okta-spent-on-auth0": ["last_section_the_real_reason_okta_spent_on_auth0"],
    "/blog/implementing-a-forgot-password-flow": ["password-reset-conclusion"],
    "/blog/speed-up-your-web-development-time-by-integrating-webflow-into-a-react-application": [
        "react-webflow-conclusion"
    ],
    "/blog/cookies-vs-localstorage-for-sessions-everything-you-need-to-know": ["cookie-localstorage-conclusion"],
    "/blog/express-session-vs-supertokens-for-handling-user-sessions": ["expression-vs-us-conclusion"],
    "/blog/should-you-use-express-session-for-your-production-app": ["express-session-conclusion"],
    "/blog/are-you-using-jwts-for-user-sessions-in-the-correct-way": [
        "last_section_are_you_using_jwts_for_user_sessions_in_the_correct_way"
    ],
    "/blog/the-best-way-to-securely-manage-user-sessions": ["securely-manage-conclusion"],
    "/blog/all-you-need-to-know-about-user-session-security": ["all-you-need-part-2"],
    "/blog/solve-the-problem-of-vendor-lock-in": ["vendor-lock-in-conclusion"],
    "/blog/why-is-redux-state-immutable": ["redux-state-immutable-conclusion"],
    "/blog/how-to-customise-supertokens-apis": ["override-blog-conclusion"],
    "/blog/adding-social-login-to-your-website-with-supertokens": [
        "last_section_adding_social_login_to_your_website_with_supertokens"
    ],
    "/blog/revoking-access-with-a-jwt-blacklist": ["last_section_revoking_access_with_a_jwt_blacklist"],
    "/blog/how-to-set-up-social-and-email-password-login-with-reactjs": ["whats-next"],
    "/blog/password-hashing-salting": ["last_section_password_hashing_salting"],
    "/blog/building-a-login-screen-with-react-and-bootstrap": [
        "last_section_building_a_login_screen_with_react_and_bootstrap"
    ],
    "/blog/what-is-jwt": ["last_section_what_is_jwt"],
    "/blog/oauth-vs-jwt": ["last_section_oauth_vs_jwt"],
    "/blog/how-to-deploy-supertokens-with-react-nodejs-express-on-vercel": [
        "last_section_how_to_deploy_supertokens_with_react_nodejs_express_on_vercel"
    ],
    "/blog/connect-supertokens-to-database": ["last_section_connect_supertokens_to_database"],
    "/blog/auth0-alternatives-auth0-vs-okta-vs-cognito-vs-supertokens": [
        "last_section_auth0_alternatives_auth0_vs_okta_vs_cognito_vs_supertokens"
    ],
    "/blog/how-to-use-supertokens-pre-built-ui-with-vuejs": [
        "last_section_how_to_use_supertokens_pre_built_ui_with_vuejs"
    ],
    "/blog/user-roles": ["last_section_user_roles"],
    "/blog/passwordless-for-product-managers": ["last_section_passwordless_for_product_managers"],
    "/blog/how-to-use-supertokens-custom-ui-with-vuejs": ["last_section_how_to_use_supertokens_custom_ui_with_vuejs"],
    "/blog/supabase-auth-comparison-with-supertokens-integration": [
        "last_section_supabase_auth_comparison_with_supertokens_integration"
    ]
    // "/blog/introducing-user-roles-authorization-with-supertokens": [
    //     "last_section_introducing_user_roles_authorization_with_supertokens"
    // ],
    // "/blog/what-is-cross-origin-resource-sharing": [
    //     "last_section_what_is_cross_origin_resource_sharing",
    // ],
};
export const addBlogReadAntcs = (version = "v5") => {
    const currentLocationPath = getLocationPath();
    let idsToTrack: string[] = [];

    // We dont want to interfere with existing analytics for blogs
    if (currentLocationPath.startsWith("/blog/") && blogIdsToTrack[currentLocationPath] === undefined) {
        // We want to add analytics for the last section of all blogs
        const elements = document.querySelectorAll('[id^="last_section_"]');

        if (elements.length > 0) {
            elements.forEach(element => {
                idsToTrack.push(element.id);
            });
        }
    } else {
        idsToTrack = blogIdsToTrack[currentLocationPath];
    }

    if (idsToTrack === undefined) {
        return;
    }
    let isEventAlreadySent: { [id: string]: boolean } = {};
    idsToTrack.map(id => (isEventAlreadySent[id] = false));

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observerOptions = {
        root: null, // observing relative to viewport
        rootMargin: "0px",
        threshold: 1.0 // heading should be completely visible
    };

    const onIntersection: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach(entry => {
            const id = entry.target.id;

            if (isEventAlreadySent[id] === true) {
                const elementToUnobserve = document.getElementById(id);
                if (elementToUnobserve !== null) {
                    observer.unobserve(elementToUnobserve);
                }
                return;
            }
            if (entry.isIntersecting) {
                isEventAlreadySent[id] = true;
                sendScrollAnalytics("page_blog_lastsection_markasdone", version, {
                    option_clicked: window.location.pathname.split("/")[2]
                });
            }
        });
    };

    let observer = new IntersectionObserver(onIntersection, observerOptions);

    // now track each relevant element
    idsToTrack.forEach(id => {
        const elementToTrack = document.querySelector(`#${id}`);
        if (elementToTrack !== null) {
            observer.observe(elementToTrack);
        }
    });

    return observer;
};

// list of ids to track on a page for section analytics
// the sectionAnalyticsEventNameFromPage object should be updated too if required when this object is updated
const sectionIdsToTrack: {
    [key: string]: {
        [id: string]: string;
    };
} = {
    "/": {
        testimonials_companies_homepage: "Companies section homepage",
        benefits_section_homepage: "The benefits section",
        supertokens_provides_section: "SuperTokens provides section",
        how_it_works_supertokens_homepage: "How SuperTokens works section",
        "testimonial-1": "The testimonials section",
        coming_soon_feature_launch: "Coming soon feature requests section",
        homepage_end: "End of homepage section"
    },
    "/pricing": {
        pricing_selfhosted_saas_details: "Pricing details of SaaS and selfhosted version",
        login_features_pricing: "Login features, Session management features",
        role_management_features_pricing: "Role management features",
        end_of_comparison_table: "Comparison table",
        pricing_philosophy_section: "Pricing philosophy"
    }
};

// map of page path to section analytics event name
// the sectionIdsToTrack object should be updated too if required when this object is updated
const sectionAnalyticsEventNameFromPage: {
    [key: string]: string;
} = {
    "/": "homepage_section_viewed",
    "/pricing": "pricing_section_viewed"
};

// returns true if the current page should have section analytics
export const shouldAddSectionViewAnalytics = () => {
    return sectionAnalyticsEventNameFromPage[getLocationPath()] !== undefined;
};

export const addSectionViewAnalytics = (version = "v5") => {
    // get list of ids to track for section analytics for current page
    let currentPageSectionIds: string[] = [];
    if (sectionIdsToTrack[getLocationPath()]) {
        currentPageSectionIds = Object.keys(sectionIdsToTrack[getLocationPath()]);
    }

    let isEventAlreadySent: { [id: string]: boolean } = {};

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observerOptions = {
        root: null, // observing relative to viewport
        rootMargin: "0px",
        threshold: 0
    };

    const onIntersection: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach(entry => {
            const id = entry.target.id;

            if (isEventAlreadySent[id] === true) {
                const elementToUnobserve = document.getElementById(id);
                if (elementToUnobserve !== null) {
                    observer.unobserve(elementToUnobserve);
                }
                return;
            }

            if (entry.isIntersecting) {
                isEventAlreadySent[id] = true;
                const eventName = sectionAnalyticsEventNameFromPage[getLocationPath()];
                const sectionViewed = sectionIdsToTrack[getLocationPath()][id];

                sendScrollAnalytics(eventName, version, {
                    section_viewed: sectionViewed
                });
            }
        });
    };

    let observer = new IntersectionObserver(onIntersection, observerOptions);

    // now track each relevant element
    currentPageSectionIds.forEach(id => {
        const elementToTrack = document.querySelector(`#${id}`);
        if (elementToTrack !== null) {
            observer.observe(elementToTrack);
        }
    });

    return observer;
};

export const addScrollAnalytics = (idsToTrack: string[], version = "v5") => {
    let isEventAlreadySent: { [id: string]: boolean } = {};
    idsToTrack.map(id => (isEventAlreadySent[id] = false));

    const observerOptions = {
        root: null, // observing relative to viewport
        rootMargin: "0px",
        threshold: buildThresholdList()
    };

    const intersectionObserverCallback: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach(entry => {
            const id = entry.target.id;

            if (isEventAlreadySent[id] === true) {
                const elementToUnobserve = document.getElementById(id);
                if (elementToUnobserve !== null) {
                    observer.unobserve(elementToUnobserve);
                }
                return;
            }
            const info = idToEventNameMapping[id];
            if (info === undefined) {
                return;
            }
            if (entry.intersectionRatio > 0.25) {
                isEventAlreadySent[id] = true;
                sendScrollAnalytics(info.eventName, version, info.metaData);
            }
        });
    };

    let observer = new IntersectionObserver(intersectionObserverCallback, observerOptions);

    // now track each relevant element
    idsToTrack.forEach(id => {
        const elementToTrack = document.querySelector(`#${id}`);
        if (elementToTrack !== null) {
            observer.observe(elementToTrack);
        }
    });

    return observer;
};

function buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
}
