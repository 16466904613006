import * as React from "react";
import { sendButtonAnalytics } from "../utils";
import "../style/useOss.module.css";

export class OssDocsLinkAnalytics extends React.PureComponent {
    constructor(props: any) {
        super(props);
    }

    render() {
        return null;
    }

    componentDidMount() {
        document.addEventListener("click", this.docsClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.docsClickHandler);
    }

    async docsClickHandler(e: MouseEvent) {
        const id = (e.target as HTMLElement).id;
        if (id === undefined) {
            return;
        }

        if (id.endsWith("documentation-1")) {
            let eventName;

            let plugin: "mysql" | "postgresql" | "mongodb" = "mysql";
            if (id.includes("-postgresql-")) {
                plugin = "postgresql";
            } else if (id.includes("-mongodb-")) {
                plugin = "mongodb";
            }

            if (id.startsWith("docker")) {
                eventName = "button_selfhosted_docker_documentation";
            } else if (id.startsWith("binary")) {
                eventName = "button_selfhosted_binary_documentation";
            }

            if (eventName !== undefined) {
                sendAnalyticsEvent(eventName, id, plugin);
                await new Promise(r => setTimeout(r, 500));
            }
        }
        return;
    }
}

function sendAnalyticsEvent(eventName: string, id: string, plugin: string) {
    const { driver } = getPluginAndDriver(id);
    let option_clicked = driver + "-" + plugin;

    sendButtonAnalytics(eventName, "v5", {
        login_supported: driver === "node" && plugin !== "mongodb",
        option_clicked
    });
}

function getPluginAndDriver(id: string) {
    let plugin: "mysql" | "postgresql" | "mongodb" = "mysql";
    if (id.includes("-postgresql-")) {
        plugin = "postgresql";
    } else if (id.includes("-mongodb-")) {
        plugin = "mongodb";
    }

    let driver: "node" | "go" | "flask" | "laravel" | "javalin" | "fastapi" = "node";
    if (id.includes("-go-")) {
        driver = "go";
    } else if (id.includes("-flask-")) {
        driver = "flask";
    } else if (id.includes("-laravel-")) {
        driver = "laravel";
    } else if (id.includes("-javalin-")) {
        driver = "javalin";
    } else if (id.includes("-fastapi-")) {
        driver = "fastapi";
    }

    return { plugin, driver };
}

export const useOssDiscordButtonAnalytics = () => {
    sendButtonAnalytics("button_use-oss_joindiscord", "v5");
};

export const useOssSelectPlatformAnalytics = (os: string) => {
    sendButtonAnalytics("button_use-oss_binary_operating-system", "v5", {
        option_clicked: os
    });
};

export const useOssCopyCurlCommandAnalytics = () => {
    sendButtonAnalytics("button_use-oss_binary_copyaction", "v5", {
        option_clicked: "curl command"
    });
};

export const useOssCopyDockerComposeFileContent = () => {
    sendButtonAnalytics("button_use-oss_docker_copyaction", "v5", {
        option_clicked: "docker compose file"
    });
};
