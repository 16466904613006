import * as React from "react";

interface Props {}
interface State {
    hasError: boolean;
}
const ErrorMessage = () => {
    return (
        <div
            style={{
                width: "fit-content",
                borderRadius: "7px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f7d9d9"
            }}
        >
            <p
                style={{
                    textAlign: "center",
                    color: "#ff2b2b",
                    fontWeight: 500,
                    padding: "6px 12px"
                }}
            >
                Something went wrong. Please try again.
            </p>
        </div>
    );
};
export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage />;
        }

        return this.props.children;
    }
}
