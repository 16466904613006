import * as React from "react";
import Header from "../darkhome/Header";
import Footer from "../footer";
import "../style/useOss.module.css";
import { Hero } from "./Hero";
import { UseOss } from "./UseOss";
import { OssDocsLinkAnalytics } from "./useOssAnalytics";

export const planType = "FREE";
export const frontend = "website";
export type Db = "mysql" | "postgresql" | "mongodb";
export type Platform = "linux" | "mac" | "windows" | "linux-arm";

export const OSData: {
    displayName: string;
    id: Platform;
}[] = [
    { id: "mac", displayName: "Mac" },
    { id: "windows", displayName: "Windows" },
    { id: "linux", displayName: "Linux" }
];

type OssSpinnerProps = {
    isInline?: boolean;
};

export function Spinner({isInline} :OssSpinnerProps) {
    return (
        <div className={`useoss-spinner-container ${isInline ? 'useoss-spinner-container-inline' : ''}`}>
            <div className="useoss-spinner"></div>
        </div>
    );
}

const UseOssPage = () => {
    return (
        <div className="useOss-container">
            <Header />
            <Hero />
            <UseOss />
            <Footer />
            <OssDocsLinkAnalytics />
        </div>
    );
};

export default UseOssPage;
