// Global Dependencies
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { SuperTokensWrapper } from "supertokens-auth-react";
import { SessionAuth, SessionContextType, SessionContext } from "supertokens-auth-react/recipe/session";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { EmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/emailpassword";
import { canHandleRoute } from "supertokens-auth-react/ui";

import { lazy } from "@loadable/component";

import { PAGE_PATHS, saasDashboardPagesPaths } from "./constants";

import Footer from "./footer";
import HeaderDarkTheme from "./darkhome/Header";
import { Loading, Redirects } from "./components/elements";

import "./style/global.module.css";

import {
    addBlogReadAntcs,
    addScrollAnalytics,
    addSectionViewAnalytics,
    addTwitterWallAntcs,
    addWebflowButtonAnalytics,
    shouldAddSectionViewAnalytics
} from "./addWebflowAnalytics";
import { ErrorBoundary } from "./components/ErrorBoundary";
import NotFound from "./notfound";
import SuperTokensAuth from "./auth";
import superTokensInit from "./auth/supertokensInit";
import DocVersionHeader from "./components/docVersionHeader";
import SdkDocsBanner from "./components/SdkDocsBanner/SdkDocsBanner";
import { getLocationHref, getLocationPath, sendPageViewAnalytics } from "./utils";
import AutogeneratedDocsVersionsList from "./components/AutogeneratedDocsVersionsList/AutogeneratedDocsVersionsList";
import { PopupContentContextProvider } from "./context/PopupContentContext";
import { ToastNotificationContainer } from "./components/elements/Notification";
import SignUpHelper from "./auth/SignUpHelper";
import ResetPasswordHelper from "./auth/ResetPasswordHelper";
import PaymentsModalProvider from "./context/PaymentsModalContext";
import SaasLayout from "./components/layouts/saas-layout";
import { UserProvider } from "./context/UserContext";
import { checkForDesyncedSession } from "./auth/sdklogsUtils";
import { saveUTMSource } from "./utils/ads";

const DockerCompatibility = lazy(() =>
    import(/*
  webpackChunkName: "dockercompatibility",
  webpackPrefetch: true
*/ "./screens/docs/docker-compatibility")
);

const SeoContent = lazy(() =>
    import(/*
  webpackChunkName: "seocontent",
  webpackPrefetch: true
*/ "./screens/main-website/seo-content")
);

const DashboardSaas = lazy(() =>
    import(/*
  webpackChunkName: "dashboardsaas",
  webpackPrefetch: true
*/ "./dashboard/saas")
);

const SaasBilling = lazy(() =>
    import(/*
  webpackChunkName: "dashboardsaas",
  webpackPrefetch: true
*/ "./dashboard/saas/Billing")
);

const SaasSettings = lazy(() =>
    import(/*
  webpackChunkName: "dashboardsaas",
  webpackPrefetch: true
*/ "./dashboard/saas/Settings")
);

const SaasUserTenantManagement = lazy(() =>
    import(/*
  webpackChunkName: "dashboardsaas",
  webpackPrefetch: true
*/ "./dashboard/saas/UserTenantManagement")
);

const ProductRoadmap = lazy(() => import(/*
  webpackChunkName: "roadmap",
  webpackPrefetch: true
*/ "./productroadmap"));

const ContactSales = lazy(() =>
    import(/*
  webpackChunkName: "dockercompatibility",
  webpackPrefetch: true
*/ "./consultancy")
);

const EventScheduled = lazy(() =>
    import(/*
  webpackChunkName: "eventscheduled",
  webpackPrefetch: true
*/ "./consultancy/eventScheduled")
);

const UseOss = lazy(() => import(/*
  webpackChunkName: "useoss",
  webpackPrefetch: true
*/ "./useOss"));

const RecipeRedirect = lazy(() =>
    import(/*
  webpackChunkName: "reciperedirect",
  webpackPrefetch: true
*/ "./recipe_redirect")
);

superTokensInit();

class Router extends React.PureComponent<{}, {}> {
    headerDiv: any;
    headerWhiteDiv: any;
    footerDiv: any;
    notFoundDiv: any;
    sdkBannerDiv: any;
    scrollObserver: IntersectionObserver;
    sdkDocsVersionsListTHead: any;

    static contextType = SessionContext;
    context: SessionContextType;

    constructor(props: any) {
        super(props);

        this.headerDiv = document.getElementById("supertokens-webflow-header");
        this.headerWhiteDiv = document.getElementById("supertokens-webflow-white-header");
        this.footerDiv = document.getElementById("supertokens-webflow-footer");
        this.notFoundDiv = document.getElementById("non-whitelisted-page");
        this.sdkBannerDiv = document.getElementById("sdk-docs-guides-banner");
        this.sdkDocsVersionsListTHead = document.getElementById("sdk-autogenerated-docs-version-list");
    }

    getCurrentPath = () => {
        let currentPath: string = getLocationPath();
        // if current path includes .html then remove html from the string
        if (currentPath.includes(".html")) {
            currentPath = currentPath.replace(".html", "");
        }

        if (currentPath !== "/" && currentPath.charAt(currentPath.length - 1) === "/") {
            currentPath = currentPath.substr(0, currentPath.length - 1);
        }
        return currentPath;
    };
    isFeaturePage = (currentPath: string) => {
        for (let i = 0; i < Object.values(PAGE_PATHS.featurePages).length; i++) {
            const config = Object.values(PAGE_PATHS.featurePages)[i];
            if (currentPath === config.main || currentPath === config.alt) {
                return true;
            }
        }

        return false;
    };

    isCustomerPage = (currentPath: string) => {
        for (let i = 0; i < Object.values(PAGE_PATHS.customerPages).length; i++) {
            const config = Object.values(PAGE_PATHS.customerPages)[i];
            if (currentPath === config.main || currentPath === config.alt) {
                return true;
            }
        }

        return false;
    };

    render() {
        const doesSessionExist = !this.context.loading && this.context.doesSessionExist;

        // for main website pages this ensures that the urlPath has been added to the 404
        // whitelist in backend
        if (this.notFoundDiv !== null) {
            return <NotFound />;
        }

        if (canHandleRoute([ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])) {
            return <SuperTokensAuth />;
        }

        let currentPath: string = this.getCurrentPath();

        if (currentPath === PAGE_PATHS.home) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (
            currentPath === PAGE_PATHS.dashboardSaas ||
            currentPath === PAGE_PATHS.dashboardSaasAlt ||
            saasDashboardPagesPaths.includes(currentPath)
        ) {
            // Only for React
            return (
                <SessionAuth>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <PopupContentContextProvider>
                                <PaymentsModalProvider>
                                    <UserProvider>
                                        <BrowserRouter>
                                            <Switch>
                                                <SaasLayout>
                                                    {/* Note: While adding a new route on the frontend app, Please also make sure that you include this newly added route into notFoundWhitelist on the backend repo. */}
                                                    {/* Note: Add new dashboard route to "saasDashboardPagesPaths" in src/ts/constants.ts*/}
                                                    <Route path="/dashboard-saas" exact>
                                                        <DashboardSaas />
                                                    </Route>
                                                    <Route path="/dashboard-saas/user-tenant-management" exact>
                                                        <SaasUserTenantManagement />
                                                    </Route>
                                                    <Route path="/dashboard-saas/billing" exact>
                                                        <SaasBilling />
                                                    </Route>
                                                    <Route path="/dashboard-saas/settings" exact>
                                                        <SaasSettings />
                                                    </Route>
                                                </SaasLayout>
                                            </Switch>
                                        </BrowserRouter>
                                    </UserProvider>
                                    <ToastNotificationContainer />
                                </PaymentsModalProvider>
                            </PopupContentContextProvider>
                        </React.Suspense>
                    </ErrorBoundary>
                </SessionAuth>
            );
        } else if (currentPath === PAGE_PATHS.pricing || currentPath === PAGE_PATHS.pricingAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.product || currentPath === PAGE_PATHS.productAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (this.isFeaturePage(currentPath)) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (this.isCustomerPage(currentPath)) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.guides || currentPath === PAGE_PATHS.guidesAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.references || currentPath === PAGE_PATHS.referencesAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.consultancy || currentPath === PAGE_PATHS.consultancyAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <ContactSales />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (currentPath === PAGE_PATHS.eventScheduled || currentPath === PAGE_PATHS.eventScheduledAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <EventScheduled />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (currentPath === PAGE_PATHS.customers || currentPath === PAGE_PATHS.customersAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (
            currentPath === PAGE_PATHS.customersBuiltIntelligence ||
            currentPath === PAGE_PATHS.customersBuiltIntelligenceAlt
        ) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.customersPoppy || currentPath === PAGE_PATHS.customersPoppyAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.customersGoPronto || currentPath === PAGE_PATHS.customersGoProntoAlt) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (
            currentPath === PAGE_PATHS.customersFoodMarketHub ||
            currentPath === PAGE_PATHS.customersFoodMarketHubAlt
        ) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (currentPath === PAGE_PATHS.useOSS || currentPath === PAGE_PATHS.useOSSAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <UseOss />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (currentPath === PAGE_PATHS.spreadsheet || currentPath === PAGE_PATHS.spreadsheetAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <SeoContent />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (currentPath === PAGE_PATHS.productRoadmap || currentPath === PAGE_PATHS.productRoadmapAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <ProductRoadmap doesSessionExist={doesSessionExist} />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (currentPath === PAGE_PATHS.recipeRedirect || currentPath === PAGE_PATHS.recipeRedirectAlt) {
            return (
                <div>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <RecipeRedirect />
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            );
        } else if (
            getLocationHref()
                .split("/")
                .includes("docs")
        ) {
            const versionHeaderDiv = document.getElementById("version-header");

            let versionHeader;
            let sdkDocsBanner;
            let deprecatedDocsBanner;
            let sdkAutogeneratedDocsVersionsList;

            if (versionHeaderDiv !== null) {
                versionHeader = ReactDOM.createPortal(<DocVersionHeader />, versionHeaderDiv);
            }

            if (this.sdkBannerDiv !== null) {
                sdkDocsBanner = ReactDOM.createPortal(<SdkDocsBanner />, this.sdkBannerDiv);
            }

            if (this.sdkDocsVersionsListTHead !== null) {
                sdkAutogeneratedDocsVersionsList = ReactDOM.createPortal(
                    <AutogeneratedDocsVersionsList />,
                    this.sdkDocsVersionsListTHead
                );
            }

            // We dont want to inject the footer for android and ios docs
            let shouldShowFooter =
                !getLocationHref().includes("docs/android") && !getLocationHref().includes("docs/ios");

            return (
                <>
                    {versionHeader}
                    {sdkDocsBanner}
                    {deprecatedDocsBanner}
                    {sdkAutogeneratedDocsVersionsList}

                    {shouldShowFooter && <Footer />}
                </>
            );
        } else if (window.redirectPaths.hasOwnProperty(currentPath)) {
            // Redirect
            if (currentPath === "/discord") {
                sendPageViewAnalytics("discord_page_view");
            }
            const hash = window.location.hash;
            return <Redirects redirectLink={window.redirectPaths[currentPath] + hash} />;
        } else if (
            getLocationPath()
                .split("/")
                .includes("blog")
        ) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else if (
            getLocationPath()
                .split("/")
                .includes("jwt-encoder-decoder")
        ) {
            return (
                <RenderCreatePortals
                    headerDiv={this.headerDiv}
                    footerDiv={this.footerDiv}
                    headerWhiteDiv={this.headerWhiteDiv}
                />
            );
        } else {
            return <NotFound />;
        }
    }

    async componentDidMount() {
        checkForDesyncedSession();
        saveUTMSource();
        if (getLocationHref().includes("/docs/pro/")) {
            window.open(PAGE_PATHS.communityDocsLanding, "_self");
            return;
        }

        addWebflowButtonAnalytics(); // add webflow analytics
        addTwitterWallAntcs();
        const observer = addScrollAnalytics(["comparison-table-true"]);
        this.scrollObserver = observer;

        if (getLocationHref().includes("/blog/")) {
            addBlogReadAntcs();
        }

        this.renderDocsElements();
        this.preventFooterFromCoveringBlog();

        if (shouldAddSectionViewAnalytics()) {
            addSectionViewAnalytics();
        }
    }

    preventFooterFromCoveringBlog() {
        if (
            getLocationHref()
                .split("/")
                .includes("blog")
        ) {
            const blogBody = document.querySelector(".div-block") as HTMLElement;
            if (blogBody !== null) {
                blogBody.style.height = "auto";
            }
        }
    }

    componentWillUnmount() {
        this.scrollObserver.disconnect();
    }

    renderDocsElements = () => {
        if (
            getLocationHref()
                .split("/")
                .includes("docs")
        ) {
            // Only for docusaurus, benefits and documentation

            // if the documentation page have supertokens-docker-compatibility
            const dockerCompatibilityId = "supertokens-docker-compatibility";
            const dockerCompatibilityDiv = document.getElementById(dockerCompatibilityId);
            if (dockerCompatibilityDiv !== null) {
                ReactDOM.render(
                    <ErrorBoundary>
                        <React.Suspense fallback={<Loading />}>
                            <DockerCompatibility />
                        </React.Suspense>
                    </ErrorBoundary>,
                    dockerCompatibilityDiv
                );
            }
        }
    };
}

class App extends React.Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <SuperTokensWrapper>
                <EmailPasswordComponentsOverrideProvider
                    components={{
                        EmailPasswordSignInForm_Override: ({ DefaultComponent, ...props }) => {
                            React.useEffect(() => {
                                sendPageViewAnalytics("page_signin");
                            }, []);
                            return <DefaultComponent {...props} />;
                        },
                        EmailPasswordSignUpForm_Override: ({ DefaultComponent, ...props }) => {
                            React.useEffect(() => {
                                sendPageViewAnalytics("page_signup");
                            }, []);
                            return <SignUpHelper originalComponent={DefaultComponent} originalProps={props} />;
                        },
                        EmailPasswordResetPasswordEmail_Override: ({ DefaultComponent, ...props }) => {
                            return <ResetPasswordHelper originalComponent={DefaultComponent} originalProps={props} />;
                        },
                        EmailPasswordSubmitNewPassword_Override: ({ DefaultComponent, ...props }) => {
                            return <ResetPasswordHelper originalComponent={DefaultComponent} originalProps={props} />;
                        }
                    }}
                >
                    <Router />
                </EmailPasswordComponentsOverrideProvider>
            </SuperTokensWrapper>
        );
    }
}

ReactDOM.render(<App />, document.getElementById("supertokens-root"));

function RenderCreatePortals(props: any): any {
    const removeCloneHeader = () => {
        const headerClone = document.getElementById("header-webflow-clone");
        if (headerClone !== null) {
            headerClone.remove();
        }
    };
    let renderHeader =
        (props.headerWhiteDiv !== undefined && props.headerWhiteDiv !== null) ||
        (props.headerDiv !== undefined && props.headerDiv !== null);

    let HeaderDarkThemeComp = !renderHeader
        ? null
        : ReactDOM.createPortal(
              <HeaderDarkTheme removeCloneHeader={removeCloneHeader} />,
              props.headerWhiteDiv !== undefined && props.headerWhiteDiv !== null
                  ? props.headerWhiteDiv
                  : props.headerDiv
          );

    let renderFooter = props.footerDiv !== undefined && props.footerDiv !== null;
    let FooterComp = !renderFooter ? null : ReactDOM.createPortal(<Footer />, props.footerDiv);

    return (
        <>
            {HeaderDarkThemeComp}
            {FooterComp}
        </>
    );
}
