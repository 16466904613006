import { API_URL } from "../constants";
import * as httpNetworking from "../httpNetworking";

const URL = API_URL + "/website/form/how-did-you-hear-about-us";
const VERSION = 1;

export interface HeardAboutUsData {
    source: string;
    detail: string;
    name?: string;
    email?: string;
    country?: string;
    analyticsUserId?: string;
    utm_source?: string;
    companySize?: string;
}

export default async function sendHeardAboutUsData(data: HeardAboutUsData): Promise<void> {
    const options: httpNetworking.POSTRequestConfig = {
        timeout: 50000
    };

    await httpNetworking.simplePOSTRequest(URL, data, options, VERSION);
}
