import { useContext, useEffect } from "react";

import Storage from "./storage";
import { PopupContentContext } from "../context/PopupContentContext";

export const PAYMENT_DETAILS_REDIRECT_CONTEXT = "payment-details-redirect-context";

//this item in localstorage will make sure that production is auto created when the users payments details are
//successfully stored.
export const AUTO_CREATE_PROD_APP = "auto-create-prod-app";

/**
 * @property redirect_uri : this is the uri that we have to redirect user to enter the
 * payment details.
 *
 * @property action_type : tells what action does the user have done when the payments flow started
 *
 * @property data : this stores respective data related to the action_type inorder prefill the UI for the user
 * in the case of a failed/success redirection back from stripe.
 *
 * @property timestamp : tracks the time when the user have started the payments flow.
 */

export type PaymentDetailsRedirectContext =
    | { redirect_uri: string; action_type: "list-apps"; data: any; timestamp: number }
    | {
          redirect_uri: string;
          action_type: "create-app-or-env";
          data: {
              appName: string;
          };
          timestamp: number;
      }
    | {
          redirect_uri: string;
          action_type: "license-key";
          data: {
              selectedFeatures: string[];
          };
          timestamp: number;
      }
    | {
          redirect_uri: string;
          action_type: "create-prod-app";
          data: any;
          timestamp: number;
      };

export function usePaymentDetailsContext() {
    const params = new URLSearchParams(window.location.search);
    const isPaymentSuccess = params.get("paymentSuccess");
    const stripe_context = Storage.getItem(PAYMENT_DETAILS_REDIRECT_CONTEXT);

    const { showToast: showToastMessage } = useContext(PopupContentContext);

    useEffect(() => {
        if (isPaymentSuccess === "true" && Storage.getItem(PAYMENT_DETAILS_REDIRECT_CONTEXT)) {
            showToastMessage({
                status: "success",
                icon: "/static/assets/dashboard/check-rounded-green.png",
                children: "Payment details added successfully."
            });
        } else if (isPaymentSuccess === "false" && Storage.getItem(PAYMENT_DETAILS_REDIRECT_CONTEXT)) {
            showToastMessage({
                status: "error",
                icon: "/static/assets/dashboard/alert.png",
                children: "Failed to verify your payment details."
            });
        }

        //remove stored context from the localStorage.
        Storage.removeItem(PAYMENT_DETAILS_REDIRECT_CONTEXT);
        //cleanup the redirection uri injected query params.
        window.history.replaceState({}, "", location.protocol + "//" + location.host + location.pathname);
    }, []);

    if (typeof isPaymentSuccess === "string") {
        if (stripe_context !== undefined) {
            const parsedContext = JSON.parse(stripe_context) as PaymentDetailsRedirectContext;
            //time window between the stripe link opened and redirection back
            //the website should be less than 15mins.
            if (parsedContext.action_type === "create-prod-app" && isPaymentSuccess === "true") {
                Storage.setItem(AUTO_CREATE_PROD_APP, "true");
            }
            if (Date.now() - parsedContext.timestamp < 15 * 60 * 1000) {
                return { savedContext: parsedContext, isPaymentSuccess };
            }
        }
    }
}
