import * as React from "react";
import getSupportedDrivers from "../api/drivers";
import getSupportedPlugins from "../api/plugins";
import { useAsync } from "../asyncReactUtils";
import { Section } from "./Section";
import { planType, Spinner } from "./index";
import { changeSdkDataOrder, changeDbDataOrder } from "./useOssUtils";
import ContactSection from "./ContactSection";
import { sendButtonAnalytics } from "../utils";

export function UseOss() {
    const { data: sdkData, status: sdkStatus, run: fetchSdks } = useAsync({ status: "pending" });
    const { data: dbData, status: dbStatus, run: fetchDbs } = useAsync({ status: "pending" });
    const [currentTab, setCurrentTab] = React.useState<"binary" | "docker">("binary");
    const [sdkDownloadClicked, setSdkDownloadClicked] = React.useState(false);

    React.useEffect(() => {
        fetchSdks(getSupportedDrivers(planType));
        fetchDbs(getSupportedPlugins(planType));
    }, []);

    if (sdkStatus === "pending" || dbStatus === "pending") {
        return <Spinner />;
    }

    if (sdkStatus === "rejected" || dbStatus === "rejected") {
        return (
            <p className="bold-info danger-info" style={{ textAlign: "center", marginTop: "2rem" }}>
                Something went wrong.
            </p>
        );
    }

    const handleSectionChange = (tab: "docker" | "binary") => {
        setCurrentTab(tab);
        sendButtonAnalytics("button_use-oss_toggle", "v5", {
            "option_clicked": tab
        });
    };

    if (sdkStatus === "resolved" && dbStatus === "resolved") {
        changeSdkDataOrder(sdkData);
        changeDbDataOrder(dbData);

        return (
            <>
                <div className="useOss-container__sections">
                    <div className="useOss-container__sections__buttons">
                        <button
                            className={`useOss-container__sections__buttons__button ${currentTab === "docker" ? "active" : ""}`}
                            onClick={() => handleSectionChange("docker")}
                        >
                            Docker
                        </button>
                        <button
                            className={`useOss-container__sections__buttons__button ${currentTab === "binary" ? "active" : ""}`}
                            onClick={() => handleSectionChange("binary")}
                        >
                            Binary
                        </button>
                    </div>

                    <Section
                        idPrefix={currentTab}
                        sectionHeading={currentTab === "binary" ? "Download the binary" : "Docker Image"}
                        imgSrc={currentTab === "binary" ? "/static/assets/use-oss/download3x.png" : "/static/assets/use-oss/zDhowvTif3x.png"}
                        sdkData={sdkData}
                        dbData={dbData}
                        showContactSectionOnDownload={() => setSdkDownloadClicked(true)}
                    />

                    <ContactSection
                        visible={sdkDownloadClicked}
                    />
                </div>
            </>
        );
    }

    return null;
}
