import React, { useContext, useState } from "react";
import SuperTokens from "supertokens-auth-react";
import { signOut, useSessionContext } from "supertokens-auth-react/recipe/session";

import { getLocationPath } from "../../utils";
import { Button } from "../elements";
import { Spinner } from "../../useOss";
import { UserContext } from "../../context/UserContext";

import "./../../style/navbar.module.css";
import Header from "../../darkhome/Header";

const DashboardCTA = () => {
    const session = useSessionContext();
    const [isSigningOut, setIsSigningOut] = useState(false);
    const userContext = useContext(UserContext);

    const locationPath = getLocationPath();
    const currentPath = locationPath === "/" ? "_home" : locationPath;
    const pagePathFormatted = currentPath.split("/").join("_");
    const buttonEventPath = "button" + pagePathFormatted + "_" + "header_";

    const logoutUser = async () => {
        try {
            setIsSigningOut(true);
            await signOut();
            window.location.replace("/");
        } catch (error) {
            alert("Unable to logout");
        }
        setIsSigningOut(false);
    };

    function getUserName() {
        if (!userContext || !userContext.user) {
            return undefined;
        }

        return userContext.user.name;
    }

    function getUserEmail() {
        if (!userContext || !userContext.user) {
            return undefined;
        }

        return userContext.user.email;
    }

    function sendAnalyticsEvent(eventName: string, href?: string) {
        const buttonEvent = buttonEventPath + eventName;
        stAnalytics.getInstance().sendButtonEvent(buttonEvent);
        if (href) {
            setTimeout(function() {
                window.location.href = href;
            }, 100);
        }
    }

    const showSignupPopup = () => {
        SuperTokens.redirectToAuth({
            show: "signup",
            redirectBack: false
        });
    };

    return session.loading || isSigningOut || userContext?.isLoadingUser === true ? (
        <div className="center-vertically">
            <button className="dropdown-navbar-loading-btn" disabled>
                <Spinner isInline />
            </button>
        </div>
    ) : session.doesSessionExist ? (
        <div className="dropdown-link-main center-vertically dashboard-navbar-name">
            <Button
                text={getUserName() || "Sign Up"}
                link="/dashboard-saas"
                size={1}
                color={1}
                analyticsEventName="button_home_header_name_dashboard"
                textBold
            />

            <div className="dropdown-links">
                <span className="dropdown-links__caret"></span>
                <a
                    onClick={() => {
                        sendAnalyticsEvent("dashboard");
                    }}
                    href="/dashboard-saas"
                    className="margins-dropdown-navbar text-center"
                >
                    Dashboard
                </a>
                <a
                    onClick={() => {
                        sendAnalyticsEvent("signout");
                        logoutUser();
                    }}
                    className="margins-dropdown-navbar text-center"
                >
                    Sign out
                </a>
                {getUserEmail() && <span className="dropdown-navbar-email-pill">{getUserEmail()}</span>}
            </div>
        </div>
    ) : (
        <div className="center-vertically">
            <button className="dropdown-navbar-btn" onClick={showSignupPopup}>
                Sign Up
            </button>
        </div>
    );
};

export default function Navbar() {
    return <Header theme="light" size="small" CustomCTA={DashboardCTA} showAnnouncement={false} />;
}
