import * as React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { downloadApp } from "../api/user/app/download";
import { sendButtonAnalytics } from "../utils";
import { Db, Platform } from "./index";
import { compareVersions, getCurlCommand, isLoginSupported } from "./useOssUtils";
import { DownloadBinaryProps } from "./useOssTypes";
import Modal from "./Modal";
import UseOssCopyBox from "./UseOssCopyBox";
import { useOssCopyCurlCommandAnalytics } from "./useOssAnalytics";

const minCoreWithArmSupport = "3.4";

export const GetDownloadBinary = ({ sdk, db, compatibilityData, core, showContactSectionOnDownload, os }: DownloadBinaryProps) => {
    const [showPopup, setShowPopup] = React.useState<boolean>(false);

    if (isMobile || isTablet) {
        return (
            <p className="bold-info bold-info-section green-text">
                * Please move to a desktop device to download the binary
            </p>
        );
    }

    const loginSupported = isLoginSupported(compatibilityData, db);

    const handleBinaryDownload = async (e: React.MouseEvent, platform: Platform) => {
        e.preventDefault();
        showContactSectionOnDownload();
        try {
            if (core !== undefined && core !== "") {
                await downloadApp(db as Db, platform, core);

                if (showPopup) {
                    setShowPopup(false);
                    if (platform === "linux") {
                        sendButtonAnalytics("button_linux_popup_downloadoption", "v5", {
                            login_supported: loginSupported,
                            option_clicked: `linux64-${sdk}-${db}`
                        });
                    }
                    if (platform === "linux-arm") {
                        sendButtonAnalytics("button_linux_popup_downloadoption", "v5", {
                            login_supported: loginSupported,
                            option_clicked: `linuxAArch64/ARM-${sdk}-${db}`
                        });
                    }
                } else {
                    sendButtonAnalytics("button_selfhosted_binary_download", "v5", {
                        login_supported: loginSupported,
                        option_clicked: sdk + "-" + db
                    });
                }

                await new Promise(r => setTimeout(r, 500));
            }
        } catch (ignored) {
            window.alert("Downloading binary failed. Please try again");
            return;
        }
        return;
    };

    const platformImageSrc: {
        [key: string]: string;
    } = {
        "mac": "/static/assets/use-oss/iconfinderApple3676183x.png",
        "windows": "/static/assets/use-oss/iconfinderWindows3676163x.png",
        "linux": "/static/assets/use-oss/iconfinderLinux23333863x.png"
    };

    const handleArmDownloads = (e: React.MouseEvent) => {
        e.preventDefault();
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        sendButtonAnalytics("button_linux_popup_close", "v5", {
            option_clicked: sdk + "-" + db
        });
        setShowPopup(false);
    };

    const doesCoreVersionHaveArchitectureOptions = () => {
        return os === "linux" && compareVersions(core, minCoreWithArmSupport);
    };

    const downloadLinkId = `binary-download-${sdk}-${db}-${os}-1`;

    return (
        <React.Fragment>
            <Modal
                show={showPopup}
                modalClosed={handlePopupClose}
            >
                <div className="pm-EmailSummary">
                    <button className="use-oss-popup-btn" onClick={handlePopupClose}>
                        <img src={"/static/assets/use-oss/close_popup_cross.svg"} alt="close popup" />
                    </button>
                    <h3 className="pm-heading">Please select your Linux platform</h3>

                    <div className="arm-download-btns">
                        <a
                            href="#"
                            className="arm-download-btn"
                            onClick={async e => await handleBinaryDownload(e, "linux")}
                        >
                            Linux/x64
                        </a>
                        <a
                            href="#"
                            className="arm-download-btn"
                            onClick={async e => await handleBinaryDownload(e, "linux-arm")}
                        >
                            Linux/AArch64/ARM
                        </a>
                    </div>
                </div>
            </Modal>

            {doesCoreVersionHaveArchitectureOptions() ? (
                <>
                    <div key={downloadLinkId} className="use-oss-download-item">
                        <img src={platformImageSrc[os]} alt={os} loading="lazy" />
                        <a href="#" id={downloadLinkId} onClick={handleArmDownloads}>
                            Download zip
                        </a>
                    </div>
                    <UseOssCopyBox
                        label="Curl Command for Linux/x64"
                        textToCopy={getCurlCommand(db, os, core)}
                        callbackAfterCopy={useOssCopyCurlCommandAnalytics}
                    />
                    <UseOssCopyBox
                        label="Curl Command for Linux/Arch64/Arm"
                        textToCopy={getCurlCommand(db, "linux-arm", core)}
                        callbackAfterCopy={useOssCopyCurlCommandAnalytics}
                    />
                </>
            ) : (
                <>
                    <div key={downloadLinkId} className="use-oss-download-item">
                        <img src={platformImageSrc[os]} alt={os} loading="lazy" />
                        <a
                            href="#"
                            id={downloadLinkId}
                            onClick={async e => await handleBinaryDownload(e, os)}
                        >
                            Download zip
                        </a>
                    </div>
                    <UseOssCopyBox
                        label="Curl Command"
                        textToCopy={getCurlCommand(db, os, core)}
                        callbackAfterCopy={useOssCopyCurlCommandAnalytics}
                    />
                </>
            )}
        </React.Fragment>
    );
};