import React, { useEffect, useState } from "react";
import { getDocVersions } from "../../api/docVersion";
import { getLocationPath } from "../../utils";

const AutogeneratedDocsVersionsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState<string[]>([]);
  const [sdkName, setSdkName] = useState<string>("");

  useEffect(() => {
    getAutogeneratedDocsVersionsList();
  }, [])

  const getAutogeneratedDocsVersionsList = async () => {
    try {
      // window.location.pathname returns /docs/<sdkName>/<version ( if any )>/<path>
      // so splitting by '/' returns ['', 'docs', '<sdkName>', ...path]
      const [_empty, _docs, sdk, ..._path] = getLocationPath().split("/");
      setSdkName(sdk);
  
      const versionsResponse = await getDocVersions(sdk);
  
      setVersions(versionsResponse.versions);
    } catch (ignored) {}
    setIsLoading(false);
  }

  if (isLoading) {
    return <p>Loading...</p>
  }

  return (
    <>
      {versions.map((version) => (
        <tr>
          <th>{version}</th>
          <td>
            <a href={`/docs/${sdkName}/${version}/modules.html`}>Documentation</a>
          </td>
        </tr>
      ))}
    </>
  );
};

export default AutogeneratedDocsVersionsList;
