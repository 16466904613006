import * as React from "react";
import { getCompatibility } from "../api/compatibility";
import { useAsync } from "../asyncReactUtils";
import { coresDataReducer, compatibilityDataReducer } from "./cacheReducers";
import { CoreSelection } from "./CoreSelection";
import { DatabaseSelection } from "./DatabaseSelection";
import { getDockerCommand } from "./getDockerCommand";
import { GetDownloadBinary } from "./getDownloadBinary";
import { planType, frontend, Db, Spinner, Platform, OSData } from "./index";
import { fetchAllAvailableCores } from "./useOssUtils";
import { SectionProps } from "./useOssTypes";
import { PlatformSelection } from "./PlatformSelection";
import getSupportedCores from "../api/plugin/dependency/cores";

export function Section({ sectionHeading, imgSrc, idPrefix, sdkData, dbData, showContactSectionOnDownload }: SectionProps) {
    const [selectedSdk, setSelectedSdk] = React.useState<string>(sdkData.drivers[0].id);
    const [selectedDb, setSelectedDb] = React.useState<string>(dbData.plugins[0].id);
    const [selectedOS, setSelectedOS] = React.useState<Platform>(OSData[0].id);
    const [selectedCore, setSelectedCore] = React.useState<string>("");
    const [coresCache, updateCoresCache] = React.useReducer(coresDataReducer, {});
    const [compatCache, updateCompatCache] = React.useReducer(compatibilityDataReducer, {});
    const [selectedDbDockerVersion, setSelectedDbDockerVersion] = React.useState("");

    const {
        data: compatibilityData,
        status: compatibilityStatus,
        run: fetchCompatibilityData,
        setData: setCompatData
    } = useAsync({
        status: "pending"
    });

    React.useEffect(() => {
        if (selectedSdk !== undefined && selectedDb !== undefined) {
            const cacheKey = idPrefix + selectedSdk + selectedDb;
            if (compatCache[cacheKey]) {
                setCompatData(compatCache[cacheKey]);
            } else {
                fetchCompatibilityData(
                    getCompatibility(planType, selectedSdk, selectedDb, frontend).then(compatData => {
                        updateCompatCache({ type: "ADD_COMPAT_DATA", cacheKey, compatData });
                        return compatData;
                    })
                );
            }
        }
    }, [idPrefix, selectedSdk, selectedDb, compatCache, updateCompatCache, fetchCompatibilityData, setCompatData]);

    const { data: coresData, status: coresStatus, run: fetchCoresData, setData: setCoresData } = useAsync({
        status: "pending"
    });

    React.useEffect(() => {
        if (selectedDb !== undefined && idPrefix === "binary") {
            const coresCacheKey = selectedDb;
            if (coresCache[coresCacheKey]) {
                setCoresData(coresCache[coresCacheKey]);
            } else {
                fetchCoresData(
                    fetchAllAvailableCores(selectedDb as Db).then(cData => {
                        updateCoresCache({ type: "ADD_CORES_DATA", coresCacheKey, cData });
                        return cData;
                    })
                );
            }
        }
    }, [idPrefix, selectedDb, coresCache, updateCoresCache, fetchCoresData, setCoresData]);

    const fetchDbDockerVersion = async () => {
        try {
            const response = await getSupportedCores(selectedDb, "FREE");
            if (response.cores.length > 0) {
               setSelectedDbDockerVersion(`:${response.cores[0]}`)
            }
        } catch(_) {}
    }

    React.useEffect(() => {
        void fetchDbDockerVersion()
    }, [selectedDb])

    return (
        <section className="section">
            <div className={`section__selections ${idPrefix}`}>
                <div className="section__header">
                    <h2 className="yellow-text">{sectionHeading}</h2>
                    <img src={imgSrc} loading="lazy" height="32" alt="" />
                </div>
                <hr className="section__line" />
                {selectedSdk !== undefined && (
                    <DatabaseSelection
                        idPrefix={idPrefix}
                        setDb={setSelectedDb}
                        dbData={dbData.plugins}
                        selectedSdk={selectedSdk} />
                )}
                {idPrefix === "binary" && (
                    <PlatformSelection
                        idPrefix={idPrefix}
                        setOS={setSelectedOS}
                        OSData={OSData}
                        selectedSdk={selectedSdk} />
                )}
                {idPrefix === "binary" && coresStatus === "resolved" && (
                    <CoreSelection
                        cores={coresData}
                        setSelectedCore={setSelectedCore}
                    />
                )}

                {idPrefix === "docker" && (
                    <hr className="section__line" />
                )}
            </div>

            <div className={`section__selected ${idPrefix}`}>
                {selectedDb === "mongodb" && (
                    <p className="use-oss__mongodb-desclaimer">
                        <span className="use-oss__mongodb-desclaimer__bold">Note:</span> We currently support only session management for MongoDB. Support for login will be added soon.
                    </p>
                )}

                {compatibilityStatus === "pending" && <Spinner />}
                {compatibilityStatus === "rejected" && <p className="bold-info danger-info">Something went wrong.</p>}


                {idPrefix === "docker" &&
                    compatibilityStatus === "resolved" &&
                    getDockerCommand({
                        idPrefix,
                        compatibilityData,
                        drivers: sdkData.drivers,
                        plugins: dbData.plugins,
                        sdk: selectedSdk,
                        db: selectedDb,
                        selectedDbDockerVersion,
                    })}
                {idPrefix === "binary" &&
                    compatibilityStatus === "resolved" &&
                    coresStatus === "resolved" &&
                    <GetDownloadBinary
                        sdk={selectedSdk}
                        db={selectedDb}
                        compatibilityData={compatibilityData}
                        core={selectedCore}
                        showContactSectionOnDownload={showContactSectionOnDownload}
                        os={selectedOS}
                    />
                }
            </div>
        </section>
    );
}
