import React, { createContext, useContext, useEffect, useState } from "react";

import Modal from "../useOss/Modal";
import PaymentsModal from "../components/modals/payments-modals/PaymentsModal";
import { PaymentDetailsRedirectContext } from "../utils/payments";

type OpenPaymentModal = (PaymentDetailsRedirectContext: PaymentDetailsRedirectContext,justSaveContext?:boolean) => void;

export type PaymentModalContextType = {
    isModalOpen: boolean;
    openModal: OpenPaymentModal;
    closeModal: () => void;
    paymentDetailsRedirectContext: PaymentDetailsRedirectContext | undefined;
};

export const PaymentsModalContext = createContext<PaymentModalContextType | undefined>(undefined);

export let openPaymentModal: OpenPaymentModal = () => {};

export default function PaymentsModalProvider({ children }: { children: React.ReactNode }) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [paymentDetailsRedirectContext, setPaymentDetailsRedirectContext] = useState<PaymentDetailsRedirectContext>();

    //refreshOnClose tells whether to refresh the page when the
    //modal is closed or not. pass true as a param inorder to refresh the page on closing the modal.
    function openModal(paymentDetailsRedirectContext: PaymentDetailsRedirectContext, justSaveContext?: boolean) {
        setPaymentDetailsRedirectContext(paymentDetailsRedirectContext);
        if (typeof justSaveContext === "undefined" || justSaveContext === false) {
            setIsModalOpen(true)
        }
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    useEffect(() => {
        openPaymentModal = openModal;
    }, []);

    return (
        <PaymentsModalContext.Provider value={{ isModalOpen,paymentDetailsRedirectContext, openModal, closeModal }}>
            {children}
            <div id="payments-modal" className="saas-container">
                {isModalOpen ? (
                    <Modal disableBackdropClose={true} show={isModalOpen} modalClosed={closeModal}>
                        <PaymentsModal />
                    </Modal>
                ) : null}
            </div>
        </PaymentsModalContext.Provider>
    );
}

export function usePaymentsModal() {
    const context = useContext(PaymentsModalContext);

    if (context === undefined) {
        throw new Error("usePaymentsModal can only be use within the PaymentsModalProvider.");
    }

    return context;
}
