import React from "react";
import Prism from "prismjs";
import "prismjs/components/prism-yaml";

import dockerComposeFileContents, { getDockerComposeFileContentsForDb } from "./DockerComposeFileContents";
import { copyTextToClipboard } from "../utils";
import { useOssCopyDockerComposeFileContent } from "./useOssAnalytics";

import "../style/prism-vscode-theme.module.css";

Prism.manual = true;

type DockerComposeFilePropType = {
  db: string;
  dockerVersion: string;
};

const DockerComposeFile: React.FC<DockerComposeFilePropType> = ({ db, dockerVersion }) => {
  const [isFileVisible, setIsFileVisible] = React.useState(false);
  const [fileContent, setFileContent] = React.useState<string>("");
  const [tokenizedFileContent, setTokenizedFileContent] = React.useState<string>("");
  const copyAlertRef = React.useRef<HTMLSpanElement>(null);

  const toggleFileVisibilityState = () => {
    setIsFileVisible((prevState) => !prevState);
  }

  React.useEffect(() => {
    if (Object.keys(dockerComposeFileContents).includes(db)) {
      const newFileContent = getDockerComposeFileContentsForDb(db, dockerVersion);
      const tokenizedContentString = Prism.highlight(newFileContent, Prism.languages.yml, "yml");
      setTokenizedFileContent(tokenizedContentString);
      setFileContent(newFileContent);
    }
  }, [db, dockerVersion]);

  const handleCopyButtonClick = () => {
    copyTextToClipboard(fileContent);
    showAlert();

    useOssCopyDockerComposeFileContent();
  }

  const showAlert = () => {
    if (copyAlertRef.current) {
      copyAlertRef.current.classList.add("visible")
      setTimeout(hideAlert, 1000)
    }
  }

  const hideAlert = () => {
    if (copyAlertRef.current) {
      copyAlertRef.current.classList.remove("visible")
    }
  }

  if (db === "mongodb") {
    return <></>;
  }

  return (
    <div className="use-oss-docker-compose">
      <p className="use-oss-docker-compose__title">Here's the docker compose file:</p>

      <div
        className={`use-oss-docker-compose__file-title ${isFileVisible ? "active" : ""}`}
        onClick={toggleFileVisibilityState}
      >
        <div className="use-oss-docker-compose__file-title__name">
          <img src="/static/assets/use-oss/file-icon.png" alt="File icon" />
          <p>docker-compose.yml</p>
        </div>

        <img src="/static/assets/use-oss/right-chevron-icon.png" alt="Chevron icon" />
      </div>

      <div className="use-oss-docker-compose__content-container">
        <div className={`use-oss-docker-compose__content ${isFileVisible ? "visible" : ""}`}>
          <pre className="language-yaml" dangerouslySetInnerHTML={{ __html: tokenizedFileContent }}>
          </pre>
        </div>
        <div
          className={`use-oss-docker-compose__content__copy-button-container ${isFileVisible ? "visible" : ""}`}
        >
          <button onClick={handleCopyButtonClick} className="use-oss-docker-compose__content__copy-button-container__copy-button">
            <img src="/static/assets/docker-compatibility/copy-icon.png" alt="Copy docker compose file code" />
          </button>

          <span ref={copyAlertRef} className="use-oss-docker-compose-copy-button__alert">Copied!</span>
        </div>
      </div>
    </div>
  )
};

export default DockerComposeFile;
