export class PaymentsError extends Error {
    statusCode = 402;
    status = "PAYMENT_DETAILS_NOT_FOUND";
    redirectionURL = "";

    constructor(redirectionURL: string) {
        super("This action requires payment details");
        this.redirectionURL = redirectionURL;
    }

    static isThisError(err: any): boolean {
        return err.status === "PAYMENT_DETAILS_NOT_FOUND";
    }
}
