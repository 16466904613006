import React, { useEffect, useState } from "react";

import { UseDesktopOnMobilePrompt } from "../../dashboard/useDesktopOnMobilePrompt";
import Footer from "../../footer";
import { getIsUserAllowedToAccessBilling } from "../../api/user/billing/access/check";
import "../../style/dashboard-saas.module.css";
import { useSaasNavigation } from "../navigation";
import Navbar from "../sections/navbar";
import HowDidYouHearAboutUs from "../../dashboard/howDidYouHearAboutUs";

export default function SaasLayout({ children }: { children: React.ReactNode }) {
    const [shouldShowBillingSection, setShouldShowBillingSection] = useState(false);
    const [accessToBillingError, setAccessToBillingError] = useState<boolean | undefined>(undefined);
    const [isFetchingAccessToBillingInfo, setIsFetchingAccessToBillingInfo] = useState(false);
    const { activeItem, SaasNavigation } = useSaasNavigation({ shouldShowBillingSection });

    async function checkIfUserIsAllowedToAccessTheBillingSection() {
        setIsFetchingAccessToBillingInfo(true);
        try {
            const data = await getIsUserAllowedToAccessBilling();
            setShouldShowBillingSection(data.hasBillingAccess);
        } catch {
            setAccessToBillingError(true);
        } finally {
            setIsFetchingAccessToBillingInfo(false);
        }
    }

    function renderDesktopOnMobilePrompt() {
        switch (activeItem) {
            case "settings":
                // Only the settings page is responsive for now
                return null;
            default:
                return <UseDesktopOnMobilePrompt />;
        }
    }

    useEffect(() => {
        checkIfUserIsAllowedToAccessTheBillingSection();
    }, []);

    return (
        <>
            <Navbar />
            {renderDesktopOnMobilePrompt()}
            <HowDidYouHearAboutUs />
            <div style={{ display: "flex", backgroundColor: "#f8f8f8" }}>
                {accessToBillingError && isFetchingAccessToBillingInfo === false ? (
                    <div className="saas-container billing-error-container">
                        <p>Something went wrong while fetching your billing information. Please try again!</p>
                        <button
                            onClick={checkIfUserIsAllowedToAccessTheBillingSection}
                            className="btn btn-sm yellow-button"
                        >
                            Try again
                        </button>
                    </div>
                ) : (
                    <>
                        {SaasNavigation}
                        {children}
                    </>
                )}
            </div>
            <Footer />
        </>
    );
}
