import React, { useContext } from "react";
import { PopupContentContext } from "../../context/PopupContentContext";
import "./../../style/notification.module.css"
import TemporaryContent, { TemporaryContentProps } from './TemporaryContent';

type NotificationProps = {
  status: "success" | "error";
  size?:"sm";
  icon?: string,
  autoDisappear?: boolean
  duration?: number;
};

export type ToastNotificationProps = Omit<TemporaryContentProps, 'contentAfterDisappear'> & NotificationProps

const InlineNotificationContent: React.FC<{status: string, icon: string, size?: "sm"}> = ({status, icon, children, size}) => {
  return <div className={`inline-notification notification-${status}`}>
    <div className="inline-notification__icon">
      <img src={icon} alt="success-icon" />
    </div>
    <div className={`inline-notification__content ${size ?? ""}`}>{children}</div>
  </div>;
}

/**
 * Display Notification that is inline with the content 
 */
export const InlineNotification: React.FC<NotificationProps> = ({status = "success", children, icon: customIcon, autoDisappear, duration, size}) => {
  const icon = customIcon !== undefined ? customIcon : 
    `/static/assets/dashboard/${status === "success" ? "success" : "alert"}.png`;
  const content = <InlineNotificationContent status={status} size={size} icon={icon}>{children}</InlineNotificationContent>;
  return <>
    { autoDisappear === true ? <TemporaryContent duration={duration}>{content}</TemporaryContent> : content }
  </>
}

export const ToastNotification: React.FC<ToastNotificationProps> = (props: ToastNotificationProps) => {
  const { icon, status, children, duration, onDisappear } = props

  return (
    <TemporaryContent duration={duration} onDisappear={onDisappear}>
      <div className={`toast-notification notification-${status}`}>
        <span className='toast-notification__icon'>
          <img src={icon} alt={status} />
        </span>
        <span className='toast-notification__info'>{children}</span>
        <span className='toast-notification__close' onClick={onDisappear}>
          <img src={`/static/assets/dashboard/${status==="error"?"close-icon-red.png":"close-green.png"}` } />
        </span>
      </div>
    </TemporaryContent>
  )
}

/**
 * Display list of ToastNotification stacked on top right of screen
 * @returns 
 */
export const ToastNotificationContainer: React.FC = () => {
  const { toasts, removeToast } = useContext(PopupContentContext)

  return (
    <div className='toast-notification-container'>
      {toasts.map((toast) => (
        <ToastNotification
          {...toast}
          key={toast.id}
          onDisappear={() => removeToast(toast.id) }
        />
      ))}
    </div>
  )
}

export default InlineNotification;
