/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { getRoutingComponent } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";

import { UseDesktopOnMobilePrompt } from "../dashboard/useDesktopOnMobilePrompt";
import Footer from "../footer";
import { getLocationPath } from "../utils";
import CommonContainerWrapper from "./CommonContainerWrapper";
import Header from "../darkhome/Header";

const Auth = () => {
    if (getLocationPath().startsWith("/auth")) {
        return (
            <div>
                <div style={{ position: "absolute" }}>
                    <Header />
                </div>
                <CommonContainerWrapper>
                    {getRoutingComponent([ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])}
                </CommonContainerWrapper>
                <UseDesktopOnMobilePrompt />
                <Footer />
            </div>
        );
    } else {
        return (
            <div>
                <div
                    css={css`
                        @media (max-width: 1024px) {
                            display: none;
                        }
                    `}
                >
                    <div>{getRoutingComponent([ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI])}</div>
                </div>
                <UseDesktopOnMobilePrompt />
                <Footer />
            </div>
        );
    }
};

export default Auth;
