import * as React from "react";

import "../style/footer.module.css";
import { getPathAndSelectedPage, sendButtonAnalytics } from "../utils";

export default function Footer() {
    function handleHomeLogoClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "home" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_home", "v5", { page_selected: pageSelected });
        }
    }

    function handlePricingClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "pricing" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_pricing", "v5", { page_selected: pageSelected });
        }
    }

    function handleProductRoadmapClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "product-roadmap" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_product_roadmap", "v5", { page_selected: pageSelected });
        }
    }

    function handleDocsClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "docs" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_documentation", "v5", { page_selected: pageSelected });
        }
    }

    function handleContributeClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "contribute" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_contribute", "v5", { page_selected: pageSelected });
        }
    }

    function handleBlogClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "blog" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_blog", "v5", { page_selected: pageSelected });
        }
    }

    function handleAboutUsClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "about-us" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_about-us", "v5", {
                page_selected: pageSelected
            });
        }
    }

    function handleCareersClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "blog" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_blog", "v5", { page_selected: pageSelected });
        }
    }

    function handleConsultancyClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "consultancy" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_getademo", "v5", {
                page_selected: pageSelected,
                button_text: "Consult an expert"
            });
        }
    }

    function handleUseOssClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "use-oss" && pageSelected !== undefined) {
            sendButtonAnalytics("button_footer_ST_selfhosted", "v5", { page_selected: pageSelected });
        }
    }

    function handleTosClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_TOS", "v5", { page_selected: pageSelected });
    }

    function handleAmbassadorsClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_ambassadors", "v5", { page_selected: pageSelected });
    }

    function handlePrivacyClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_privacypolicy", "v5", { page_selected: pageSelected });
    }

    function handleSecurityClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_security", "v5", { page_selected: pageSelected });
    }

    function handleEngineeringClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_engineering", "v1", { page_selected: pageSelected });
    }

    function handleDiscordClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_discord", "v5", { page_selected: pageSelected });
    }

    function handleApiStatusClick() {
        sendButtonAnalytics("button_footer_apistatus", "v5");
    }

    function handleCustomersClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_customers", "v5", { page_selected: pageSelected });
    }

    function handleCommunityClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_community", "v5", { page_selected: pageSelected });
    }

    function handleComparisonBlogPostClick() {
        sendButtonAnalytics("button_footer_comparison-blog", "v5");
    }

    function handleJwtDecoderClick() {
        sendButtonAnalytics("button_footer_jwtdecoder", "v5");
    }

    function handleMagicLinksClick() {
        sendButtonAnalytics("button_footer_magiclinks", "v5");
    }

    function handleSSOClick() {
        sendButtonAnalytics("button_footer_sso", "v5");
    }

    function handleMultitenancyClick() {
        sendButtonAnalytics("button_footer_multi_tenant", "v5");
    }

    function handleAccountLinkingClick() {
        sendButtonAnalytics("button_footer_account_linking", "v5");
    }
    function handleAttackProtectionSuiteClick() {
        sendButtonAnalytics("button_footer_attack_protection_suite", "v5");
    }

    function handleSocialLoginClick() {
        sendButtonAnalytics("button_footer_social_login", "v5");
    }

    function handlePasswordlessLoginClick() {
        sendButtonAnalytics("button_footer_passwordless_login", "v5");
    }

    function handleEmailPasswordLoginClick() {
        sendButtonAnalytics("button_footer_email_password_login", "v5");
    }

    // return (<></>)
    return (
        <div id="footer-container">
            <section>
                <div className="footer">
                    <div className="section-column link-list">
                        <div className="section-div">
                            <span className="footer-heading">Product</span>
                            <a href="/pricing" target="_blank" onClick={handlePricingClick}>
                                Pricing
                            </a>
                            <a href="/product-roadmap" target="_blank" onClick={handleProductRoadmapClick}>
                                Product Roadmap
                            </a>
                            <a
                                href="/docs/passwordless/pre-built-ui/setup/core/with-docker"
                                target="_blank"
                                onClick={handleUseOssClick}
                            >
                                Self Hosted
                            </a>
                        </div>
                        <div className="section-div">
                            <span className="footer-heading space-top">Developers</span>
                            <a href="/docs/guides" target="_blank" onClick={handleDocsClick}>
                                Recipe/Implementation guides
                            </a>
                            <a href="/docs/community/apis" target="_blank" onClick={handleDocsClick}>
                                API References
                            </a>
                        </div>
                    </div>
                    <div className="section-column link-list">
                        <div className="section-div resources full-height">
                            <span className="footer-heading">Features</span>
                            <a href="/features/email-magic-links" target="_blank" onClick={handleMagicLinksClick}>
                                Magic Link
                            </a>
                            <a href="/features/single-sign-on" target="_blank" onClick={handleSSOClick}>
                                SSO (Single-Sign On)
                            </a>
                            <a href="/features/multi-tenancy" target="_blank" onClick={handleMultitenancyClick}>
                                Multi-Tenant
                            </a>
                            <a href="/features/account-linking" target="_blank" onClick={handleAccountLinkingClick}>
                                Account Linking
                            </a>
                            <a
                                href="/features/attack-protection-suite"
                                target="_blank"
                                onClick={handleAttackProtectionSuiteClick}
                            >
                                Attack Protection Suite
                            </a>
                            <a href="/features/social-login" target="_blank" onClick={handleSocialLoginClick}>
                                Social Login
                            </a>
                            <a
                                href="/features/passwordless-login"
                                target="_blank"
                                onClick={handlePasswordlessLoginClick}
                            >
                                Passwordless Login
                            </a>
                            <a
                                href="/features/email-password-authentication"
                                target="_blank"
                                onClick={handleEmailPasswordLoginClick}
                            >
                                Email Password Login
                            </a>

                            <div className="spacer" />
                        </div>
                    </div>
                    <div className="section-column link-list">
                        <div className="section-div resources full-height">
                            <span className="footer-heading">Company</span>
                            <a href="/about-us" target="_blank" onClick={handleAboutUsClick}>
                                About Us
                            </a>
                            <a href="/blog" target="_blank" onClick={handleBlogClick}>
                                Blog
                            </a>
                            <a href="/careers" target="_blank" onClick={handleCareersClick}>
                                Careers
                            </a>
                            <a href="/customers" target="_blank" onClick={handleCustomersClick}>
                                Customers
                            </a>
                            <a href="https://community.supertokens.com" target="_blank" onClick={handleCommunityClick}>
                                Community
                            </a>
                            <a href="/engineering" target="_blank" onClick={handleEngineeringClick}></a>
                            <a href="/security" target="_blank" onClick={handleSecurityClick}>
                                Security at SuperTokens
                            </a>
                        </div>
                    </div>
                    <div className="section-column link-list">
                        <div className="section-div resources">
                            <span className="footer-heading">Resources</span>
                            <a href="/discord" target="_blank" onClick={handleDiscordClick}>
                                Support
                            </a>
                            <a href="/docs/contribute/introduction" target="_blank" onClick={handleContributeClick}>
                                Contribute
                            </a>
                            <a href="/consultancy" target="_blank" onClick={handleConsultancyClick}>
                                Contact Sales
                            </a>
                            <a href="/legal/terms-and-conditions" target="_blank" onClick={handleTosClick}>
                                Terms of Service
                            </a>
                            <a href="/legal/privacy-policy" target="_blank" onClick={handlePrivacyClick}>
                                Privacy Policy
                            </a>
                            <a
                                href="https://supertokens.instatus.com/"
                                onClick={handleApiStatusClick}
                                target="_blank"
                                className="footer_uptime_link"
                            >
                                API Status
                                <span className="footer_uptime_link__pill">
                                    <img src="/static/assets/footer_api_status_caret_up.png" alt="API Status Caret" />
                                    99.99%
                                </span>
                            </a>
                            <a
                                href="/blog/auth0-alternatives-auth0-vs-okta-vs-cognito-vs-supertokens"
                                onClick={handleComparisonBlogPostClick}
                                target="_blank"
                            >
                                Auth0 vs SuperTokens
                                <br /> vs Cognito
                            </a>
                            <a href="/jwt-encoder-decoder" target="_blank" onClick={handleJwtDecoderClick}>
                                JWT Decoder
                            </a>
                        </div>
                    </div>
                </div>
                <div className="divider" />
                <div className="footer-bottom">
                    <div className="compliance-container">
                        <div>
                            <a href="/" className="footer-logo-link" onClick={handleHomeLogoClick}>
                                <img
                                    src="/static/assets/dark-home/logo.png"
                                    className="footer-logo"
                                    alt="SuperTokens homepage"
                                />
                            </a>
                            <div className="spacer" />
                            <p className="copyright">©SuperTokens {new Date().getFullYear()}</p>
                        </div>

                        <SocialLinkSection className="hidden-lg" />

                        <div className="compliance">
                            <a className="link-container" href="https://security.supertokens.com/" target={"_blank"}>
                                <img className="img-compliance" src="/static/assets/compliance/soc2.png" />

                                <span className="link-text">View Report</span>
                            </a>
                            <img
                                style={{
                                    // This is because at the time GDPR was still in progress,
                                    // Remove this style to start showing the icon
                                    display: "none"
                                }}
                                className="img-compliance space-left"
                                src="/static/assets/compliance/gdpr.png"
                            />
                        </div>
                    </div>
                    <SocialLinkSection className="hidden-md" />
                </div>
            </section>
        </div>
    );
}

type SocialLinksSectionProps = {
    className?: string;
};

function SocialLinkSection({ className }: SocialLinksSectionProps) {
    function renderSocialIcons(imageUrl: string, link: string, alt: string) {
        let className = imageUrl.split(".")[0];

        return (
            <a key={link} rel="noopener" onClick={() => window.open(link, "_blank")}>
                <img className={`img-${className}`} src={`/static/assets/social-icons/${imageUrl}`} alt={alt} />
            </a>
        );
    }

    function handleDiscordClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_footer_discord", "v5", { page_selected: pageSelected });
    }

    return (
        <div className={`section-div reach-us ${className}`}>
            <div className="social-links">
                <a href="/discord" target="_blank" id="footer-discord" onClick={handleDiscordClick}>
                    <img
                        src="/static/assets/footerDiscord.png"
                        alt="Join SuperTokens on Discord"
                        className="discord-logo"
                    />
                </a>
                {renderSocialIcons("mail.png", "mailto:support@supertokens.com", "Email SuperTokens")}
                {renderSocialIcons(
                    "linkedin.png",
                    "https://www.linkedin.com/company/supertokens/",
                    "Connect with SuperTokens on LinkedIn"
                )}
                {renderSocialIcons(
                    "twitter.png",
                    "https://twitter.com/supertokensio",
                    "Connect with SuperTokens on Twitter"
                )}
                {renderSocialIcons(
                    "git.png",
                    "https://github.com/supertokens/supertokens-core",
                    "SuperTokens on Github"
                )}
            </div>
        </div>
    );
}
