import { API_URL } from "../../../../constants";
import * as httpNetworking from "../../../../httpNetworking";

const URL = API_URL + "/user/billing/access/check";
const VERSION = 0;

export async function getIsUserAllowedToAccessBilling(): Promise<{
    hasBillingAccess: boolean;
}> {
    const options: httpNetworking.GETRequestConfig = {
        timeout: 50000
    };

    const result = await httpNetworking.simpleGETRequest(URL, options, VERSION);
    return result.data;
}
