import React from "react";
import { copyTextToClipboard } from "../utils";

type UseOssCopyBoxProps = {
  label: string;
  textToCopy: string;
  callbackAfterCopy?: Function;
  callbackAfterHighlight?: Function;
  className?: string;
};

const UseOssCopyBox: React.FC<UseOssCopyBoxProps> = (props) => {
  const copyAlertRef = React.useRef<HTMLSpanElement>(null);

  const handleCopy = () => {
    copyTextToClipboard(props.textToCopy);
    showAlert();

    if (props.callbackAfterCopy) {
      props.callbackAfterCopy();
    }
  };

  const handleSelection = () => {
    if (props.callbackAfterHighlight) {
      props.callbackAfterHighlight();
    }
  }

  const handleMouseEnter = () => {
    if (props.callbackAfterHighlight) {
      document.addEventListener("selectionchange", handleSelection);
    }
  }

  const handleMouseLeave = () => {
    if (props.callbackAfterHighlight) {
      document.removeEventListener("selectionchange", handleSelection);
    }
  }

  const showAlert = () => {
    if (copyAlertRef.current) {
      copyAlertRef.current.classList.add("visible")
      setTimeout(hideAlert, 1000)
    }
  }

  const hideAlert = () => {
    if (copyAlertRef.current) {
      copyAlertRef.current.classList.remove("visible")
    }
  }

  return (
    <div className={`use-oss-copy-box ${props.className || ""}`}>
      <p className="use-oss-copy-box__title">{props.label}:</p>

      <div className="use-oss-copy-box__label-container">
        <div className="use-oss-copy-box__container">
          <div className="use-oss-copy-box__container__command">
            <p onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {props.textToCopy}
            </p>
          </div>

          <button
            className="use-oss-copy-box__container__copy-button"
            onClick={handleCopy}
          >
            <img src="/static/assets/docker-compatibility/copy-icon.png" alt="Copy icon" />
          </button>
        </div>

        <span
          className="use-oss-copy-box__alert"
          ref={copyAlertRef}
        >Copied!</span>
      </div>
    </div>
  );
};

export default UseOssCopyBox;
