import { getAnalytics } from "../utils";

const authAnalytics = {
    // when the user clicks on join us on discord link
    joinDiscordLinkClickAction: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_sign_up_discord",
                {
                    type: "page_view"
                },
                "v5"
            )
        );
    },
    // when the user selects a brand color from the color buttons
    selectBrandColorAction: (color: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_sign_up_customisation_brandcolor",
                {
                    type: "page_view",
                    option_clicked: color
                },
                "v5"
            )
        );
    },
    // when the user selects a font size from the font size select menu
    selectFontSizeAction: (fontSize: string) => {
        // the value is passed as '14px', so we only select the '14' here
        const fontSizeIntegerPart = fontSize.match(/\d+/);
        if (fontSizeIntegerPart) {
            getAnalytics().then((stAnalytics: any) =>
                stAnalytics.sendEvent(
                    "button_sign_up_customisation_fontsize",
                    {
                        type: "page_view",
                        option_clicked: fontSizeIntegerPart[0]
                    },
                    "v5"
                )
            );
        }
    },
    // when the user selects a font family from the font family select menu
    selectFontFamilyAction: (fontFamily: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_sign_up_customisation_fontfamily",
                {
                    type: "page_view",
                    option_clicked: fontFamily
                },
                "v5"
            )
        );
    },
    // when the user presses on the toggle checkboxes
    toggleCheckboxClickedAction: (checkboxType: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_sign_up_customisation_toggle",
                {
                    type: "page_view",
                    option_clicked: checkboxType
                },
                "v5"
            )
        );
    },
    // when the user clicks on the 'here' link in self hosted note
    selfHostedNoteClickAction: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_signup_selfhostednote_here",
                {
                    type: "page_view"
                },
                "v5"
            )
        );
    }
};

export default authAnalytics;
