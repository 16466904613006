import * as React from 'react'
import "./../../style/button-box.module.css"
import "./../../style/loading.module.css"

export const Loading = () => {
    return (
        <div
            id="checkout-loading-container">
            <div className="st-spinner"></div>
        </div>
    );
}
