import React, { useContext, useEffect, useState } from "react";
import Modal from "../useOss/Modal";
import "../style/how-did-you-hear-about-us.module.css";
import { getHeardFromMoreDetails, heardFromOptions } from "../constants";
import sendHeardAboutUsData from "../api/heardAboutUs";
import { UserContext } from "../context/UserContext";
import { getCurrentUserId } from "../utils";
import { isWeakMap } from "lodash";
import sendProspectToSalesEmail from "../api/salesProspect";

const nonWorkEmail = [
    "gmail",
    "outlook",
    "hotmail",
    "live",
    "yahoo",
    "icloud",
    "protonmail",
    "zoho",
    "aol",
    "gmx",
    "yandex",
    "yandex.ru",
    "mail",
    "tutanota",
    "fastmail",
    "lycos",
    "hushmail",
    "runbox"
];

const isNonWorkEmail = (email: string) => {
    const isNotAWorkingMail = nonWorkEmail.includes(email.split("@")[1].split(".")[0]);
    return isNotAWorkingMail;
};

const getCancelIcon = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => close()}
    >
        <path
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
            fill="#E8E8E8"
        />
        <path
            d="M7.2481 14.89C7.40424 15.0431 7.61474 15.1279 7.83337 15.1258C8.052 15.1238 8.26088 15.035 8.4141 14.879L11.0041 12.239L13.5951 14.879C13.6699 14.9606 13.7604 15.0264 13.8611 15.0723C13.9618 15.1182 14.0708 15.1433 14.1814 15.1463C14.2921 15.1492 14.4022 15.1298 14.5052 15.0893C14.6082 15.0488 14.7021 14.9879 14.7811 14.9104C14.8601 14.8329 14.9227 14.7402 14.9651 14.638C15.0076 14.5357 15.029 14.426 15.0282 14.3153C15.0274 14.2046 15.0043 14.0952 14.9603 13.9936C14.9163 13.8921 14.8523 13.8004 14.7721 13.724L12.1601 11.063L14.7721 8.40103C14.9167 8.24332 14.9943 8.03562 14.9887 7.82175C14.983 7.60787 14.8945 7.40456 14.7418 7.25471C14.5891 7.10486 14.3841 7.0202 14.1702 7.01858C13.9562 7.01696 13.7501 7.09851 13.5951 7.24603L11.0041 9.88603L8.4141 7.24603C8.3393 7.16443 8.24883 7.09872 8.14809 7.05281C8.04736 7.0069 7.93841 6.98173 7.82775 6.9788C7.71709 6.97588 7.60698 6.99526 7.50396 7.03578C7.40094 7.0763 7.30712 7.13714 7.22811 7.21467C7.1491 7.29221 7.0865 7.38485 7.04405 7.48709C7.00159 7.58932 6.98014 7.69907 6.98098 7.80977C6.98182 7.92046 7.00493 8.02986 7.04893 8.13144C7.09293 8.23302 7.15692 8.3247 7.2371 8.40103L9.8491 11.063L7.2371 13.724C7.08405 13.8802 6.99924 14.0907 7.00131 14.3093C7.00337 14.5279 7.09214 14.7368 7.2481 14.89Z"
            fill="#6E6A65"
        />
    </svg>
);

export default function HowDidYouHearAboutUs() {
    const [state, setState] = useState<"IDLE" | "SHOW_PROMPT">("IDLE");
    const isModalOpen = state === "SHOW_PROMPT";
    const [heardFrom, setHeardFrom] = useState<string>("");
    const [heardFromDetails, setHeardFromDetails] = useState("");
    const [companySize, setCompanySize] = useState<string>("");

    const userContext = useContext(UserContext);

    const howDidYouHearAboutUsDetails = getHeardFromMoreDetails(heardFrom);

    const close = () => {
        setState("IDLE");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const user = userContext ? userContext.user : "";
            const username = user ? user.name : "";
            const email = user ? user.email : "";
            const country = user ? user.country : "";
            const analyticsUserId = await getCurrentUserId();

            const userData = {
                source: heardFrom,
                detail: heardFromDetails,
                name: username,
                email,
                analyticsUserId,
                country,
                companySize: companySize || "no_selection"
            };

            sendHeardAboutUsData(userData);

            if ((companySize === "200+" || companySize === "20 - 200") && !isNonWorkEmail(email)) {
                sendProspectToSalesEmail(userData);
            }
        } catch (error) {
            // Do nothing
        } finally {
            close();
        }
    };

    useEffect(() => {
        const userJustSignedUp = localStorage.getItem("userJustSignedUp");
        if (userJustSignedUp && userJustSignedUp === "true") {
            setState("SHOW_PROMPT");
            localStorage.removeItem("userJustSignedUp");
        }
    }, []);

    switch (state) {
        case "IDLE":
            return null;
        case "SHOW_PROMPT":
            return (
                <Modal show={isModalOpen} modalClosed={() => close()} className={`${!isModalOpen && "hide"}`}>
                    <div className="how-did-you-hear-about-us-container">
                        <div className="how-did-you-hear-about-us-header">
                            <span onClick={() => close()}>{getCancelIcon()}</span>
                        </div>
                        <div className="how-did-you-hear-about-us-info">
                            Help us solve the mystery! 🕵️‍♂️ Let us know how you found us so we can thank the internet gods
                            (or your awesome friend).
                        </div>
                        <div className="how-did-you-hear-about-us-body">
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="heardFrom">How did you hear about us?</label>
                                <select
                                    name="heardFrom"
                                    id="heardFrom"
                                    value={heardFrom || ""}
                                    onChange={e => setHeardFrom(e.target.value)}
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    {heardFromOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                                {(() => {
                                    if (!howDidYouHearAboutUsDetails) return null;
                                    switch (howDidYouHearAboutUsDetails.type) {
                                        case "textInput":
                                            return (
                                                <>
                                                    <label htmlFor="details">
                                                        {howDidYouHearAboutUsDetails.description}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="details"
                                                        id="details"
                                                        placeholder=""
                                                        onChange={e => setHeardFromDetails(e.target.value)}
                                                        autoFocus
                                                    />
                                                </>
                                            );
                                        case "dropdown":
                                            return (
                                                <>
                                                    <label htmlFor="details">
                                                        {howDidYouHearAboutUsDetails.description}
                                                    </label>{" "}
                                                    <select
                                                        name="details"
                                                        id="details"
                                                        placeholder="Select an option"
                                                        onChange={e => setHeardFromDetails(e.target.value)}
                                                        value={heardFromDetails}
                                                    >
                                                        <option value="" disabled>
                                                            Select an option
                                                        </option>
                                                        {howDidYouHearAboutUsDetails.options.map(option => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            );
                                        default:
                                            return null;
                                    }
                                })()}

                                <label htmlFor="companySize">How many people work in your company?</label>

                                <div className="company-size-button-group">
                                    <button
                                        className={`company-size-btn ${companySize === "1 - 20" ? "active" : ""}`}
                                        onClick={() => setCompanySize("1 - 20")}
                                        type="button"
                                    >
                                        1 - 20
                                    </button>
                                    <button
                                        className={`company-size-btn ${companySize === "20 - 200" ? "active" : ""}`}
                                        onClick={() => setCompanySize("20 - 200")}
                                        type="button"
                                    >
                                        20 - 200
                                    </button>
                                    <button
                                        className={`company-size-btn ${companySize === "200+" ? "active" : ""}`}
                                        onClick={() => setCompanySize("200+")}
                                        type="button"
                                    >
                                        200+
                                    </button>
                                </div>

                                <hr />
                                <div className="how-did-you-hear-about-us-footer">
                                    <button type="submit" className="footer-button submit-button">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            );
    }
}
