import React, {useState, useEffect, useRef } from "react";

export type TemporaryContentProps = {
  /** duration before the content disappear (in miliseconds)*/
  autoDismiss?: boolean,
  duration?: number;
  contentAfterDisappear?: React.ReactNode,
  /** Main content */
  children?: React.ReactNode,
  onDisappear?: () => void
  /** Ref of function that make the element disappears which can be triggered from the parent */
  closeRef?: React.MutableRefObject<Function | undefined>
};
const DEFAULT_TIMER_DURATION = 3000;

/**
 * Simple component that will display one-time content, 
 * and then disappears or replaced by `props.contentAfterDisappear` after `props.duration` miliseconds 
 */
export const TemporaryContent: React.FC<TemporaryContentProps> = ({ duration = DEFAULT_TIMER_DURATION, children, contentAfterDisappear, closeRef, onDisappear, autoDismiss }) => {
  const [showChildern, setShowChildern] = useState(true);
  const timerRef: React.MutableRefObject<null | NodeJS.Timeout> = useRef(null);
  const startTimer = () => { 
    if (autoDismiss === false) {
      return;
    }

    timerRef.current = setTimeout(() => {
      setShowChildern(false);
      if (onDisappear !== undefined) {
				onDisappear();
			}
    }, duration) as any as NodeJS.Timeout;
  }
  
  useEffect(() => {
    startTimer();
    if (closeRef !== undefined) {
      closeRef.current = () => setShowChildern(false);
    }
    return () => {
      if (timerRef.current != undefined) { clearTimeout(timerRef.current)}
    }
  }, [])
  return <>{showChildern ? children : contentAfterDisappear}</>
}

export default TemporaryContent;


