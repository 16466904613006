import { API_URL } from "../../../constants";
import * as httpNetworking from "../../../httpNetworking";

const URL = API_URL + "/user/app/";
const VERSION = 0;

export async function downloadApp(
    pluginName: "mysql" | "postgresql" | "mongodb",
    os: "linux" | "mac" | "windows" | "linux-arm",
    core: string
): Promise<void> {
    window.open(`${URL}download?pluginName=${pluginName}&os=${os}&core=${core}&api-version=${VERSION}`);
}

export async function downloadLegalLicense(appId: string, core: string): Promise<void> {
    window.open(`${URL}${appId}/legal-license?core=${core}`, "_parent");
    return;
}

export async function downloadAppLicenseKey(appId: string, licenseKeyId: string): Promise<void> {
    window.open(`${URL}${appId}/license?licenseKeyId=${licenseKeyId}`, "_parent");
}
