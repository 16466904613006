import { PaidFeaturesModalSteps } from "../../components/modals/paid-features-modal/EnablePaidFeaturesModal";
import { getAnalytics } from "../../utils";

type ServiceType = "self_hosted" | "managed";

const enablePaidFeaturesAnalytics = {
    //when users clicks on enable paid feature button on dashboard.
    enablePaidFeaturesClick: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature",
                {
                    type: "button_click",
                    button_text: "Enable Paid Features"
                },

                "v5"
            )
        );
    },
    //when users selects between self host or managed services..
    enablePaidFeaturesSelectedType: (serviceType: ServiceType) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature_selected_service",
                {
                    type: "button_click",
                    serviceType
                },
                "v5"
            )
        );
    },
    //when users closed enable paid feature modal
    enablePaidFeaturesModalClose: (
        step: PaidFeaturesModalSteps | "create-an-app-first" | "success-self-hosted" | "success-managed"
    ) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature_modal_close",
                {
                    type: "button_click",
                    step
                },
                "v5"
            )
        );
    },
    //when users selected paid features for self hosted service license keys.
    enablePaidFeaturesSelecteFeatureClick: (serviceType: ServiceType, feature: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature_selected_feature",
                {
                    type: "button_click",
                    serviceType,
                    feature
                },
                "v5"
            )
        );
    },
    //when users clicks on send license to key to email
    enablePaidFeaturesSendLicenseKeyToEmail: (features: string[]) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature_send_email",
                {
                    type: "button_click",
                    features
                },
                "v5"
            )
        );
    },
    //when users clicks on save to generate license key for saas.
    enablePaidFeaturesGenerateLicenseKeyForSaas: (features: string[], selectedApp: string) => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_feature_generate_license_key",
                {
                    type: "button_click",
                    selectedApp,
                    features
                },
                "v5"
            )
        );
    },
    enablePaidFeaturesCreateAppBeforeGeneratingLicenseKey: () => {
        getAnalytics().then((stAnalytics: any) =>
            stAnalytics.sendEvent(
                "button_saasdashboard_enable_paid_create_app_first_dialog",
                {
                    type: "button_click"
                },
                "v5"
            )
        );
    }
};

export default enablePaidFeaturesAnalytics;
