import * as React from "react";
import "./../../style/tech-stack-element.css"
import "./../../style/common.module.css"
type Props = {
    id: string,
    displayName: string,
    onClick: (id: string) => () => void,
    selected: boolean,
    shouldBlur: boolean,
    supported: Boolean
};

type State = {

};

export class TechStackItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render () {
        const techStackInLowerCase = this.props.id.toLowerCase()
        return (

            <div
                style={{
                    opacity: this.props.shouldBlur ? "40%" : "100%",
                }}
                onClick={this.props.onClick(this.props.id)}
                id="tech-stack-image-main">
                <div
                    className={`tech-stack-item-root-image-main ${this.props.selected ? "tech-stack-item-image-selected" : ""}`}>
                        <img
                            src="/docs/static/assets/techstackselected.png"
                            className={`tech-stack-root-selected-icon ${this.props.selected ? 'tech-stack-root-selected-icon-selected': null}`}/>
                        <div
                            className="tech-stack-item-image-container-new">
                                <img
                                    className="tech-stack-item-image"
                                    src={`/docs/static/assets/techstack${techStackInLowerCase}.png`}/>
                        </div>
                </div>
                <div
                    className={`tech-stack-item-root-text-main`}>
                        <h2 className={`text-stack-item-heading ${this.props.selected ? "tech-stack-text-selected" : ""}`}>{this.props.displayName} </h2>
                </div>
                <div className={`tech-stack-item-root-supported ${this.props.supported ? "tech-stack-props-supported" : ""}`}>
                    {this.props.supported ? <p className={`tech-stack-item-root-para-supported ${this.props.selected ? 'font-weight-medium': null}`}>Supported</p> : null}
                </div>
            </div>
        )
    }
}

