import { API_URL } from "../constants";
import * as httpNetworking from "../httpNetworking";

const URL = API_URL + "/website/form/prospect";
const VERSION = 1;

export default async function sendProspectToSalesEmail(data: Record<string, unknown>): Promise<void> {
    const options: httpNetworking.POSTRequestConfig = {
        timeout: 50000
    };

    await httpNetworking.simplePOSTRequest(URL, data, options, VERSION);
}
