import React from "react";
import "../../style/components/dropdown/styles.css";

type Props<ItemValue extends string | number> = {
    defaultText: string;
    items: {
        value: ItemValue;
        text: string;
    }[];
    onChange: (value: ItemValue) => void;
    className?: string;
    additionalClassNames?: string[],
    id?: string;
    name?: string;
    defaultValue?: ItemValue;
    selectedValue?: ItemValue;
    disabled?: boolean;
}

export const Dropdown = <ItemValue extends string | number,>(props: Props<ItemValue>) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange(event.target.value as ItemValue);
    }

    let additionalClassNames: string[] = []

    if (props.additionalClassNames !== undefined) {
        additionalClassNames = props.additionalClassNames!;
    }

    let className: string[] = [
        props.className || "dropdown",
        ...additionalClassNames,
    ];

    return (
        <select 
            disabled={props.disabled}
            className={className.join(" ")} 
            id={props.id}
            name={props.name}
            onChange={handleChange}
            value={props.selectedValue}
            defaultValue={props.selectedValue !== undefined ? undefined : props.defaultValue ?? "default"}>
                <option disabled value="default" style={{
                    display: "none",
                }}>{props.defaultText}</option>
                {
                    props.items.map((item) => {
                        return (
                            <option
                                key={item.value}
                                value={item.value}>
                                {item.text}
                            </option>
                        );
                    })
                }
        </select>
    );
}