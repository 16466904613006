import * as React from "react";
import Header from "../darkhome/Header";
import Footer from "../footer";

import "../style/notfound.module";
import { sendButtonAnalytics } from "../utils";

const NotFound = () => {
    const handleCtaClick = () => {
        sendButtonAnalytics("button_404page_cta", "v5", {
            option_clicked: "Go to docs"
        });
    };

    return (
        <React.Fragment>
            <div className="notfound-container">
                <Header />
                <div className="notfound-main">
                    <div className="naut-cookie">
                        <div className="cookie-404">
                            <img className="naut" src="/static/assets/notfound/astro.png" />
                            <h1 className="notfound-h1">
                                404
                            </h1>
                        </div>
                    </div>
                    <h2 className="notfound-h2">Page not found</h2>
                    <p className="notfound-p">This is not the webpage you were looking for</p>
                    <a href="/docs/guides" onClick={handleCtaClick} className="notfound-cta">
                        GO TO DOCS
                    </a>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default NotFound;
