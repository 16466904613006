// Keep these consistent with nextjs repo
export const UTM_SOURCE_STORAGE_KEY = "supertokens_utm_source";
export const UTM_SOURCE_PARAM_KEY = "utm_source";

export function saveUTMSource() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get(UTM_SOURCE_PARAM_KEY);
    if (utmSource) {
        localStorage.setItem(UTM_SOURCE_STORAGE_KEY, utmSource);
    }
}
