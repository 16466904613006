import * as React from 'react'
import "./../../style/select.module.css"


type Props = {
    onSelected: (id:any, updateValueFor?: string) => void, 
    currentValue: string, 
    options: { id: string; displayName: string; }[],
    updateValueFor?: string, 
    styleClass?: string
}


export const Select = (props: Props) => {

    const upsideEmitValue = (value: React.FormEvent<HTMLSelectElement>)  => {
        props.onSelected(value, props.updateValueFor)
    }

    return (
        <div className="select-element-main">
            <select className={`${props.styleClass}`}
            
            onChange={upsideEmitValue}
                value={props.currentValue}>
                <option key="placeholder" value="" disabled hidden>Please select</option>
                {
                    props.options.map(options => 
                    <option key={options.id} value={options.id}>{options.displayName}</option>
                )}
            </select>
        </div>
    )
}



