import * as React from 'react';
import { IMAGE_PATH_PREFIX } from "../constants";
import { sendButtonAnalytics } from '../utils';

export function UseDesktopOnMobilePrompt(): JSX.Element {
    const handleDesktopPromptCta = (e: any) => {
        sendButtonAnalytics("button_signup_nondesktop_gotodocs", "v5")
    }

    return (
        <div
            className="border-box"
            id="signup-mobile-disclaimer">
            <img
                src={IMAGE_PATH_PREFIX + "/static/assets/desktopIcon.png"}
                id="signup-mobile-disclaimer-icon" />

            <div
                id="signup-mobile-disclaimer-text"
            >
                Thank you for your interest.<br />Please use a desktop computer to sign up and get started.
            </div>
            <a href="/docs/guides" id="signup-mobile-docs-cta" onClick={handleDesktopPromptCta}>Go to Docs</a>
        </div>
    )
}