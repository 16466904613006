import React from "react";

export default function AttackProtectionSuiteIcon({ theme }: { theme?: "light" | "dark" }) {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.68269 0.0714289C3.66755 0.110692 3.63616 0.221258 3.61286 0.317119C3.56478 0.515212 3.29416 1.02786 3.02752 1.42604C2.34823 2.44033 1.42544 3.11418 0.294237 3.42189C0.143181 3.46299 0.0117397 3.51633 0.00213002 3.54041C-0.00744673 3.56453 0.0161825 3.8652 0.0546869 4.20856C0.145189 5.01557 0.309047 6.56447 0.355416 7.05119C0.541686 9.00667 1.26761 11.0487 2.39483 12.788C3.64699 14.7202 5.36524 16.3209 7.38119 17.4333C7.95306 17.7488 8.4627 18 8.53112 18C8.60122 18 9.38898 17.6153 9.78953 17.3854C10.827 16.7899 11.6876 16.1478 12.5332 15.3381C14.6652 13.2966 16.0542 10.7427 16.575 7.90681C16.6294 7.61052 16.6826 7.29679 16.6931 7.20964C16.7291 6.9128 16.841 5.59574 16.9073 4.6903C16.9437 4.19357 16.982 3.72652 16.9924 3.65243C17.0144 3.49585 17.0183 3.49823 16.5703 3.38979C15.9801 3.24687 15.1748 2.77082 14.619 2.23625C13.9816 1.62331 13.7048 1.14127 13.3892 0.0952912L13.3653 0.0160668L8.53774 0.00804937L3.71021 0L3.68269 0.0714289ZM12.2651 1.79824C12.4726 2.20954 12.9516 2.8144 13.4523 3.29729C13.8583 3.68894 14.2312 3.97336 14.7633 4.2971C14.9748 4.42579 15.1634 4.54336 15.1823 4.55835C15.2283 4.59482 15.1066 6.1564 14.9806 7.14626C14.7704 8.79692 14.1042 10.5736 13.1445 12.0423C12.0524 13.7139 10.4549 15.1902 8.74487 16.1082L8.52889 16.2242L8.12337 15.9874C5.9795 14.7356 4.19395 12.8032 3.13738 10.5912C2.71969 9.71677 2.4159 8.82807 2.23052 7.9385C2.11221 7.37085 2.07778 7.13853 2.01673 6.49662C1.94433 5.73515 1.87364 4.94858 1.86328 4.78972L1.85429 4.65142L2.15446 4.48518C2.80555 4.12452 3.51607 3.5387 4.0003 2.96321C4.23607 2.68304 4.64938 2.09181 4.7646 1.86992C4.80534 1.79148 4.85349 1.7095 4.87159 1.6877C4.89762 1.65645 5.67255 1.64809 8.54695 1.64809H12.1893L12.2651 1.79824Z"
                fill={theme === "light" ? "#6E6A65" : "white"}
                fillOpacity={theme === "light" ? "1" : "0.6"}
            />
        </svg>
    );
}
