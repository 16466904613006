import { API_URL } from "../../constants";
import * as httpNetworking from "../../httpNetworking";

const URL = API_URL + "/user/info";
const VERSION = 2;

export type UserInfoResponse = {
    userId: string;
    joinedDate: number;
    email: string;
    name: string;
    country: string;
    company: string;
    paymentInfoShared: boolean;
    loginMethod:
        | {
              type: "emailpassword";
          }
        | {
              type: "thirdparty";
              provider: "google" | "github";
          };
};

export async function getUserInformation(): Promise<UserInfoResponse> {
    let options: httpNetworking.GETRequestConfig = {
        timeout: 50000
    };

    let result = (await httpNetworking.simpleGETRequest(URL, options, VERSION)).data;
    return result;
}
