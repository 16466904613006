import React from "react";

export default function getIcon(isOpen: boolean, theme?: "dark" | "light") {
    if (isOpen) {
        return (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.5 -3.93402e-07L0.602886 5.25L8.39711 5.25L4.5 -3.93402e-07Z"
                    fill={`${theme === "light" ? "#ff9933" : "white"}`}
                />
            </svg>
        );
    }
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L7.4641 0.75L0.535898 0.75L4 6Z" fill={`${theme === "light" ? "#222222" : "#A1A1A1"}`} />
        </svg>
    );
}
