import createAppOrEnvAnalytics from "../../analytics/saas/createAppOrEnv";
import enablePaidFeaturesAnalytics from "../../analytics/saas/paidFeatures";
import { getAnalytics } from "../../utils";
import { AuthRecipes, CoreTypes, CreateAppData, SelfHostedOptions, SetupDetailsSteps, UITypes } from "./createApp";

// Types for parameters
type Instance = "dev" | "prod"
type CredentialCopied = "connectionURI" | "API key"
type EditConfigAction = "Save" | "Go back"
type PostgreSQLCopyOptions = "db host" | "db name" | "db user" | "db password"

// Helpers
const getInstanceName = (instance: Instance) => instance === "dev" ? "Development" : "Production"

let saasAnalytics = {
  //when user deploys supertokens core public dev instance from dashboard
  createPublicDevEnv: (data: CreateAppData) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent('button_saasdashboard_create_public_dev_environment', {
        type: 'button_click',
        button_text: "Deploy Supertokens Core",
        data
      }, "v5")
    )
  },
  //when user selects a auth method from dropdown while creating a public app.
  selectedRecipe: (option: AuthRecipes) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_selected_auth_method", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user selects any option between custom/prebuilt UI options.
  selectedUIType: (option: UITypes) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_selected_ui_type", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user selects any option between managed/self-hosted service.
  selectedCoreServiceType: (option: CoreTypes) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_selected_core_service_type", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user selects any option among cloud instance deployment regions for managed service.
  selectedRegionType: (option: string) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_selected_managed_service_region", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user selects between the with docker or without option while creating a public app.
  selectedSelfHostedType: (option: SelfHostedOptions) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_selected_self_hosted_type", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user selects a step while setting up the instance to deploy on saas dashboard.
  checkedSetupSteps: (option: SetupDetailsSteps) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_checked_setup_step", {
        type: "button_click",
        "option_clicked": option
      }, "v5")
    )
  },
  //when user clicks on `click here` link text, which redirects to relevant docs page.
  docsRedirects: (link: string) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_docs_redirects_from_get_started_setup", {
        type: "button_click",
        "redirected_location": link
      }, "v5")
    )
  },
  // when the user visits saas dashboard
  visitSaasDashboardAction: () => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent('page_saasdashboard', {
        type: 'page_view',
        referrer: document.referrer,
      }, "v5")
    )
  },
  // When the user clicks on any one of the cta options in the feature banner
  clickFeatureBannerCta: (buttonText: "I'm Interested in Buying" | "No, thanks") => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_featurebanner_cta", {
        type: "button_click",
        button_text: buttonText
      }, "v5")
    )
  },
  // when user clicks on the create production environment button
  createProdEnvAction: () => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_create_prodenvironment", {
        type: "button_click",
        instancetype: "prod",
      }, "v5")
    )
  },
  // when user clicks on visit the docs button
  visitDocsAction: () => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_main_cta", {
        type: "button_click",
        instancetype: "dev",
      }, "v5")
    )
  },
  // analytics for when user clicks on access postgresql button
  accessPostgreSQLAction: () => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_databaseaccess", {
        type: "button_click",
        instancetype: "dev",
      }, "v5")
    )
  },
  // when user clicks on copy button in any field
  accessPostgreSQLCopyAction: (option: PostgreSQLCopyOptions) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_databaseaccess_copy", {
        type: "button_click",
        instancetype: "dev",
        "option_clicked": option
      }, "v5")
    )
  },
  // when user clicks on export as csv in users tab
  exportUsersAsCSVAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_exportuserscsv", {
        type: "button_click",
        instancetype: instance
      }, "v5")
    )
  },
  // When user gets error while exporting csv
  exportUsersCSVErrorOnExportAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("saasdashboard_exportuserscsv_errrortriggered", {
        type: "button_click",
        instancetype: instance
      }, "v5")
    )
  },
  // When user clicks on contact us link in export csv error box
  exportUsersCSVErrorContactAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_csverror_contactus", {
        type: "button_click",
        instancetype: instance
      }, "v5")
    )
  },
  // when user clicks on edit config button
  editConfigAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_environment_editconfig", {
        type: "button_click",
        instancetype: instance,
        "instance_type": getInstanceName(instance)
      }, "v5")
    )
  },
  // when user clicks on different tabs related to an environment
  changeTabAction: (instance: Instance, tabValue: string) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_environment_tabs", {
        type: "button_click",
        "instance_type": getInstanceName(instance),
        "tab_clicked": tabValue
      }, "v5")
    )
  },
  // when user copies a credential from setup tab
  copyCredentialAction: (instance: Instance, credentialCopied: CredentialCopied) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent(`button_saasdashboard_connect_${instance}instance`, {
        type: "button_click",
        instancetype: instance,
        "credential_copied": credentialCopied
      }, "v5")
    )
  },
  // when user hovers over the ? icon in setup tab
  connectMoreInfoAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_connect_moreinfo", {
        type: "button_click",
        instancetype: instance,
        "instance_type": getInstanceName(instance)
      }, "v5")
    )
  },
  // when user toggles number of days for finding active no of users
  usersTabDaysCountChangeAction: (instance: Instance, days: Number) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_userstab_daystoggle", {
        type: "button_click",
        instancetype: instance,
        "option_clicked": days
      }, "v5")
    )
  },
  // when user clicks on pagination feature link in users tab
  usersTabPaginationLinkAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_userstab_noteCTA", {
        type: "button_click",
        instancetype: instance,
        text: "pagination_feature"
      }, "v5")
    )
  },
  // when user clicks save / go back in edit config
  editConfigSaveGoBackAction: (instance: Instance, action: EditConfigAction) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_editconfig_action", {
        type: "button_click",
        instancetype: instance,
        "option_clicked": action
      }, "v5")
    )
  },
  // when the user hover over the "?" on no of active sessions explanation
  activeSessionsInfoModalViewAction: (instance: Instance) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_userstab_activesessionsinfo", {
        type: "button_click",
        instancetype: instance
      }, "v5")
    )
  },
  // when the user hovers over the "i" icon in the billing tab
  prodBillingTabInfoIconHoverAction: (keyName: "MAUs" | "SMS") => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent("button_saasdashboard_billing_infotips", {
        type: "button_click",
        "info_tip": keyName
      }, "v5")
    )
  },
  // when the user visits get started section.
  visitSaasDashboardGetStartedPage: () => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent('page_get_started', {
        type: 'page_view',
        referrer: document.referrer,
      }, "v5")
    )
  },
  paymentDetailsPopup: (kind: string) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent(
        "saasdashboard_payment_details_modal_open",
        {
          type: "button_click",
          kind,
        },
        "v5"
      )
    );
  },
  addPaymentsDetailsClick: (kind: string) => {
    getAnalytics().then((stAnalytics: any) =>
      stAnalytics.sendEvent(
        "saasdashboard_payment_details_modal_open",
        {
          type: "button_click",
          kind,
        },
        "v5"
      )
    );
  },
  //Collection of Create new app or env related analytical events.
  ...createAppOrEnvAnalytics,
  ...enablePaidFeaturesAnalytics
}

export default saasAnalytics