import React, {useState} from "react";
import { sendButtonAnalytics } from "../utils";
import { isLoginSupported } from "./useOssUtils";
import { GetDockerCommandProps } from "./useOssTypes";
import UseOssCopyBox from "./UseOssCopyBox";
import DockerComposeFile from "./DockerComposeFile";
import getSupportedCores from "../api/plugin/dependency/cores";

const clickEventAlreadySent: any = {};
const highlightEventAlreadySent: any = {};

export function getDockerCommand({ idPrefix, compatibilityData, sdk, db, selectedDbDockerVersion }: GetDockerCommandProps) {
    const copyTextId = `${idPrefix}-copycommand-${sdk}-${db}-1`;
    const eventName = "button_selfhosted_docker_copycommand";
    const loginSupported = isLoginSupported(compatibilityData, db);

    let coreInfo = "";
    if (!loginSupported) {
        // greatest core less than v3
        if (sdk === "node" && db === "mongodb") {
            coreInfo = "";
        } else {
            let gtCorelt3 = "0";
            for (const key of Object.keys(compatibilityData.coreToDriver)) {
                if (+key > +gtCorelt3 && +key < 3) {
                    gtCorelt3 = key;
                }
            }
            coreInfo = ":" + gtCorelt3;
        }
    }

    const textToCopy = `docker run -p 3567:3567 -d registry.supertokens.io/supertokens/supertokens-${db}${selectedDbDockerVersion}`;
    const textLength = textToCopy.length;

    const dockerCommandCopyCalback = () => {
        if (!clickEventAlreadySent[copyTextId]) {
            sendButtonAnalytics(eventName, "v5", {
                option_clicked: sdk + "-" + db,
                login_supported: loginSupported,
                command_copied_detected: "copy_button"
            });
            clickEventAlreadySent[copyTextId] = true;
        }
    };

    const dockerCommandHighlightCallback = () => {
        const selection = window.getSelection() as Selection;
        if (selection !== null && selection.toString().trim().length === textLength) {
            if (!highlightEventAlreadySent[copyTextId]) {
                sendButtonAnalytics(eventName, "v5", {
                    option_clicked: sdk + "-" + db,
                    login_supported: loginSupported,
                    command_copied_detected: "command-highlighted"
                });
                highlightEventAlreadySent[copyTextId] = true;
            }
        }
        return;
    };

    return (
        <div className="docker-command-container">
            <UseOssCopyBox
                label="Run the Docker command below to start a container running SuperTokens"
                textToCopy={textToCopy}
                callbackAfterCopy={dockerCommandCopyCalback}
                callbackAfterHighlight={dockerCommandHighlightCallback}
                className="docker"
            />

            <DockerComposeFile db={db} dockerVersion={selectedDbDockerVersion} />
        </div>
    );
}
