import React, { useEffect } from "react"

import { useOssDiscordButtonAnalytics } from './useOssAnalytics'

import "../style/useOss-contact.module.css"

const ContactSection = ({ visible }: { visible: boolean }) => {

  // start checking if the Calendly object is available in the window once component mounts
  useEffect(() => {
    // we check for the calendly script status max 40 times
    // i.e. 20 seconds
    checkCalendlyStatusAndInitOrWait(40)
  }, [])

  // checks if the Calendly object is available on window object
  // and calls Calendly object's initInlineWidget method to initialize Calendly embed
  // the Calendly script is loaded in the website/index.html file
  const checkCalendlyStatusAndInitOrWait = (triesLeft: number) => {
    setTimeout(() => {
      if (Object.keys(window).includes("Calendly") && (window as any).Calendly !== undefined) {
        (window as any).Calendly.initInlineWidget({
          url: "https://calendly.com/d/2fv-zb9-6jk/30-mins-user-call",
          parentElement: document.getElementById("calendly-widget")
        })
      } else if (triesLeft > 0) {
        checkCalendlyStatusAndInitOrWait(triesLeft - 1)
      }
    }, 500)
  }

  useEffect(() => {
    if (visible) {
      const el = document.getElementById("use-oss-contact-section")
      if (el) {
        el.scrollIntoView()
      }
    }
  }, [visible])

  return (
    <div id="use-oss-contact-section" className={`use-oss-contact-section ${visible ? "visible" : ""}`}>
      <div className="use-oss-contact-section__title-container">
        <h1 className="use-oss-contact-section__title-container__title">
          Your download will begin shortly!
        </h1>
        <p className="use-oss-contact-section__title-container__subtitle">
          For any questions, get in touch with the founders:
        </p>

        <div className="use-oss-contact-section__title-container__buttons">
          <p className="use-oss-contact-section__title-container__buttons__button active">
            Book a demo
          </p>
          <a
            className="use-oss-contact-section__title-container__buttons__button"
            href="/discord"
            onClick={() => useOssDiscordButtonAnalytics()}
          >
            <img
              src="/static/assets/use-oss/discord-white.png"
              alt="Discord"
            />
            <span>Join our Discord</span>
          </a>
        </div>
      </div>

      <div id="calendly-widget"></div>
    </div>
  )
}

export default ContactSection
