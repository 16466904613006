import React from "react";
import { sendAsyncButtonAnalytics } from "../../utils";

import AccountLinkingIcon from "./assets/product-header/account-linking";
import AttackProtectionSuiteIcon from "./assets/product-header/attack-protection-suite";
import EmailPasswordIcon from "./assets/product-header/email-password";
import MagicLinkIcon from "./assets/product-header/magic-link";
import MultiTenancyIcon from "./assets/product-header/multi-tenancy";
import SocialLoginIcon from "./assets/product-header/social-login";
import SingleSignOnIcon from "./assets/product-header/sso";
import PasswordlessLoginIcon from "./assets/product-header/passwordless-login";
import StarIcon from "./assets/product-header/star";

import "../../style/product-header-dropdown-content.module.css";

const ArrowRightIcon = () => {
    return (
        <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4L0.25 7.4641L0.25 0.535898L4 4Z" fill="#FF9933" />
        </svg>
    );
};

const getBadge = (isNew: boolean) => {
    return isNew ? (
        <div className={"product-badge"}>
            {StarIcon}
            <span>New</span>
        </div>
    ) : null;
};

type TIcon = React.ComponentType<{ theme?: "light" | "dark" }>;

const data: {
    left: {
        title: string;
        icon: TIcon;
        link: string;
        isNew: boolean;
        eventName: string;
    }[];
    right: {
        title: string;
        icon: TIcon;
        link: string;
        isNew: boolean;
        eventName: string;
    }[];
} = {
    left: [
        {
            title: "Magic-link",
            icon: MagicLinkIcon,
            link: "/features/email-magic-links",
            isNew: false,
            eventName: "button_header_product_magicLink"
        },
        {
            title: "Passwordless login",
            icon: PasswordlessLoginIcon,
            link: "/features/passwordless-login",
            isNew: false,
            eventName: "button_header_product_passwordless"
        },
        {
            title: "Email Password login",
            icon: EmailPasswordIcon,
            link: "/features/email-password-authentication",
            isNew: false,
            eventName: "button_header_product_emailPassword"
        },
        {
            title: "Social login",
            icon: SocialLoginIcon,
            link: "/features/social-login",
            isNew: false,
            eventName: "button_header_product_social"
        }
    ],
    right: [
        {
            title: "Attack protection Suite",
            icon: AttackProtectionSuiteIcon,
            link: "/features/attack-protection-suite",
            isNew: true,
            eventName: "button_header_product_attackProtection"
        },
        {
            title: "Single Sign On (SSO)",
            icon: SingleSignOnIcon,
            link: "/features/single-sign-on",
            isNew: false,
            eventName: "button_header_product_sso"
        },
        {
            title: "Multi-Tenancy",
            icon: MultiTenancyIcon,
            link: "/features/multi-tenancy",
            isNew: false,
            eventName: "button_header_product_multitenancy"
        },
        {
            title: "Account linking",
            icon: AccountLinkingIcon,
            link: "/features/account-linking",
            isNew: false,
            eventName: "button_header_product_accountLinking"
        }
    ]
};

export default function ProductHeaderDropdownContent({
    type,
    closeDropdown,
    theme
}: {
    type?: "mobile" | "desktop";
    closeDropdown?: () => void;
    theme?: "light" | "dark";
}) {
    function handleItemClick(eventName: string, isNew = false) {
        sendAsyncButtonAnalytics(eventName, "v1");
        if (isNew) {
            sendAsyncButtonAnalytics("button_header_product_new", "v1", {
                event_name: eventName
            });
        }
        if (closeDropdown) {
            closeDropdown();
        }
    }
    return (
        <div
            className={`product-container ${type === "mobile" ? "product-mobile" : ""} ${
                theme === "light" ? "light-theme-product-container" : ""
            }`}
        >
            <section className="product-main">
                <h3>Features</h3>
                <div className="product-main-body">
                    <div className="product-left">
                        {data.left.map(item => (
                            <a
                                className="product-item"
                                key={item.title}
                                href={item.link}
                                onClick={() => handleItemClick(item.eventName, item.isNew)}
                            >
                                <div className="product-icon">{<item.icon theme={theme} />}</div>
                                <span className="product-link">{item.title}</span>
                                {getBadge(item.isNew)}
                            </a>
                        ))}
                    </div>
                    <div className="product-right">
                        {data.right.map(item => (
                            <a
                                href={item.link}
                                onClick={() => handleItemClick(item.eventName, item.isNew)}
                                className="product-item"
                                key={item.title}
                            >
                                <div className="product-icon">{<item.icon theme={theme} />}</div>
                                <span className="product-link">{item.title}</span>
                                {getBadge(item.isNew)}
                            </a>
                        ))}
                    </div>
                </div>
            </section>
            <div className="product-footer">
                <a
                    href="/product"
                    className={"product-footerLink"}
                    onClick={() => handleItemClick("button_header_product_all")}
                >
                    <span>View all Product and Feature offerings</span>
                    <ArrowRightIcon />
                </a>
            </div>
        </div>
    );
}
