// --- Important Note ---
// This file is shared in the backend as well. Do not import any node modules or files here

// --- Important Note ---
// Do not change this file from backend repository

let API_URL: string;
let API_DOMAIN: string;
let API_BASE_PATH: string;
let WEBSITE_DOMAIN: string;

if (window.location.hostname === "supertokens.com" || window.location.hostname === "www.supertokens.com") {
    API_URL = "https://api.supertokens.com/0";
    API_DOMAIN = "https://api.supertokens.com";
    API_BASE_PATH = "/0/auth";
    WEBSITE_DOMAIN = "https://supertokens.com";
} else if (window.location.hostname === "test.supertokens.com") {
    API_URL = "https://dev.api.supertokens.com/0";
    API_DOMAIN = "https://dev.api.supertokens.com";
    API_BASE_PATH = "/0/auth";
    WEBSITE_DOMAIN = "https://test.supertokens.com";
} else {
    API_URL = "https://dev.api.supertokens.com/0";
    API_DOMAIN = "https://dev.api.supertokens.com";
    API_BASE_PATH = "/0/auth";
    WEBSITE_DOMAIN = "http://localhost:9001";
}

export { API_URL, API_DOMAIN, API_BASE_PATH, WEBSITE_DOMAIN };
export const backend_url = "/update-spreedsheet";
export const MOCK_ENABLED = window.location.hostname === "localhost";
export function mockDelay(timeout = 2000) {
    return new Promise(resolve => setTimeout(resolve, timeout));
}

declare global {
    interface Window {
        redirectPaths: any;
    }
}

export const IMAGE_PATH_PREFIX = "";
export let redirectPaths = {};
// Routes for the website with alt versions that have .html. When using these in the index file to decide what the user should see
// check both the actual path and the alt path. WHen using this to open pages do not use the alt version (it wont cause errors but the url will be ugly)
export const PAGE_PATHS = {
    home: "/",

    useOSS: "/use-oss",
    useOSSAlt: "/use-oss.html",

    dashboardSaas: "/dashboard-saas",
    dashboardSaasAlt: "/dashboard-saas.html",

    pricing: "/pricing",
    pricingAlt: "/pricing.html",

    product: "/product",
    productAlt: "/product.html",

    guides: "/docs/guides",
    guidesAlt: "/docs/guides.html",

    references: "/docs/references",
    referencesAlt: "/docs/references.html",

    consultancy: "/consultancy",
    consultancyAlt: "/consultancy.html",

    eventScheduled: "/consultancy/event-scheduled",
    eventScheduledAlt: "/consultancy/event-scheduled.html",

    intro: "/intro",
    introAlt: "/intro.html",

    checkout: "/checkout",
    checkoutAlt: "/checkout.html",

    communityDocsLanding: "/docs/guides",
    communityDocsLandingAlt: "/docs/guides.html",

    proDocsLanding: "/docs/pro/getting-started/installation",
    proDocsLandingAlt: "/docs/pro/getting-started/installation.html",

    spreadsheet: "/update-seo-content",
    spreadsheetAlt: "/update-seo-content.html",

    productRoadmap: "/product-roadmap",
    productRoadmapAlt: "/product-roadmap.html",

    customers: "/customers",
    customersAlt: "/customers.html",

    customersBuiltIntelligence: "/customers/builtintelligence",
    customersBuiltIntelligenceAlt: "/customers/builtintelligence.html",

    customersPoppy: "/customers/poppy",
    customersPoppyAlt: "/customers/poppy.html",

    customersGoPronto: "/customers/gopronto",
    customersGoProntoAlt: "/customers/gopronto.html",

    customersFoodMarketHub: "/customers/foodmarkethub",
    customersFoodMarketHubAlt: "/customers/foodmarkethub.html",

    recipeRedirect: "/recipe-redirect",
    recipeRedirectAlt: "/recipe-redirect.html",

    notFound: "/404",
    notFoundAlt: "/404.html",

    customerPages: {
        curology: {
            main: "/customers/curology",
            alt: "/customers/curology.html"
        },
        qloo: {
            main: "/customers/qloo",
            alt: "/customers/qloo.html"
        },
        stacker: {
            main: "/customers/stacker-hq",
            alt: "/customers/stacker-hq.html"
        },
        arena: {
            main: "/customers/arena-club",
            alt: "/customers/arena-club.html"
        },
        cocoon: {
            main: "/customers/cocoon",
            alt: "/customers/cocoon.html"
        }
    },
    featurePages: {
        magicLinks: {
            main: "/features/email-magic-links",
            alt: "/features/email-magic-links.html"
        },
        sso: {
            main: "/features/single-sign-on",
            alt: "/features/single-sign-on.html"
        },
        multitenancy: {
            main: "/features/multi-tenancy",
            alt: "/features/multi-tenancy"
        },
        emailPasswordAuthentication: {
            main: "/features/email-password-authentication",
            alt: "/features/email-password-authentication.html"
        },
        passwordlessLogin: {
            main: "/features/passwordless-login",
            alt: "/features/passwordless-login.html"
        },
        socialLogin: {
            main: "/features/social-login",
            alt: "/features/social-login.html"
        },
        accountLinking: {
            main: "/features/account-linking",
            alt: "/features/account-linking.html"
        },
        attackProtection: {
            main: "/features/attack-protection-suite",
            alt: "/features/attack-protection-suite.html"
        }
    }
};

export const saasDashboardPagesPaths = [
    "/dashboard-saas",
    "/dashboard-saas/billing",
    "/dashboard-saas/settings",
    "/dashboard-saas/user-tenant-management"
];

const licenseBaseUrl = "/legal/";

export const LICENSE_PATH = {
    community: licenseBaseUrl + "community-license",
    pro: licenseBaseUrl + "pro-license",
    openSourceLicense: licenseBaseUrl + "open-source-licenses",
    terms: licenseBaseUrl + "terms-and-conditions",
    privacy: licenseBaseUrl + "privacy-policy"
};

// Using underscore for naming because in footer when we are to create a text for the link, we are going to split so Session_Management_Primer becomes Session Management Primer with it's href tag being /blog/all-you-need-to-know-about-user-session-security
export const BLOGS_PATH: { [key: string]: any } = {
    Session_Management_Primer: "/blog/all-you-need-to-know-about-user-session-security",
    SuperTokens_Conceptual_Flow: "/blog/the-best-way-to-securely-manage-user-sessions",
    Using_JWT_for_User_Sessions: "/blog/are-you-using-jwts-for-user-sessions-in-the-correct-way",
    "Cookies vs localstorage for sessions": "/blog/cookies-vs-localstorage-for-sessions-everything-you-need-to-know"
};

if (typeof window !== "undefined") {
    window.redirectPaths = {
        "/facebook": "https://www.facebook.com/SuperTokenss/",
        "/github": "https://github.com/supertokens/supertokens-core",
        "/linkedin": "https://www.linkedin.com/company/supertokens/",
        "/slack":
            "https://join.slack.com/t/webmobilesecurity/shared_invite/enQtODM4MDM2MTQ1MDYyLTFiNmNhYzRlNGNjODhkNjc5MDRlYTBmZTBiNjFhOTFhYjI1MTc3ZWI2ZjY3Y2M3ZjY1MGJhZmRiNDFjNDNjOTM",
        "/twitter": "https://twitter.com/supertokensio",
        "/discord": "https://discord.gg/ywaJefBEaE",
        "/call-user": "https://calendly.com/d/2fv-zb9-6jk/30-mins-user-call"
    };
} else {
    redirectPaths = {
        "/facebook": "https://www.facebook.com/SuperTokenss/",
        "/github": "https://github.com/supertokens/supertokens-core",
        "/linkedin": "https://www.linkedin.com/company/supertokens/",
        "/slack":
            "https://join.slack.com/t/webmobilesecurity/shared_invite/enQtODM4MDM2MTQ1MDYyLTFiNmNhYzRlNGNjODhkNjc5MDRlYTBmZTBiNjFhOTFhYjI1MTc3ZWI2ZjY3Y2M3ZjY1MGJhZmRiNDFjNDNjOTM",
        "/twitter": "https://twitter.com/supertokensio",
        "/discord": "https://discord.gg/ywaJefBEaE"
    };
}

export const analyticsBase = "supertokensAy-";
export const antcsCookieName = "st_antcs"; // analytics cookie name
export const VALID_OTP_REGEX = /^[a-km-z0-9]{4}$/;
// export const V3_DRIVERS = ["node"];
// export const V3_FRONTENDS = ["auth-react", "website"];
// export const V3_DATABASES = ["mysql", "postgresql"];
// export const V2_LATEST = "2.5";

export const javascriptSDKs = ["auth-react", "nodejs", "website", "react-native", "web-js"];

export const pythonSDKs = ["python"];

export const javaSDKs = ["android"];

export class CustomEventNames {
    static dashboard = {
        refetchActiveUsersProd: "refetch-active-users-prod",
        refetchActiveUsersDev: "refetch-active-users-dev",
        onAppDataFetched: "on-app-data-fetched"
    };
}
export const heardFromOptions = [
    { text: "Social Media", value: "social_media" },
    { text: "Email Campaign or newsletter", value: "email_or_newsletter" },
    { text: "Online Ad", value: "online_ad" },
    { text: "Search engine", value: "search_engine" },
    { text: "Referral/Recommendation", value: "referral_recommendation" },
    { text: "Event (Conference, Webinar, Meetup)", value: "event" },
    { text: "Other", value: "other" }
];

export const companySizeOptions = [
    { text: "Just me", value: "1" },
    { text: "2-10 Employees", value: "2-10" },
    { text: "11-50 Employees", value: "11-50" },
    { text: "51-500 Employees", value: "51-500" },
    { text: "501+ Employees", value: "501+" }
];

export type HeardFromMoreDetails =
    | { type: "dropdown"; description: string; options: { text: string; value: string }[] }
    | { type: "textInput"; description: string }
    | null;

export const getHeardFromMoreDetails = (heardFrom: typeof heardFromOptions[number]["value"]): HeardFromMoreDetails => {
    switch (heardFrom) {
        case "social_media": {
            return {
                type: "dropdown",
                description: "Let us know which platform",
                options: [
                    { text: "Twitter (X)", value: "twitter" },
                    { text: "LinkedIn", value: "linkedin" },
                    { text: "Reddit", value: "reddit" },
                    { text: "YouTube", value: "youtube" },
                    { text: "Discord", value: "discord" },
                    { text: "GitHub", value: "github" },
                    { text: "Hacker News", value: "hacker_news" },
                    { text: "Facebook", value: "facebook" },
                    { text: "Instagram", value: "instagram" },
                    { text: "TikTok", value: "tiktok" },
                    { text: "BlueSky", value: "bluesky" },
                    { text: "Threads", value: "threads" },
                    { text: "Other", value: "other" }
                ]
            };
        }

        case "email_or_newsletter": {
            return {
                type: "textInput",
                description: "Let us know which email campaign or newsletter"
            };
        }
        case "event": {
            return {
                type: "textInput",
                description: "Let us know which event"
            };
        }
        case "other": {
            return {
                type: "textInput",
                description: "Let us know where you found us"
            };
        }
        default: {
            return null;
        }
    }
};
