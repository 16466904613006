import React from "react";
import "../../style/components/radiogroup/styles.css";

type Props<ItemValue extends string> = {
    items: {
        value: ItemValue;
        label: string;
    }[];
    onChange: (value: ItemValue) => void;
    selectedValue?: ItemValue;
    className?: string;
    additionalClassNames?: string[],
    defaultSelectedValue?: ItemValue;
    layoutMode?: "VERTICAL" | "HORIZONTAL";
}

export const RadioGroup = <ItemValue extends string,>(props: Props<ItemValue>) => {
    const [selectedValue, setSelectedValue] = React.useState<ItemValue | undefined>(props.selectedValue || props.defaultSelectedValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value as ItemValue);
        props.onChange(event.target.value as ItemValue);
    }

    let additionalClassNames: string[] = []

    if (props.additionalClassNames !== undefined) {
        additionalClassNames = props.additionalClassNames!;
    }

    let layoutClassName = "vertical";

    if (props.layoutMode === "HORIZONTAL") {
        layoutClassName = "horizontal";
    }

    let className: string[] = [
        props.className || "radio-group",
        ...additionalClassNames,
        layoutClassName,
    ];

    return (
        <div className={className.join(" ")}>
            {
                props.items.map((item) => {
                    return (
                        <div className="radio-group-item" key={item.value}>
                            <input 
                                type="radio" 
                                id={item.value} 
                                value={item.value} 
                                onChange={handleChange}
                                checked={selectedValue === item.value}/>
                            <label htmlFor={item.value}>{item.label}</label>
                        </div>
                    );
                })
            }
        </div>
    );
}