/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FormFieldThemeProps } from "supertokens-auth-react/lib/build/recipe/emailpassword/types";

export type NewSignupHelperProps = {
    originalComponent: any;
    originalProps: any;
};

const SignUpHelper = ({ originalComponent: OriginalComponent, originalProps }: NewSignupHelperProps) => {
    const fields = originalProps.formFields as FormFieldThemeProps[];
    return (
        <div>
            <OriginalComponent
                {...originalProps}
                formFields={[
                    fields.find(({ id }) => id === "name"),
                    fields.find(({ id }) => id === "email"),
                    fields.find(({ id }) => id === "password")
                ]}
            />
        </div>
    );
};

export default SignUpHelper;
