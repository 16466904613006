import XRegExp from "xregexp";
import {
    SaasConnectionUrlDomain,
    SaasAppListItem,
    SaasAppDevDeployment,
    SaasAppProdDeployment,
    SaasConnectionUriDomainApp
} from "./types";

export function convertSaasAppDataToNewFormat(
    data: SaasConnectionUrlDomain,
    app?: SaasConnectionUriDomainApp
): SaasAppListItem {
    if (data.isTemporarilyRemoved === true) {
        return {
            usesMultiTenancy: true,
            isTemporarilyRemoved: true,
            isRecreating: data.isRecreating,
            appName: data.deploymentName,
            region: data.region,
            appId: data.deploymentId
        };
    }

    if (app === undefined) {
        throw new Error("Should never come here");
    }

    let devDeployment: SaasAppDevDeployment | undefined = undefined;
    let prodDeployment: SaasAppProdDeployment | undefined = undefined;

    for (let i = 0; i < app.deployments.length; i++) {
        let deployment = app.deployments[i];
        if (deployment.deploymentType === "development") {
            devDeployment = deployment;
        } else {
            prodDeployment = deployment;
        }
    }

    let appData: SaasAppListItem = {
        appId: data.deploymentId,
        appName: app.appId,
        isTemporarilyRemoved: false,
        region: data.region,
        usesMultiTenancy: true
    };

    if (devDeployment !== undefined) {
        appData.devDeployment = {
            config: devDeployment.config,
            connectionInfo: devDeployment.connectionInfo,
            supertokensVersion: devDeployment.coreVersion,
            displayCoreVersion: devDeployment.displayCoreVersion,
            supertokensInstance: [
                {
                    containerName: "",
                    status: devDeployment.status
                }
            ],
            id: app.appId
        };
    }

    if (prodDeployment !== undefined) {
        appData.prodDeployment = {
            userHadCreated: true,
            config: prodDeployment.config,
            connectionInfo: prodDeployment.connectionInfo,
            supertokensVersion: prodDeployment.coreVersion,
            displayCoreVersion: prodDeployment.displayCoreVersion,
            supertokensInstance: [
                {
                    containerName: "",
                    status: prodDeployment.status
                }
            ],
            id: app.appId
        };
    }

    return appData;
}

export function assertNever(t: never) {
    throw new Error("Unexpected value:" + t);
}

export function generateRandomId() {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomId = "";

    for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters[randomIndex];
    }

    return randomId;
}
export function isValidEmail(email: string) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return emailRegex.test(email.toLowerCase());
}

const fullNameRegex = XRegExp("^[\\p{L}\\p{M}'\\-\\s]+$");

export function verifyFullNameInput(fullNameInput: string): { success: true } | { success: false; reason: string } {
    if (fullNameInput === "") {
        return {
            success: false,
            reason: "Name cannot be empty"
        };
    }
    const isValidFullNameInput = fullNameRegex.test(fullNameInput);
    if (isValidFullNameInput) {
        return {
            success: true
        };
    } else {
        return {
            success: false,
            reason: "Please enter a valid name using only letters, spaces, hyphens, and apostrophes"
        };
    }
}
