import React, { useEffect, useState } from "react";

import { getDocVersions } from "../../api/docVersion";
import CaretSvg from "../elements/CaretSvg";

import "../../style/doc-version-header.module.css";
import { getLocationPath, getSDKLanguage } from "../../utils";

const versionRegex = /([0-9])+.([0-9])+.(X)+/i;

const DocVersionHeader = () => {
  // States
  const [sdkName, setSdkName] = useState<string>();
  const [path, setPath] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [newVersions, setNewVersions] = useState<string[]>([]);
  const [pathHash, setPathHash] = useState(window.location.hash);

  // didMount
  useEffect(() => {
    initStateFromWindowLocation();

    // store the hash part of the window location path in state if it is changed
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleHashChange = () => {
    const hash = window.location.hash;

    if (hash !== pathHash) {
      setPathHash(hash);
    }
  };

  const setCurrentVersionAndFetchOtherVersions = async (sdk: string, version: string) => {
    const res = await getDocVersions(sdk);

    if (res.versions !== undefined && res.versions.length > 0) {
      const versions = res.versions;

      if (version === "latest") {
        setCurrentVersion(versions[0]);
      } else {
        setCurrentVersion(version);
      }
      setNewVersions(versions);
    }

    // show version header once the data is loaded
    const el = document.getElementById("doc-version-header");
    if (el) {
      el.classList.remove("hidden");
    }
  };

  const initStateFromWindowLocation = () => {
    // window.location.pathname returns /docs/<sdkName>/<version ( if any )>/<path>
    // so splitting by '/' returns ['', 'docs', '<sdkName>', '<version or path fragment', ...path]
    const [_empty, _docs, sdk, versionOrPath, ...path] = getLocationPath().split("/");

    setSdkName(sdk);

    // the url can either be '/docs/<sdkname>/N.N.X/some/path...
    // or '/docs/<sdkname>/some/path
    // If the versionOrPath matches the N.N.X regex, we set the version and path accordingly
    // else we include the versionOrPath in the path
    if (versionRegex.test(versionOrPath)) {
      setCurrentVersionAndFetchOtherVersions(sdk, versionOrPath);
      setPath(path.join("/"));
    } else {
      setCurrentVersionAndFetchOtherVersions(sdk, "latest");

      // if the url is like '/docs/sdkname/path/with/more/content
      // else the url is like '/docs/sdkname/path
      if (path.length > 0) {
        setPath(`${versionOrPath}/${path.join("/")}`);
      } else {
        setPath(versionOrPath);
      }
    }
  };

  const getUrlForAutoGeneratedDocs = (version: string) => {
    if (sdkName !== undefined) {
      if (version === newVersions[0]) {
        return `/docs/${sdkName}/${path}`;
      } else {
        return `/docs/${sdkName}/${version}/${path}`;
      }
    }

    return "";
  };

  const renderOlderVersionsUrl = () => {
    // The sdks in sdksThatHaveOlderVersions have docs in docusaurus v2 docs
    // we render the 'Older versions' button if the sdkName is present in sdksThatHaveOlderVersions 
    const sdksThatHaveOlderVersions = ["nodejs", "auth-react", "react-native", "website"];
    if (sdkName !== undefined && sdksThatHaveOlderVersions.includes(sdkName)) {
      return (
        <a href={`/docs/${sdkName}/installation`} className="doc-version-header__content__item">
          Older versions
        </a>
      );
    }
  };

  const sdkLanguage = getSDKLanguage(sdkName);

  return (
    <div
      id="doc-version-header"
      className={`doc-version-header hidden ${sdkLanguage}`}
    >
      <div className="doc-version-header__button">
        <p>Version{` ${currentVersion}`}</p>
        <CaretSvg />
      </div>

      <div className="padding-top-10">
        <div className="doc-version-header__content">
          {newVersions.map((version: string) => {
            return (
              <a
                className={`doc-version-header__content__item ${version === currentVersion && "active"}`}
                href={`${getUrlForAutoGeneratedDocs(version)}${pathHash}`}
                target={sdkLanguage === "java" ? "_top" : "_self"}
              >
                {version}
              </a>
            );
          })}
          {renderOlderVersionsUrl()}
        </div>
      </div>
    </div>
  );
};

export default DocVersionHeader;
