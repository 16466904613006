export function compatibilityDataReducer(state: any, action: any) {
    switch (action.type) {
        case "ADD_COMPAT_DATA": {
            return { ...state, [action.cacheKey]: action.compatData };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export function coresDataReducer(state: any, action: any) {
    switch (action.type) {
        case "ADD_CORES_DATA": {
            return { ...state, [action.coresCacheKey]: action.cData };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
