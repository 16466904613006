export default class Storage {
    static getItem(key: string): string | undefined {
        const item = localStorage.getItem(key);
        return item !== null ? item : undefined;
    }

    static setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    static removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}
