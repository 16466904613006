import * as React from "react";

// Suppose our component gets dismounted in the middle of a dispatch
// which was executing a promise (fetch request for example). When the request
// returns it will try to update our component's state.
// But since our component has already been dismounted
// there will be no place to apply the changes to.
// Thus we will get a memory leak.
// To prevent this we will only dispatch while we are mounted
const useSafeDispatch = (unsafeDispatch: any) => {
    const mountedRef = React.useRef(false);
    // useLayoutEffect fires as soon as we are mounted without
    // waiting for the browser to paint the screen
    // Similarly the cleanup function is called as soon as the component gets
    // unmounted
    React.useLayoutEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const safeDispatch = React.useCallback(
        (...args) => {
            if (mountedRef.current) {
                unsafeDispatch(...args);
            }
        },
        [unsafeDispatch]
    );
    return safeDispatch;
};

// this is going to be our generic asyncReducer
function asyncReducer(state: any, action: any) {
    switch (action.type) {
        case "pending": {
            return { status: "pending", data: null, error: null };
        }
        case "resolved": {
            return { status: "resolved", data: action.data, error: null };
        }
        case "rejected": {
            return { status: "rejected", data: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const useAsync = (initialState: any) => {
    const [state, unsafeDispatch] = React.useReducer(asyncReducer, {
        status: "idle",
        data: null,
        error: null,
        ...initialState
    });

    const dispatch = useSafeDispatch(unsafeDispatch);
    // users will call run with a promise to accomplish their async tasks
    const run = React.useCallback(
        promise => {
            if (!promise) {
                return;
            }
            dispatch({ type: "pending" });
            promise.then(
                (data: any) => dispatch({ type: "resolved", data }),
                (error: any) => dispatch({ type: "rejected", error })
            );
        },
        [dispatch]
    );

    const setData = React.useCallback(data => dispatch({ type: "resolved", data }), [dispatch]);

    return { ...state, run, setData };
};
