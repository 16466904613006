import * as React from "react";
import { PlatformSelectionProps } from "./useOssTypes";
import { useOssSelectPlatformAnalytics } from "./useOssAnalytics";

export const PlatformSelection: React.FC<PlatformSelectionProps> = ({ selectedSdk, setOS, idPrefix, OSData }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleTabClick = (e: React.MouseEvent, tabIndex: number, tabKey: string) => {
    e.preventDefault();
    setActiveTabIndex(tabIndex);
    setOS(tabKey);
    useOssSelectPlatformAnalytics(tabKey);
  };

  return (
    <div className="item-selection">
      <h3 className="grey-text item-selection__heading">Please select operating system:</h3>
      <ol className="useoss-tabs">
        {OSData.map((item: { id: string; displayName: string }, index: number) => {
          const linkId = `${idPrefix}-${selectedSdk}-${item.id}-1`;
          return (
            <li
              key={item.id}
              className={`useoss-tabs__tab ${index === activeTabIndex ? "useoss-tabs__tab--active" : ""}`}
            >
              <a
                id={linkId}
                href="#"
                onClick={(e) => handleTabClick(e, index, item.id)}
                data-tab-index={index}
                data-tab-key={item.id}
              >
                {item.displayName}
              </a>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
