import { API_URL } from "../../../constants";
import * as httpNetworking from "../../../httpNetworking";
import { PlanType } from "../../../types/planType";

const URL = API_URL + "/plugin/dependency/cores";
const VERSION = 0;

export type GetSupportedCoresResponse = {
    cores: string[];
};

/**
 * Get all supertokens versions that the given plugin and plan type supports
 * @param plugin The database selected by the user
 * @param planType FREE or COMMERCIAL, plan type here should never be COMMERCIAL_TRIAL since its redundant
 */
export default async function getSupportedCores(
    plugin: string,
    planType: PlanType
): Promise<GetSupportedCoresResponse> {
    let options: httpNetworking.GETRequestConfig = {
        timeout: 58000,
        params: {
            name: plugin,
            planType: planType === "COMMERCIAL_TRIAL" ? "COMMERCIAL" : planType,
            mode: "PRODUCTION"
        }
    };

    let response = (await httpNetworking.simpleGETRequest(URL, options, VERSION)).data;
    return response;
}
