import * as React from 'react';
import "../../../style/dashboard/createAppStyles.css"
import { Dropdown } from '../../../components/dropdown';
import { RadioGroup } from '../../../components/radiogroup';
import SaasTooltip from '../../../components/elements/SaasTooltip';
import saasAnalytics from '../saasAnalytics';

type Props = {
    onSubmit: (region: string) => void;
    shouldAddShadow?: boolean;
    showSubmit?: boolean;
    regions: string[];
}

export type AuthRecipes = 
    | "thirdpartyemailpassword" 
    | "passwordless" 
    | "emailpassword" 
    | "thirdpartypasswordless"
    | "thirdparty"
    | "phonepassword"
    | "multitenancy"
    |"multifactorauth"

export type UITypes = "customui" | "prebuiltui";

export type SetupDetailsSteps = "frontend" | "backend" | "supertokenscore" | "configuration";

type CheckboxState = "ON" | "OFF" | "ERROR";

export type CoreTypes = "selfhosted" | "saas";

export type SelfHostedOptions = "docker" | "manual";

type GuidesLinks = {
    frontend: string;
    backend: string;
    core: string;
}

export type CreateAppData = {
    selectedRecipe?: AuthRecipes,
    selectedRecipeUI?: UITypes,
    expandedSections?: SetupDetailsSteps[],
    checkedSections?: SetupDetailsSteps[],
    selectedCoreType?: CoreTypes,
    selectedSelfHostedOption?: SelfHostedOptions,
    selectedRegion?: string,
}

const getRegionName = (region: string): string => {
    switch (region) {
        case "us-east-1":
            return "USA, N. Virginia (us-east-1)";
        case "eu-west-1":
            return "Ireland (eu-west-1)";
        case "ap-southeast-1":
            return "Singapore (ap-southeast-1)";
    }

    return region;
}

export const getImagePathForRegion = (region: string): string => {
    switch (region) {
        case "us-east-1":
            return "/static/assets/dashboard/flag-usa.png";
        case "eu-west-1":
            return "/static/assets/dashboard/flag-ireland.jpg";
        case "ap-southeast-1":
            return "/static/assets/dashboard/flag-singapore.png"
    }

    return "";
}

export const clearCreateAppDataFromStorage = () => {
    localStorage.removeItem("create-app-data");
}

export const CreateNewApp = (props: React.PropsWithChildren<Props>) => {
    const getSelectionFromStorage = (): CreateAppData => {
        const data = localStorage.getItem("create-app-data");
        if (data === null) {
            return {};
        }
        return JSON.parse(data);
    }

    const dataFromStorage = getSelectionFromStorage();
    const [selectedRecipe, setSelectedRecipe] = React.useState<AuthRecipes | undefined>(dataFromStorage.selectedRecipe);
    const [selectedRecipeUI, setSelectedRecipeUI] = React.useState<UITypes | undefined>(dataFromStorage.selectedRecipeUI);
    const [expandedSections, setExpandedSections] = React.useState<SetupDetailsSteps[]>(dataFromStorage.expandedSections || []);
    const [checkedSections, setCheckedSections] = React.useState<SetupDetailsSteps[]>(dataFromStorage.checkedSections || []);
    const [selectedCoreType, setSelectedCoreType] = React.useState<CoreTypes | undefined>(dataFromStorage.selectedCoreType);
    const [selectedSelfHostedOption, setSelectedSelfHostedOption] = React.useState<SelfHostedOptions | undefined>(dataFromStorage.selectedSelfHostedOption);
    const [selectedRegion, setSelectedRegion] = React.useState<string | undefined>(dataFromStorage.selectedRegion);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const shouldShowSubmit = props.showSubmit === undefined ? true : props.showSubmit;

    const authMethodRef = React.useRef(null);
    const uiTypeRef = React.useRef(null);

    const saveSelectionToStorage = () => {
        const data = {
            selectedRecipe,
            selectedRecipeUI,
            expandedSections,
            checkedSections,
            selectedCoreType,
            selectedSelfHostedOption,
            selectedRegion
        };

        localStorage.setItem("create-app-data", JSON.stringify(data));
    }

    React.useEffect(()=>{
        saasAnalytics.visitSaasDashboardGetStartedPage()
    },[])

    React.useEffect(() => {
        saveSelectionToStorage();
    }, [selectedRecipe, selectedRecipeUI, expandedSections, checkedSections, selectedCoreType, selectedSelfHostedOption, selectedRegion]);

    const resetCheckedSections = () => {
        if (checkedSections.length !== 0) {
            setCheckedSections([]);
        }
        
        if(selectedRecipe !== undefined && selectedRecipeUI !== undefined){
            setExpandedSections(["frontend"]);
        }
    }

    const prepareAllLinks = (): GuidesLinks => {
        const docsBase = "docs";

        let recipeBase = "";

        // Finding the recipe base path
        switch (selectedRecipe) {
            case "emailpassword":
                recipeBase = "emailpassword";
                break;
            case "passwordless":
                recipeBase = "passwordless";
                break;
            case "phonepassword":
                recipeBase = "phonepassword";
                break;
            case "thirdparty":
                recipeBase = "thirdparty";
                break;
            case "thirdpartyemailpassword":
                recipeBase = "thirdpartyemailpassword";
                break;
            case "thirdpartypasswordless":
                recipeBase = "thirdpartypasswordless";
                break;
            case "multitenancy":
                recipeBase = "multitenancy";
                break;
            case "multifactorauth":
                recipeBase = "mfa";
                break;
        }
        
        let frontendSetupPath = "";
        let backendSetupPath = "";
        let uiTypePath = "";

        switch (selectedRecipeUI) {
            case "customui":
                uiTypePath = "custom-ui";
                frontendSetupPath = "init/frontend";
                backendSetupPath = "init/backend";
                break;
        
            default:
                uiTypePath = "pre-built-ui";
                frontendSetupPath = "setup/frontend";
                backendSetupPath = "setup/backend";
                break;
        }

        let coreSetupPath = "";
        let corePrefix = "setup";

        if (selectedRecipeUI === "customui") {
            corePrefix = "init";
        }

        switch (selectedCoreType) {
            case "selfhosted":
                switch (selectedSelfHostedOption) {
                    case "docker":
                        coreSetupPath = corePrefix + "/core/with-docker";
                        break;
                    case "manual":
                        coreSetupPath = corePrefix + "/core/without-docker";
                        break;
                }
                break;
            case "saas":
                coreSetupPath = corePrefix + "/core/saas-setup";
                break;
        }

        if (selectedRecipe === "phonepassword" || selectedRecipe === "multitenancy") {
            frontendSetupPath = "introduction";
            backendSetupPath = "introduction";
            coreSetupPath = "introduction";
            uiTypePath = "";
        }

        if (selectedRecipe === "multifactorauth") {
            frontendSetupPath = "frontend-setup";
            backendSetupPath = "backend-setup";
            coreSetupPath = "introduction";
            uiTypePath = "";
        }

        const frontendSetupLink = [docsBase, recipeBase, uiTypePath, frontendSetupPath].filter(i => i !== "").join("/");
        const backendSetupLink = [docsBase, recipeBase, uiTypePath, backendSetupPath].filter(i => i !== "").join("/");
        const coreSetupPathLink = [docsBase, recipeBase, uiTypePath, coreSetupPath].filter(i => i !== "").join("/");

        return {
            backend: backendSetupLink,
            frontend: frontendSetupLink,
            core: coreSetupPathLink
        };
    }

    const guidesLinks = prepareAllLinks();

    const renderSectionHeader = (headerText: string, subtitle?: string): JSX.Element => {
        return (
            <div className="section-header-container">
                <span className="section-header-title">{headerText}{subtitle &&
                <span className="section-header-subtitle">{subtitle}</span>}</span>
            </div>
        );
    }

    const renderAuthMethodSection = () => {
        return (
            <div className="section-content-container">
                <div className="section-item">
                    <div className="section-item-title">
                        1. Please select your desired Recipe (auth method)

                        <img
                            className='tooltip-icon'
                            src="static/assets/dashboard/question-round.png"
                            alt="Connection tips"
                            ref={authMethodRef}
                            />
                    </div>

                    <Dropdown <AuthRecipes>
                        defaultText="Select auth method"
                        selectedValue={selectedRecipe}
                        additionalClassNames={["indented"]}
                        items={[
                            {
                                value: "thirdpartyemailpassword",
                                text: getAuthMethodName("thirdpartyemailpassword"),
                            },
                            {
                                value: "passwordless",
                                text: getAuthMethodName("passwordless"),
                            },
                            {
                                value: "emailpassword",
                                text: getAuthMethodName("emailpassword"),
                            },
                            {
                                value: "thirdpartypasswordless",
                                text: getAuthMethodName("thirdpartypasswordless"),
                            },
                            {
                                value: "thirdparty",
                                text: getAuthMethodName("thirdparty"),
                            },
                            {
                                value: "phonepassword",
                                text: getAuthMethodName("phonepassword"),
                            },
                            {
                                value: "multitenancy",
                                text: getAuthMethodName("multitenancy"),
                            },
                            {
                                value: "multifactorauth",
                                text: getAuthMethodName("multifactorauth"),
                            }
                        ]}
                        onChange={(value) => {
                            setSelectedRecipe(value);
                            if (!expandedSections.includes("frontend") && selectedRecipeUI !== undefined) {
                                setExpandedSections([...expandedSections, "frontend"]);
                            }
                            saasAnalytics.selectedRecipe(value)
                            resetCheckedSections();
                        }}/>
                </div>

                <div className="section-item">
                    <div className="section-item-title">
                        2. Are you going to use prebuilt UI or custom UI?

                        <img
                            className='tooltip-icon'
                            src="static/assets/dashboard/question-round.png"
                            alt="Connection tips"
                            ref={uiTypeRef}
                            />
                    </div>
                    <RadioGroup<UITypes>
                        items={[
                            {
                                value: "customui",
                                label: "Custom UI"
                            },
                            {
                                value: "prebuiltui",
                                label: "Prebuilt UI"
                            }
                        ]} 
                        onChange={(value) => {
                            setSelectedRecipeUI(value);

                            if (selectedRecipe !== undefined && !expandedSections.includes("frontend")) {
                                setExpandedSections([...expandedSections, "frontend"]);
                            }
                            saasAnalytics.selectedUIType(value)
                            resetCheckedSections();
                        }}
                        additionalClassNames={["indented"]}
                        layoutMode='HORIZONTAL'
                        selectedValue={selectedRecipeUI}/>
                </div>
            </div>
        );
    }

    const renderSetupTextWithLink = (text: string, link: string, indent: boolean = true) => {
        return (
            <div className={`setup-text-container ${indent ? "indented" : ""}`}>
                <span><a target='_blank' onClick={() => saasAnalytics.docsRedirects(link)} href={link}>Click here</a>{" "}</span>{text}
            </div>
        );
    }

    const renderSectionTitle = (title: string, id: SetupDetailsSteps, checkboxState: CheckboxState, onChecked: () => void, showCheckbox?:boolean) => {
        return (
            <div className='setup-section-item-title'>
                {showCheckbox === false ? null :<input
                    className={`${checkboxState === "ERROR" ? "error-checkbox" : ""}`}
                    type='checkbox' 
                    id={id} 
                    name={id} 
                    value={id} 
                    checked={checkboxState === "ON"}
                    onChange={onChecked}/>
                }
                <label className={showCheckbox === false ? "margin-left-32": undefined} htmlFor={id}>
                    {title}
                </label>
            </div>
        );
    }

    const getIsFrontendEnabled = () => {
        return selectedRecipe !== undefined && selectedRecipeUI !== undefined;
    }

    const isFrontendChecked = checkedSections.includes("frontend");

    const getAuthMethodName = (recipe?: AuthRecipes): string => {
        switch (recipe) {
            case "thirdpartyemailpassword":
                return "Email password + Social login";
        
            case "passwordless":
                return "Passwordless";

            case "emailpassword":
                return "Email Password Login";

            case "thirdparty":
                return "Social login";

            case "phonepassword":
                return "Phone password login";
            
            case "thirdpartypasswordless":
                return "Social login + Passwordless";

            case "multitenancy":
                return "Multi-tenant authentication";

            case "multifactorauth":
                return "Multi-factor authentication";

            default:
                return "";
        }
    }

    const getFrontendSetupDetailsContent = () => {

        if (!getIsFrontendEnabled()) {
            return (
                <div className='warning-note indented'>
                    <span><b>Note:</b> Please select your desired recipe and UI type above to enable us to redirect you to the relevant documentation.</span>
                </div>
            );
        }

        let recipePostFix = "";

        if (selectedRecipe !== undefined) {
            recipePostFix = ` (${getAuthMethodName(selectedRecipe)})`;
        }

        return renderSetupTextWithLink(`to integrate with our frontend SDK${recipePostFix}`, guidesLinks.frontend);
    }

    const getFrontendCheckboxState = (): CheckboxState => {
        if (expandedSections.includes("frontend")) {
            if (getIsFrontendEnabled()) {
                return isFrontendChecked ? "ON" : "OFF";
            }

            return "ERROR";
        }

        return "OFF";
    }

    const renderCoreDetailsSection = () => {
        const configurationClassName = checkedSections.includes("supertokenscore") ? "" : "";
        const dockerSuffix = selectedSelfHostedOption === "docker" ? " with Docker" : " without Docker";

        return (
            <div className='section-content-container'>
                <div className='section-item'>
                    <div className="section-item-title">
                        Are you using the managed service or the self hosted version?
                    </div>                

                    <RadioGroup<CoreTypes>
                        items={[
                            {
                                value: "saas",
                                label: "Managed"
                            },
                            {
                                value: "selfhosted",
                                label: "Self hosted"
                            }
                        ]} 
                        onChange={(value) => {
                            setSelectedCoreType(value);
                            saasAnalytics.selectedCoreServiceType(value)
                        }}
                        additionalClassNames={["indented"]}
                        layoutMode='HORIZONTAL'
                        selectedValue={selectedCoreType}/>
                </div> 

                {
                    selectedCoreType === "saas" && 
                    <div className={`section-item`}>
                        <span className={`core-countries-container ${shouldShowSubmit ? "" : "disabled"}`}>
                            <b>Managed service details:</b> 
                            
                            <div style={{position: "relative", marginLeft: 14}}>
                                {selectedRegion !== undefined ? <img className='region-flag' alt='region-flag' src={getImagePathForRegion(selectedRegion)}/>: null}
                                <Dropdown<string> 
                                    disabled={!shouldShowSubmit}
                                    defaultText="Select region"
                                    additionalClassNames={[selectedRegion === undefined ? `padding-left-8` : '']}
                                    selectedValue={selectedRegion}
                                    items={props.regions.map((region) => {
                                        return {
                                            value: region,
                                            text: getRegionName(region)
                                        }
                                    })}
                                    onChange={(value) => {
                                        setSelectedRegion(value);
                                        saasAnalytics.selectedRegionType(value)
                                    }}
                                /> 
                            </div>
                            
                            <span className='secondary-text'>Cloud service provider:
                                <div className='aws-image-container'>
                                    <img src="/static/assets/dashboard/aws.png" alt='aws-logo' className='aws-logo'/>
                                </div>
                            </span>
                        </span>

                        <div className='section-item'>
                                <div className='setup-note' style={{
                                    marginBottom: 0,
                                }}>
                                    <b>Note</b>: Region cannot be changed after the core is deployed
                                </div>
                        </div>
                    </div>
                }

                {
                    selectedCoreType === "selfhosted" &&
                    <div>
                        <div className='section-item'>
                            <div className="section-item-title">
                                Do you want to use SuperTokens with or without Docker?
                            </div>                

                            <RadioGroup<SelfHostedOptions>
                                items={[
                                    {
                                        value: "docker",
                                        label: "With Docker"
                                    },
                                    {
                                        value: "manual",
                                        label: "Without Docker"
                                    }
                                ]} 
                                onChange={(value) => {
                                    setSelectedSelfHostedOption(value);
                                    saasAnalytics.selectedSelfHostedType(value)
                                }}
                                additionalClassNames={["indented"]}
                                layoutMode='HORIZONTAL'
                                selectedValue={selectedSelfHostedOption}/>
                        </div> 

                        {selectedSelfHostedOption ? renderSetupTextWithLink("to setup SuperTokens Core" + dockerSuffix, guidesLinks.core, false): null}
                    </div>
                }

                {
                    selectedCoreType === "saas" && expandedSections.includes("supertokenscore") && shouldShowSubmit &&
                    <div className='setup-section-item no-left-spacing'>
                        <div 
                            className={`cta ${configurationClassName}`} 
                            style={{
                                cursor: configurationClassName === "" ? "pointer" : "default"
                            }}
                            onClick={async () => {
                                if (configurationClassName !== "") {
                                    return;
                                }

                                if (isSubmitting) {
                                    return;
                                }

                                if(selectedRegion === undefined){
                                    alert("Please select a region to deploy your core");
                                    return;
                                }
                                
                                setIsSubmitting(true);
                                props.onSubmit(selectedRegion);
                                const dataFromStorage = getSelectionFromStorage()
                                saasAnalytics.createPublicDevEnv(dataFromStorage)
                            }}>
                            Deploy SuperTokens Core
                        </div>
                    </div>
                }

            </div>
        );
    }

    const getBackendSetupDetailsSection = () => {
        let recipePostFix = "";

        if (selectedRecipe !== undefined) {
            recipePostFix = ` (${getAuthMethodName(selectedRecipe)})`;
        }

        return renderSetupTextWithLink(`to integrate with our backend SDK${recipePostFix}`, guidesLinks.backend);
    }

    const renderSetupDetailsSection = () => {
        const disableFrontend = !getIsFrontendEnabled() && !expandedSections.includes("frontend")
        const disabledBackend = !expandedSections.includes("backend");
        const disabledCore = !expandedSections.includes("supertokenscore");
        return (
            <div className='setup-section-container'>
                <div className={'setup-section-item'}>
                    <div className={`${disableFrontend ? "disabled" : ""}`}>
                        {renderSectionTitle(
                            "Step 1: Frontend setup", 
                            "frontend", 
                            getFrontendCheckboxState(),
                            () => {
                                let currentlyExpandedSections = [...expandedSections];
                                if (currentlyExpandedSections.includes("frontend")) {
                                    if (getIsFrontendEnabled()) {
                                        let currentlyCheckedSections = [...checkedSections];
                                        if (!currentlyCheckedSections.includes("frontend")) {
                                            currentlyCheckedSections.push("frontend");
                                            setCheckedSections([...currentlyCheckedSections]);

                                            currentlyExpandedSections.push("backend");
                                        }
                                    }
                                } else {
                                    currentlyExpandedSections.push("frontend");
                                }
                                saasAnalytics.checkedSetupSteps("frontend")
                                setExpandedSections([...currentlyExpandedSections]);
                            },
                        )}
                        {
                            expandedSections.includes("frontend") &&
                            getFrontendSetupDetailsContent()
                        }
                    </div>
                </div>

                <div className={`setup-section-item`}>
                    <div className={`${disabledBackend ? "disabled" : ""}`}>
                        {renderSectionTitle(
                            "Step 2: Backend setup", 
                            "backend",
                            checkedSections.includes("backend") ? "ON" : "OFF",
                            () => {
                                let currentlyExpandedSections = [...expandedSections];
                                if (currentlyExpandedSections.includes("backend")) {
                                    let currentlyCheckedSections = [...checkedSections];
                                    if (!currentlyCheckedSections.includes("backend")) {
                                        currentlyCheckedSections.push("backend");
                                        setCheckedSections([...currentlyCheckedSections]);

                                        currentlyExpandedSections.push("supertokenscore");
                                    }
                                } else {
                                    return;
                                }
                                saasAnalytics.checkedSetupSteps("backend")
                                setExpandedSections([...currentlyExpandedSections]);
                                        
                            }
                        )}
                        {
                            expandedSections.includes("backend") &&
                            getBackendSetupDetailsSection()
                        }
                    </div>
                </div>

                <div className='setup-section-item'>
                    <div className={`${disabledCore ? "disabled" : ""}`}>
                        {renderSectionTitle(
                            "Step 3: SuperTokens core setup", 
                            "supertokenscore", 
                            checkedSections.includes("supertokenscore") ? "ON" : "OFF",
                            () => {
                                let currentlyExpandedSections = [...expandedSections];
                                if (currentlyExpandedSections.includes("supertokenscore")) {
                                    let currentlyCheckedSections = [...checkedSections];
                                    if (!currentlyCheckedSections.includes("supertokenscore")) {
                                        currentlyCheckedSections.push("supertokenscore");
                                        setCheckedSections([...currentlyCheckedSections]);

                                        currentlyExpandedSections.push("configuration");
                                    }
                                } else {
                                    return;
                                }
                                saasAnalytics.checkedSetupSteps("supertokenscore")
                                setExpandedSections([...currentlyExpandedSections]);
                            },
                            false
                        )}

                        {
                            expandedSections.includes("supertokenscore") &&
                            renderCoreDetailsSection()
                        }
                    </div>
                </div>
            </div>
        );
    }

    const rootClassNames = ["root-container"];

    if (props.shouldAddShadow === true) {
        rootClassNames.push("with-shadow");
    }

    return (
        <div className={rootClassNames.join(" ")}>
            {props.children}
            {renderSectionHeader("Select Auth Method")}
            {renderAuthMethodSection()}
            {renderSectionHeader("Setup Details", "(Check the boxes as you complete each step)")}
            {renderSetupDetailsSection()}
            <SaasTooltip
                trigger="hover"
                elementRef={authMethodRef}
            >
                <p style={{ textAlign: "center" }}>
                    Your recipe is your preferred auth method. Have a look at our <a href='/docs/guides' target="_blank">Guides page</a> to see a list of the available recipes
                </p>
            </SaasTooltip>

            <SaasTooltip
                trigger="hover"
                elementRef={uiTypeRef}
            >
                <p style={{ textAlign: "center" }}>
                    SuperTokens comes with a prebuilt UI or you can build your own
                </p>
            </SaasTooltip>
        </div>
    );
}