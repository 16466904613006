import React from "react";
import { Link } from "react-router-dom";

import "../../../ts/style/dashboard/saas-bottom-navigation-bar.module.css";
import { NavigationItem, NavigationItemName } from "./types";

export const SaasMobileNav = ({
    activeItem,
    navigationItems
}: {
    activeItem: NavigationItemName;
    navigationItems: NavigationItem[];
}) => {
    return (
        <div id="dashboard-mobile-nav-wrapper">
            <ul id="dashboard-mobile-nav">
                {navigationItems.map(({ title, icon, href, name }) => (
                    <li key={title} className={`dashboard-mobile-nav-item ${name === activeItem ? "active-link" : ""}`}>
                        <Link to={href}>
                            <div>{icon}</div>
                            <div>{title}</div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
