"use client";

import React, { useState, useEffect } from "react";
import { sendButtonAnalytics, getPathAndSelectedPage } from "../utils";

import CustomHeaderDropdown from "./components/custom-header-dropdown";
import ProductHeaderDropdownContent from "./components/product-header-dropdown-content";
import CompanyHeaderDropdown from "./components/company-header-dropdown";

import HeaderDropdown, { HeaderDropDownOptions } from "./components/HeaderDropdown";

import { BlogIcon, CustomersIcon, RoadmapIcon, SupportIcon } from "./components/HeaderDropdownIcons";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import "../style/header.module.css";
import ResourcesHeaderDropdown from "./components/resources-header-dropdown-content";
import Announcement from "./Announcement";

export default function Header({
    removeCloneHeader,
    theme = "dark",
    size = "large",
    CustomCTA,
    showAnnouncement = true
}: {
    removeCloneHeader?: () => void;
    theme?: "light" | "dark";
    size?: "small" | "large";
    CustomCTA?: React.FC;
    showAnnouncement?: boolean;
}) {
    const [showHamburger, setShowHamburger] = useState(false);
    const session = useSessionContext();

    const toggleHamburger = () => {
        setShowHamburger(!showHamburger);
    };

    const handleHomeLogoClick = () => {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "home" && pageSelected !== undefined) {
            sendButtonAnalytics("button_header_home", "v5", {
                page_selected: pageSelected
            });
        }
        setShowHamburger(false);
    };

    const handleBlogClick = () => {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "blog" && pageSelected !== undefined) {
            sendButtonAnalytics("button_header_blog", "v5", {
                page_selected: pageSelected
            });
        }
    };

    const handlePricingClick = () => {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "pricing" && pageSelected !== undefined) {
            sendButtonAnalytics("button_header_pricing", "v5", {
                page_selected: pageSelected
            });
        }
    };

    function handleRoadmapClick() {
        const { path, pageSelected } = getPathAndSelectedPage();
        if (path !== "product-roadmap" && pageSelected !== undefined) {
            sendButtonAnalytics("button_header_product_roadmap", "v5", {
                page_selected: pageSelected
            });
        }
    }

    function handleConsultancyClick() {
        const { pageSelected } = getPathAndSelectedPage();
        if (pageSelected !== undefined) {
            sendButtonAnalytics("button_header_consultancy", "v5", {
                page_selected: pageSelected
            });
        }
    }

    function handleDiscordClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_header_discord", "v5", {
            page_selected: pageSelected
        });
    }

    function handleCustomersClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_header_customers", "v5", {
            page_selected: pageSelected
        });
    }

    function handleDocsGuidesClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_header_documentation_userguides", "v5", {
            pageSelected
        });
    }

    function handleResourcesClick() {
        const { pageSelected } = getPathAndSelectedPage();
        sendButtonAnalytics("button_header_resources", "v5", { pageSelected });
    }

    const resourcesDropDownLinks: HeaderDropDownOptions[] = [
        {
            value: "Roadmap",
            href: "/product-roadmap",
            optionClickEvent: handleRoadmapClick,
            Icon: RoadmapIcon
        },
        {
            value: "Blog",
            href: "/blog",
            optionClickEvent: handleBlogClick,
            Icon: BlogIcon
        },
        {
            value: "Customers",
            href: "/customers",
            optionClickEvent: handleCustomersClick,
            Icon: CustomersIcon
        },
        {
            value: "Support",
            href: "/discord",
            optionClickEvent: handleDiscordClick,
            Icon: SupportIcon
        }
    ];

    function renderDashboardOrAuthLink(type: "burgerMenu" | "nav") {
        const linkClassName = type === "burgerMenu" ? "hamburger_menu_button" : "nav_button";

        if (session.loading) {
            return (
                <div className={`${linkClassName}`}>
                    <div className="btn_inline_loader" />
                </div>
            );
        } else if (session.doesSessionExist === true) {
            return (
                <a
                    className={linkClassName}
                    href={"/dashboard-saas"}
                    onClick={() => sendButtonAnalytics("button_home_header_dashboard", "v5")}
                >
                    Dashboard
                </a>
            );
        } else {
            return (
                <a
                    className={linkClassName}
                    href="/auth"
                    onClick={() => sendButtonAnalytics("button_home_header_signup", "v4")}
                >
                    Sign Up
                </a>
            );
        }
    }

    const isLightTheme = theme === "light";

    useEffect(() => {
        if (removeCloneHeader) {
            window.requestAnimationFrame(() => {
                removeCloneHeader();
            });
        }
    }, []);

    return (
        <>
            {showAnnouncement && (
                <Announcement heading="✨ We've Made It Better! :" text="Explore Our Updated Docs" link="/docs" />
            )}
            <header
                className={`header_container ${size === "small" ? "small-header-container" : ""} ${
                    showAnnouncement ? "" : "no_announcement"
                }`}
            >
                <div className={`sticky_container ${showAnnouncement ? "" : "no_announcement"}`}>
                    <nav
                        className={`header ${isLightTheme ? "light-theme" : ""} ${
                            size === "small" ? "small-header" : ""
                        }`}
                    >
                        <a href="/" onClick={handleHomeLogoClick}>
                            <img
                                height={30}
                                width={200}
                                src={
                                    isLightTheme
                                        ? "/static/assets/dark-home/logo-light.png"
                                        : "/static/assets/dark-home/logo.png"
                                }
                                className="header_logo"
                                alt="SuperTokens homepage"
                            />
                        </a>

                        <div className="header_cta_end">
                            <div className="header_actions">
                                <a
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    href="/pricing"
                                    onClick={handlePricingClick}
                                >
                                    Pricing
                                </a>
                                <a
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    href="/docs/guides"
                                    onClick={handleDocsGuidesClick}
                                >
                                    Documentation
                                </a>
                                <CustomHeaderDropdown
                                    title="Company"
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    theme={theme}
                                >
                                    <CompanyHeaderDropdown />
                                </CustomHeaderDropdown>

                                <CustomHeaderDropdown
                                    title="Product"
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    theme={theme}
                                >
                                    <ProductHeaderDropdownContent />
                                </CustomHeaderDropdown>

                                <CustomHeaderDropdown
                                    title="Resources"
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    theme={theme}
                                >
                                    <ResourcesHeaderDropdown />
                                </CustomHeaderDropdown>

                                <a
                                    className={`header_cta ${theme === "light" ? "light-theme-header-cta" : ""} ${
                                        size === "small" ? "small-header-cta" : ""
                                    }`}
                                    href="/consultancy"
                                    onClick={handleConsultancyClick}
                                >
                                    Contact Sales
                                </a>
                            </div>
                            {CustomCTA ? <CustomCTA /> : renderDashboardOrAuthLink("nav")}
                        </div>
                    </nav>
                    <nav
                        className={`header_drawer ${showHamburger ? "hamburger_menu_open" : ""} ${
                            isLightTheme ? "light-theme" : ""
                        }`}
                    >
                        <div
                            className={`hamburger_button ${isLightTheme && !showHamburger ? "light-theme" : ""} ${
                                showHamburger ? "hamburger_button_close" : ""
                            }`}
                            onClick={toggleHamburger}
                        >
                            <span className="line" />
                            <span className="line" />
                            <span className="line" />
                        </div>
                        <a href="/" onClick={handleHomeLogoClick}>
                            <img
                                height={30}
                                width={200}
                                src={
                                    isLightTheme
                                        ? showHamburger
                                            ? "/static/assets/dark-home/logo.png"
                                            : "/static/assets/dark-home/logo-light.png"
                                        : "/static/assets/dark-home/logo.png"
                                }
                                className="header_logo"
                                alt="SuperTokens homepage"
                            />
                        </a>
                    </nav>
                    <div className={`hamburger_menu ${showHamburger ? "hamburger_menu_open" : ""} `}>
                        <a href="/pricing">
                            <h4 onClick={handlePricingClick}>Pricing</h4>
                        </a>
                        <a href="/docs/guides">
                            <h4 onClick={handleDocsGuidesClick}>Documentation</h4>
                        </a>
                        <CustomHeaderDropdown title="Company" type="mobile" close={() => setShowHamburger(false)}>
                            <CompanyHeaderDropdown type="mobile" />
                        </CustomHeaderDropdown>

                        <CustomHeaderDropdown title="Product" type="mobile" close={() => setShowHamburger(false)}>
                            <ProductHeaderDropdownContent type="mobile" />
                        </CustomHeaderDropdown>
                        <HeaderDropdown title="Resources" options={resourcesDropDownLinks} type="mobile" />
                        <a href="/consultancy">
                            <h4 onClick={handleConsultancyClick}>Contact Sales</h4>
                        </a>
                        {CustomCTA ? <CustomCTA /> : renderDashboardOrAuthLink("burgerMenu")}
                    </div>
                </div>
            </header>
        </>
    );
}
