//TODO: move it to the sections
import * as React from 'react'

declare let stAnalytics: any;


interface IMyComponentState {
    redirectButtonClicked: boolean
    redirectTimeStamp: number
}

interface IMyComponentProps {
    redirectLink: string
}

class Redirect extends React.PureComponent<IMyComponentProps, IMyComponentState> {
    constructor(props: IMyComponentProps) {
        super(props)
        const redirectTimeStamp = Date.now() + 5000
        this.state = {
            redirectButtonClicked: false, 
            redirectTimeStamp: redirectTimeStamp
        }

    }
    componentDidMount() {
        const redirectLink = this.props.redirectLink
        const that = this
        const redirectToExternalLink = setInterval(function () {
            const currentTimeStamp = Date.now()
            if ((stAnalytics && stAnalytics.getInstance().pageViewEventSent === true) || that.state.redirectButtonClicked === true) {
                window.location.href = redirectLink
                clearInterval(redirectToExternalLink)
            } else if (currentTimeStamp  > that.state.redirectTimeStamp)  {
                window.location.href = redirectLink
                clearInterval(redirectToExternalLink)
            }
        }, 100);
    }

    render() {
        return (
            <>
            <p> Redirecting... <span style={{ textDecoration: "underline", cursor: "pointer", color: 'blue' }} onClick={() => { this.setState({ redirectButtonClicked: true }) }}> Click here </span> if this does not happen automatically</p>
            </>
        )
    }
}

export default Redirect

