import React from "react"

const CaretSvg = () => {
  return (
    <svg className="caret-svg" xmlns="http://www.w3.org/2000/svg" width="4.49" height="7.698" viewBox="0 0 4.49 7.698">
      <path data-name="Path 91877" d="M4.329 3.468.942.158a.561.561 0 0 0-.78 0 .53.53 0 0 0 0 .762l3 2.929-3 2.928a.53.53 0 0 0 0 .762.561.561 0 0 0 .78 0L4.329 4.23a.53.53 0 0 0 0-.762z" />
    </svg>
  )
}

export default CaretSvg
