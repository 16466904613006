import * as React from 'react'
import "./../../style/button-box.module.css"
import "./../../style/common.module.css"

declare let stAnalytics: any;

// color 1 = orange, 2 = black
interface button {
    text: string,
    onClick?: () => void,
    size: number,
    color: number,
    analyticsEventName?: string,
    boxShadow?: boolean,
    link?: string,
    buttonFlat?: boolean,
    textBold?: boolean,
    metaData?: any
}


const sendAnalyticsOnClick = (eventName?: string, href?: string, metaData?: any) => {
    if (eventName) { stAnalytics.getInstance().sendButtonEvent(eventName, metaData); }
    if (href) {
        setTimeout(function () {
            window.open(href, "_self");
        }, 100)
    }
}

// Generally used to show popups and stuff 
export const Button = (props: button) => {
    const { text, onClick, size, color, boxShadow, analyticsEventName, link, buttonFlat, metaData } = props
    let buttonColor = null
    let buttonTextSize = 'button-text-normal'
    let buttonRadius = null
    let buttonBorder = null
    let buttonDivSize = null
    let buttonPading = null
    let shadow = null
    let analyticClass = ''
    let buttonMove = 'button-move'

    if (size === 1) {
        // Heading Size
        // Equaivalent to css class small 
        buttonTextSize = 'button-text-small'
        buttonDivSize = 'button-size-small center-vertically'
        buttonRadius = 'button-radius-small'
        buttonPading = 'button-small-pading'
        buttonMove = '' // this is used in signup button on navbar, we don't want signup icon to move on hover
    } else if (size === 2) { // used in get started on homepage
        buttonDivSize = 'button-size-normal'
        buttonTextSize = 'button-text-heading'
        buttonRadius = 'button-radius-small'
    } else if (size === 3) { // used in see all features in home page
        buttonDivSize = 'button-div-size-style-3'
        buttonTextSize = 'button-text-normal'
        buttonRadius = 'button-radius-small'
    } else if (size === 4) {
        buttonTextSize = 'button-text-normal'
        buttonDivSize = 'buttton-extra-small-div'
        buttonRadius = 'button-radius-small'
    } else if (size === 5) { // Pricing page get started button
        buttonDivSize = 'button-div-size-style-5'
        buttonTextSize = 'button-text-normal'
    } else if (size == 6) { // used in blog side navbar "Learn more button"
        buttonDivSize = 'button-div-size-style-6'
        buttonTextSize = 'button-text-tiny'
    } else if (size === 7) {
        buttonDivSize = 'button-div-size-style-7'
        buttonTextSize = 'button-text-style-7'
    }

    if (color === 1) {
        buttonColor = 'button-color-orange' // color scheme used in navbar signup
    } else if (color === 2) {
        buttonColor = 'button-color-black' // color scheme used in getting started/see all feastures button 
    } else if (color === 3) { // used in getting started in pricing page 
        buttonColor = 'button-color-complete-orange'
    } else if (color === 4) {
        buttonColor = 'button-color-just-black'
        buttonBorder = 'button-color-border-black'
    }

    if (buttonFlat) {
        buttonRadius = 'border-radius-flat'
    }

    if (boxShadow) {
        shadow = 'button-box-shadow-dark'
    }

    return (
        <div className="w-100 center-horizontally ">
            <a onClick={() => {
                sendAnalyticsOnClick(analyticsEventName, link, metaData)
                if (onClick) {
                    onClick()
                }
            }}
                className={`${shadow} ${buttonColor} ${buttonMove} button-center ${buttonRadius} ${buttonDivSize} ${buttonBorder} ${buttonPading} ${analyticClass} ${props.textBold ? 'bold' : ''}`}>
                <span className={`${buttonTextSize}`}>{text}</span>
            </a>
        </div>
    )
}

