import React, { useEffect, useRef, useState } from "react";

import "../../style/header-dropdown.module.css";
import getIcon from "./common/getDropdownIcon";

export type HeaderDropDownOptions = {
    value: string;
    href: string;
    Icon?: any;
    optionClickEvent?: React.MouseEventHandler<HTMLAnchorElement>;
};

interface HeaderDropdownPropTypes {
    title: string;
    options: HeaderDropDownOptions[];
    type?: "mobile";
    className?: string;
    titleClickHandler?: React.MouseEventHandler<HTMLDivElement>;
    theme?: "light" | "dark";
    size?: "small" | "large";
}

const HeaderDropdown = (props: HeaderDropdownPropTypes) => {
    const targetElementRef = useRef<HTMLDivElement>(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
        if (targetElementRef.current !== undefined && targetElementRef.current !== null) {
            targetElementRef.current.addEventListener("mouseover", handleMenuOpen);
            targetElementRef.current.addEventListener("mouseleave", handleMenuClose);
        }

        return () => {
            if (targetElementRef.current !== undefined && targetElementRef.current !== null) {
                targetElementRef.current.removeEventListener("mouseover", handleMenuOpen);
                targetElementRef.current.removeEventListener("mouseleave", handleMenuClose);
            }
        };
    }, []);

    const handleMenuOpen = () => {
        if (!isMenuVisible) {
            setIsMenuVisible(true);
        }
    };

    const handleMenuClose = () => {
        if (isMenuVisible) {
            setIsMenuVisible(false);
        }
    };

    const handleMenuToggle = () => {
        setIsMenuVisible(prevValue => !prevValue);
    };

    const dropdownLinks = props.options.map(option => {
        const { Icon } = option;
        return (
            <a
                key={option.value}
                href={option.href}
                className={
                    props.type === "mobile"
                        ? "header_cta_dropdown_mobile__links__link"
                        : "header_cta_dropdown__links__link"
                }
                onClick={e => {
                    option.optionClickEvent!(e);
                    handleMenuClose();
                }}
            >
                <div className="header_dropdown_icon_container">
                    <Icon />
                </div>
                {option.value}
            </a>
        );
    });

    if (props.type === "mobile") {
        return (
            <div className={`header_cta_dropdown_mobile ${props.className || ""} theme`}>
                <div
                    className={`header_cta_dropdown_mobile__target header_cta ${isMenuVisible && "active"}`}
                    onClick={handleMenuToggle}
                >
                    <span>{props.title}</span>
                    {getIcon(isMenuVisible)}
                </div>
                <div
                    className={`header_cta_dropdown_mobile__links ${isMenuVisible && "active"}`}
                    style={{
                        maxHeight: isMenuVisible ? undefined : "0px"
                    }}
                >
                    {dropdownLinks}
                </div>
            </div>
        );
    }

    return (
        <div
            onMouseOver={handleMenuOpen}
            onMouseLeave={handleMenuClose}
            className={`header_cta_dropdown ${props.className || ""} theme`}
        >
            <div
                className={`header_cta_dropdown__target header_cta ${isMenuVisible && "active"} ${
                    props.theme === "light" ? "light-theme-header-cta" : ""
                }  ${props.size === "small" ? "small-header-cta" : ""}`}
                onClick={props.titleClickHandler}
            >
                <span>{props.title}</span>
                {getIcon(isMenuVisible)}
            </div>
            <div className={`header_cta_dropdown__links ${isMenuVisible && "active"}`}>
                <div
                    style={{
                        height: "40px",
                        position: "absolute",
                        top: "-37px",
                        width: "100%"
                    }}
                ></div>
                {dropdownLinks}
            </div>
        </div>
    );
};

export default HeaderDropdown;
