import { CookieHandlerInput } from "supertokens-web-js/utils/cookieHandler/types";
import { getUserInformation } from "../api/user/info";
import { getAnalytics, sendAuthAnalytics } from "../utils";
import Storage from "../utils/storage";
import { SuperTokensConfig } from "supertokens-auth-react/lib/build/types";
import { signInAndUp } from "supertokens-auth-react/recipe/thirdparty";
import { signIn } from "supertokens-auth-react/recipe/emailpassword";

export const SDK_LOGS_STORAGE_KEY = "Supertokens-sdk-logs";
function NOOP() {}
function shouldEnableSDKLogs() {
    return false; // change to true, to enable sdk logs
}

export function cookieExists(name: string) {
    const cookies = document.cookie;
    const regex = new RegExp("(^|; )" + encodeURIComponent(name) + "=");
    return regex.test(cookies);
}

export function getCookieValue(cookieName: string) {
    const cookies = document.cookie;
    const cookieArray = cookies.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith(cookieName + "=")) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    return null;
}

const isSuperTokensSDKLog = (data: any) => {
    return typeof data === "string" && data.includes("supertokens-website-ver:");
};

type ConsoleLog = typeof globalThis.console.log;
export const overrideConsoleImplementation = (customImplementation: ConsoleLog) => {
    const oldConsoleLogImplementation = globalThis.console.log;
    globalThis.console.log = data => {
        customImplementation(data, oldConsoleLogImplementation);
    };
};

export const saveSDKLogsConsoleOverride = (data: any, oldConsoleImplementation: ConsoleLog) => {
    if (isSuperTokensSDKLog(data)) {
        const logArrayStr = Storage.getItem(SDK_LOGS_STORAGE_KEY) || "[]";
        const logArray = JSON.parse(logArrayStr) as string[];

        if (logArray.length === 1000) {
            logArray.shift();
        }
        logArray.push(data);

        Storage.setItem(SDK_LOGS_STORAGE_KEY, JSON.stringify(logArray));
    } else {
        oldConsoleImplementation(data);
    }
};

export async function sendSDKLogsToBackend(customData?: Record<string, any>) {
    if (!shouldEnableSDKLogs()) {
        return;
    }
    const sdkLogs = Storage.getItem(SDK_LOGS_STORAGE_KEY) || "[]";
    const parsedSDKLogs = JSON.parse(sdkLogs);

    getAnalytics().then((stAnalytics: any) => {
        if (stAnalytics === undefined) {
            console.log("mocked event send:", "auth_error_sdk_logs", parsedSDKLogs);
            return;
        }
        stAnalytics.sendSupertokensSDKLogs({ logs: parsedSDKLogs, ...customData });
    });
}

export async function checkForDesyncedSession() {
    if (!shouldEnableSDKLogs()) {
        return;
    }
    const EVENT_NAME = "desynced_session_state";
    const didFrontTokenExistBeforeAPICall = cookieExists("sFrontToken");

    try {
        await getUserInformation();
        const doesFrontendTokenExistAfterAPICall = cookieExists("sFrontToken");
        if (!doesFrontendTokenExistAfterAPICall) {
            const payload = {
                didFrontTokenExistBeforeAPICall,
                stLastAccessTokenUpdate: getCookieValue("st-last-access-token-update"),
                statusCode: 200
            };
            getAnalytics().then((stAnalytics: any) => {
                if (stAnalytics === undefined) {
                    console.log("mocked event send:", EVENT_NAME, "v1", payload);
                    return;
                }
                stAnalytics.sendEvent(
                    EVENT_NAME,
                    {
                        type: EVENT_NAME,
                        ...payload
                    },
                    "v1"
                );
            });
        }
    } catch (e) {
        if (
            "response" in e &&
            e.response.status === 401 &&
            e.response.data &&
            e.response.data.message === "try refresh token"
        ) {
            if (!cookieExists("sFrontToken")) {
                const payload = {
                    didFrontTokenExistBeforeAPICall,
                    stLastAccessTokenUpdate: getCookieValue("st-last-access-token-update"),
                    statusCode: 401
                };
                getAnalytics().then((stAnalytics: any) => {
                    if (stAnalytics === undefined) {
                        console.log("mocked event send:", EVENT_NAME, "v1", payload);
                        return;
                    }
                    stAnalytics.sendEvent(
                        EVENT_NAME,
                        {
                            type: EVENT_NAME,
                            ...payload
                        },
                        "v1"
                    );
                });
            }
        }
    }
}

export function historyPushStateOverride(onPush: () => void) {
    const originalPushState = history.pushState;
    history.pushState = function(...args) {
        const result = originalPushState.apply(this, args);
        onPush();
        return result;
    };
}

const cookieSDKHook: CookieHandlerInput = original => {
    return {
        ...original,
        setCookie: (cookieString: string) => {
            const cookieName = cookieString.split(";")[0].split("=")[0];
            if (cookieName === "sFrontToken") {
                let cookieValue = cookieString
                    .split(";")[0]
                    .split("=")[1]
                    .trim();
                if (cookieValue === "" && cookieExists("sFrontToken")) {
                    const stack = new Error().stack;
                    sendSDKLogsToBackend({
                        stack,
                        title: "front_token_cookie_removed"
                    });
                }
            }
            return original.setCookie(cookieString);
        }
    };
};

export function clearStAccessTokenUpdateCookieAndReloadPage() {
    document.cookie = "st-last-access-token-update=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
}

export async function sendAnalyticsIfFrontTokenRemoved(url: string, frontTokenExists: boolean, headers: any) {
    if (!frontTokenExists) {
        return;
    }
    let updatedFrontTokenExists = cookieExists("sFrontToken");
    if (!updatedFrontTokenExists) {
        // this means it was removed between the api call!
        // send analytics
        sendAuthAnalytics("front_token_removed", {
            url,
            headers
        });
        await sendSDKLogsToBackend();
    }
}

export function getSDKLogHooks() {
    if (!shouldEnableSDKLogs()) {
        return {
            preApiHook: NOOP,
            postApiHook: NOOP,
            apiFailHandler: NOOP
        };
    }
    let preAPICookieState = {};
    const preApiHook = () => {
        preAPICookieState = {
            sFrontToken: cookieExists("sFrontToken"),
            "st-last-access-token-update": cookieExists("st-last-access-token-update")
        };
    };

    const postApiHook = async (
        response: Awaited<ReturnType<typeof signInAndUp>> | Awaited<ReturnType<typeof signIn>>
    ) => {
        if (response.status === "OK") {
            if (!cookieExists("sFrontToken")) {
                // this is an inconsistent state..
                const postAPICookieState = {
                    sFrontToken: false,
                    "st-last-access-token-update": cookieExists("st-last-access-token-update")
                };
                sendAuthAnalytics("auth_error", {
                    preAPICookieState,
                    postAPICookieState,
                    statusCode: "200"
                });
                try {
                    await sendSDKLogsToBackend();
                } catch (ignored) {
                    // we ignore in case the api throws, so the code
                    // below can fix the user's state.
                }
                clearStAccessTokenUpdateCookieAndReloadPage();
                return response;
            }
        }
    };

    const apiFailHandler = async (e: any) => {
        const postAPICookieState = {
            sFrontToken: cookieExists("sFrontToken"),
            "st-last-access-token-update": cookieExists("st-last-access-token-update")
        };
        if ("status" in e && e.status === 401) {
            sendAuthAnalytics("auth_error", {
                preAPICookieState,
                postAPICookieState,
                statusCode: "401"
            });
            try {
                await sendSDKLogsToBackend();
            } catch (ignored) {
                // we ignore in case the api throws, so the code
                // below can fix the user's state.
            }
            if (!postAPICookieState.sFrontToken) {
                clearStAccessTokenUpdateCookieAndReloadPage();
            }
        }
    };

    return {
        preApiHook,
        postApiHook,
        apiFailHandler
    };
}
export function preSDKLogsOverrides() {
    if (!shouldEnableSDKLogs()) {
        return;
    }
    overrideConsoleImplementation(saveSDKLogsConsoleOverride);
    historyPushStateOverride(checkForDesyncedSession);
}

export function getSdkLogConfigs(): Partial<SuperTokensConfig> {
    return shouldEnableSDKLogs()
        ? {
              enableDebugLogs: true,
              cookieHandler: cookieSDKHook
          }
        : {};
}

export function getHttpNetworkingSDKLogsHooks() {
    if (!shouldEnableSDKLogs()) {
        return {
            postApiExecutionHook: NOOP,
            preApiExecutionHook: NOOP
        };
    }
    let frontTokenExists = false;
    const preApiExecutionHook = () => {
        frontTokenExists = cookieExists("sFrontToken");
    };

    const postApiExecutionHook = async (url: string, headers: any) => {
        await sendAnalyticsIfFrontTokenRemoved(url, frontTokenExists, headers);
    };

    return {
        postApiExecutionHook,
        preApiExecutionHook
    };
}
