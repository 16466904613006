import * as React from "react";

export const Hero = () => {
    return (
        <div className="hero">
            <h1 className="hero__heading">
                SuperTokens <span className="yellow-text">Self Hosted</span>
            </h1>
            <p className="hero__subtext">Pick how you’d like to run SuperTokens</p>
        </div>
    );
};
