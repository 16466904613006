import * as React from "react";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens from "supertokens-auth-react";
import ThirdParty, { Google, Github } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";

import {
    getCurrentUserId,
    identifyUser,
    sendButtonAnalytics,
    sendEventToGoogleAnalytics,
    sendGenericAnalyticsEvent
} from "../utils";
import { API_DOMAIN, API_BASE_PATH, WEBSITE_DOMAIN } from "../constants";
import { ThemeMode } from "./CommonContainerWrapper";
import { clearCreateAppDataFromStorage } from "../dashboard/saas/createApp";
// import { dispatchTwqEvent } from "../utils/twq";
import { getSdkLogConfigs, getSDKLogHooks, preSDKLogsOverrides } from "./sdklogsUtils";
import { UTM_SOURCE_STORAGE_KEY } from "../utils/ads";
import { verifyFullNameInput } from "../dashboard/saas/utils";

preSDKLogsOverrides();
interface IButtonComponentProps {
    iconURI: string;
    text: string;
    dataAttr?: string;
}

export default function superTokensInit() {
    const themeMode: ThemeMode = (localStorage.getItem("themeMode") as ThemeMode) || ThemeMode.Dark;
    const isDarkMode = themeMode === ThemeMode.Dark;

    SuperTokens.init({
        appInfo: {
            // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
            appName: "SuperTokens",
            apiDomain: API_DOMAIN,
            apiBasePath: API_BASE_PATH,
            websiteDomain: WEBSITE_DOMAIN,
            websiteBasePath: "/auth"
        },
        getRedirectionURL: async context => {
            if (context.action === "SUCCESS") {
                if (context.redirectToPath !== undefined) {
                    // we are navigating back to where the user was before they authenticated
                    return context.redirectToPath;
                }
                return "/dashboard-saas";
            }
            return undefined;
        },
        useShadowDom: false,
        defaultToSignUp: true,
        style: getAuthStyles(isDarkMode),
        recipeList: [
            ThirdParty.init({
                signInAndUpFeature: {
                    providers: [
                        Google.init({
                            buttonComponent: (
                                <ButtonComponent
                                    dataAttr="providerGoogle"
                                    text="Continue with Google"
                                    iconURI="/static/assets/auth/google-icon-light.svg"
                                />
                            )
                        }),
                        Github.init({
                            buttonComponent: (
                                <ButtonComponent
                                    dataAttr="providerGitHub"
                                    text="Continue with GitHub"
                                    iconURI="/static/assets/navbar/github.png"
                                />
                            )
                        })
                    ]
                },

                preAPIHook: async context => {
                    let url = context.url;
                    // is the fetch config object that contains the header, body etc..
                    let requestInit = context.requestInit;
                    let action = context.action;

                    if (action === "GET_AUTHORISATION_URL") {
                        // We extract the invitationCode from the url before routing to the authorization url
                        const inviteCode = new URL(location.href).searchParams.get("invitationCode") || "";
                        sessionStorage.setItem("data-supertokens-invitationCode", inviteCode);
                        return {
                            url,
                            requestInit
                        };
                    }

                    if (action === "THIRD_PARTY_SIGN_IN_UP") {
                        let analyticsUserId;
                        const inviteCode = sessionStorage.getItem("data-supertokens-invitationCode") || "";
                        const utmSource = localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || "";

                        if (!!requestInit.body) {
                            const reqBody = JSON.parse(requestInit.body.toString());
                            reqBody.invitationCode = inviteCode;
                            reqBody.utm_source = utmSource;
                            requestInit.body = JSON.stringify(reqBody);
                        }
                        try {
                            analyticsUserId = await getCurrentUserId();
                        } catch (e) {
                            analyticsUserId = "";
                        }
                        if (requestInit.body !== null && requestInit.body !== undefined) {
                            let reqBody = JSON.parse(requestInit.body.toString());
                            reqBody.analyticsUserId = analyticsUserId;
                            requestInit.body = JSON.stringify(reqBody);
                        }
                    }

                    let headersToAdd: any = {};

                    if (Object.keys(requestInit.headers || {}).length === 0) {
                        if (requestInit.headers) {
                            // @ts-ignore
                            for (let pair of requestInit.headers.entries()) {
                                headersToAdd[pair[0]] = pair[1];
                            }
                        }
                    } else {
                        headersToAdd = {
                            ...requestInit.headers
                        };
                    }

                    requestInit = {
                        ...requestInit,
                        headers: {
                            ...headersToAdd,
                            "api-version": "0"
                        }
                    };

                    return {
                        requestInit,
                        url
                    };
                },
                override: {
                    functions: oI => {
                        return {
                            ...oI,
                            signInAndUp: async input => {
                                const inviteCode = sessionStorage.getItem("data-supertokens-invitationCode") || "";
                                const sdkLogHooks = getSDKLogHooks();

                                try {
                                    sdkLogHooks.preApiHook();
                                    const response = await oI.signInAndUp(input);
                                    sdkLogHooks.postApiHook(response);

                                    if (response.status === "OK" && response.createdNewRecipeUser === true) {
                                        //fire new user created event.
                                        sendGenericAnalyticsEvent(
                                            inviteCode === "" ? "sign_up_success" : "invited_user_sign_up_success"
                                        );
                                        localStorage.setItem("userJustSignedUp", "true");
                                        sendEventToGoogleAnalytics("sign_up", {
                                            method: "third_party",
                                            utm_source: localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || "",
                                            isInvitedUser: inviteCode !== ""
                                        });
                                        sendEventToGoogleAnalytics("conversion", {
                                            send_to: "AW-11492917308/o-1xCNHNjPsZELyAoOgq"
                                        });
                                        identifyUser({
                                            utm_source: localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || ""
                                        });
                                        if (inviteCode === "") {
                                            // fire twitter event
                                            // dispatchTwqEvent("event", "tw-oln73-oln7z", {});
                                        }
                                    }

                                    return response;
                                } catch (e) {
                                    sdkLogHooks.apiFailHandler(e);
                                    throw e;
                                } finally {
                                    sessionStorage.removeItem("data-supertokens-invitationCode");
                                }
                            },
                            getAuthorisationURLWithQueryParamsAndSetState: async input => {
                                // override to send analytics
                                let elem = document.querySelector("#supertokens-root");
                                if (elem !== null) {
                                    elem = elem.querySelector("#supertokens-root");
                                    if (elem !== null) {
                                        let shadowRoot = elem.shadowRoot;
                                        if (shadowRoot !== null) {
                                            elem = shadowRoot.querySelector("[data-supertokens~='headerTitle']");
                                            if (elem !== null) {
                                                let text = (elem as any).innerText;
                                                if (text === "Sign In") {
                                                    if (input.thirdPartyId === "github") {
                                                        sendButtonAnalytics("button_sign_in_github");
                                                    } else {
                                                        sendButtonAnalytics("button_sign_in_google");
                                                    }
                                                } else {
                                                    if (input.thirdPartyId === "github") {
                                                        sendButtonAnalytics("button_sign_up_github");
                                                    } else {
                                                        sendButtonAnalytics("button_sign_up_google");
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                return oI.getAuthorisationURLWithQueryParamsAndSetState(input);
                            }
                        };
                    }
                }
            }),
            EmailPassword.init({
                getRedirectionURL: async context => {
                    if (context.action === "RESET_PASSWORD") {
                        sendButtonAnalytics("button_signin_forgotpassword");
                    }
                    return undefined;
                },
                override: {
                    functions: oI => {
                        return {
                            ...oI,
                            sendPasswordResetEmail: async input => {
                                sendButtonAnalytics("button_signin_forgotpassword_resetpassword");
                                return oI.sendPasswordResetEmail(input);
                            },
                            submitNewPassword: async input => {
                                sendButtonAnalytics("button_signin_changepassword_cta");
                                return oI.submitNewPassword(input);
                            },
                            signIn: async input => {
                                const sdkLogHooks = getSDKLogHooks();
                                sendButtonAnalytics("button_sign_in_emailpassword");
                                try {
                                    sdkLogHooks.preApiHook();
                                    const response = await oI.signIn(input);
                                    sdkLogHooks.postApiHook(response);
                                    return response;
                                } catch (e) {
                                    sdkLogHooks.apiFailHandler(e);
                                    throw e;
                                }
                            },
                            signUp: async input => {
                                const isInvitedUser = !!input.formFields.find(
                                    field => field.id === "invitationCode" && !!field.value
                                );
                                sendButtonAnalytics(
                                    isInvitedUser
                                        ? "button_sign_up_emailpassword_invited_user"
                                        : "button_sign_up_emailpassword"
                                );

                                const response = await oI.signUp(input);

                                if (response.status === "OK") {
                                    //fire new user created event.
                                    sendGenericAnalyticsEvent(
                                        isInvitedUser ? "invited_user_sign_up_success" : "sign_up_success"
                                    );
                                    localStorage.setItem("userJustSignedUp", "true");
                                    sendEventToGoogleAnalytics("sign_up", {
                                        method: "email_password",
                                        utm_source: localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || "",
                                        isInvitedUser
                                    });
                                    sendEventToGoogleAnalytics("conversion", {
                                        send_to: "AW-11492917308/o-1xCNHNjPsZELyAoOgq"
                                    });
                                    identifyUser({
                                        utm_source: localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || ""
                                    });

                                    if (!isInvitedUser) {
                                        // fire twitter event..
                                        // dispatchTwqEvent("event", "tw-oln73-oln7z", {});
                                    }
                                }

                                return response;
                            }
                        };
                    }
                },
                preAPIHook: async context => {
                    let url = context.url;

                    // is the fetch config object that contains the header, body etc..
                    let requestInit = context.requestInit;

                    let action = context.action;

                    if (action === "EMAIL_PASSWORD_SIGN_UP") {
                        let analyticsUserId;
                        const params = new URL(location.href).searchParams;
                        const inviteCode = params.get("invitationCode") || "";
                        const utmSource = localStorage.getItem(UTM_SOURCE_STORAGE_KEY) || "";

                        if (!!requestInit.body) {
                            const reqBody = JSON.parse(requestInit.body.toString());
                            reqBody.formFields.push({ id: "invitationCode", value: inviteCode });
                            reqBody.formFields.push({ id: "utm_source", value: utmSource });
                            requestInit.body = JSON.stringify(reqBody);
                        }
                        try {
                            analyticsUserId = await getCurrentUserId();
                        } catch (e) {
                            analyticsUserId = "";
                        }
                        if (requestInit.body !== null && requestInit.body !== undefined) {
                            let reqBody = JSON.parse(requestInit.body.toString());
                            reqBody.formFields.push({ id: "analyticsUserId", value: analyticsUserId });
                            requestInit.body = JSON.stringify(reqBody);
                        }
                    }

                    let headersToAdd: any = {};

                    if (Object.keys(requestInit.headers || {}).length === 0) {
                        if (requestInit.headers) {
                            // @ts-ignore
                            for (let pair of requestInit.headers.entries()) {
                                headersToAdd[pair[0]] = pair[1];
                            }
                        }
                    } else {
                        headersToAdd = {
                            ...requestInit.headers
                        };
                    }

                    requestInit = {
                        ...requestInit,
                        headers: {
                            ...headersToAdd,
                            "api-version": "0"
                        }
                    };

                    return {
                        requestInit,
                        url
                    };
                },
                signInAndUpFeature: {
                    signUpForm: {
                        formFields: [
                            {
                                id: "name",
                                label: "Full Name",
                                placeholder: "e.g Jane Doe",
                                validate: async value => {
                                    // Prevent input with HTML tags
                                    const result = verifyFullNameInput(value);
                                    if (result.success !== true) {
                                        return result.reason;
                                    }
                                    return undefined;
                                }
                            }
                        ]
                    }
                }
            }),
            Session.init({
                preAPIHook: async context => {
                    let headersToAdd: Record<string, unknown> = {};
                    const requestHeaders = context.requestInit.headers;

                    if (Object.keys(requestHeaders || {}).length === 0) {
                        if (requestHeaders && requestHeaders instanceof Headers) {
                            for (let pair of requestHeaders.entries()) {
                                headersToAdd[pair[0]] = pair[1];
                            }
                        }
                    } else {
                        headersToAdd = {
                            ...requestHeaders
                        };
                    }

                    return {
                        ...context,
                        requestInit: {
                            ...context.requestInit,
                            headers: {
                                ...headersToAdd,
                                "api-version": "0"
                            }
                        }
                    };
                },
                onHandleEvent: context => {
                    if (context.action === "SIGN_OUT") {
                        clearCreateAppDataFromStorage();
                    }
                }
            })
        ],
        termsOfServiceLink: "https://supertokens.com/legal/terms-and-conditions",
        privacyPolicyLink: "https://supertokens.com/legal/privacy-policy",
        languageTranslations: {
            translations: {
                en: {
                    EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: "Sign Up",
                    EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: "Sign In",
                    ERROR_EMAIL_INVALID: "Invalid Email ID"
                }
            },
            defaultLanguage: "en"
        },
        ...getSdkLogConfigs()
    });
}

const ButtonComponent: React.FC<IButtonComponentProps> = ({ iconURI, text, dataAttr }) => {
    return (
        <button data-supertokens={`button providerButton ${dataAttr || ""}`}>
            <div data-supertokens="providerButtonLeft">
                <div data-supertokens="providerButtonLogo">
                    <div data-supertokens="providerButtonLogoCenter">
                        <img className="provider-logo" src={iconURI} />
                    </div>
                </div>
            </div>
            <div className="text-and-arrow-container">
                <div data-supertokens="providerButtonText">{text}</div>
                <img className="right-arrow-icon" src={"/static/assets/dark-home/go-black.png"} />
            </div>
        </button>
    );
};

const darkStyleAndPalette = `
[data-supertokens~="container"]{
    border: 1px solid #3F3E3E;
    color: #FFFFFF;
    --palette-error: 237, 52, 78;
    --palette-background: linear-gradient(180deg, #1E1E1E 0%, rgba(22, 22, 22, 0.59) 100%);
    --palette-textInput: 255, 255, 255;
    --palette-textLabel: 255, 255, 255;
    --palette-textLink: 221, 221, 221; 
    --palette-inputBackground: 31, 31, 31;
    --palette-inputBorder: 48, 48, 48;
    --palette-primary: 47, 47, 47;
    --palette-buttonText: 0, 0, 0;
}

[data-supertokens~="headerSubtitle"][data-supertokens~="secondaryText"]{
    color: rgb(var(--palette-textLink));
}

.css-1ifzw3e-SigninHelper [data-supertokens~="secondaryText"]{
    color: white;
}

[data-supertokens ~="spinner"] circle {
    stroke: rgb(170,170,170);
}

[data-supertokens="button"][type="submit"]{
    background-color: #FFFFFF;
}

[data-supertokens~="inputWrapper"]:hover{
    border: 1px solid #666666 !important;
    box-shadow: 0px 0px 2px 0px #00000040 inset !important;
}

[data-supertokens~="inputWrapper"]:focus-within{
    border: 2px solid #AAAAAA !important;
    box-shadow: 0px 0px 2px 0px #00000040 inset !important;

}
[data-supertokens~="inputAdornmentSuccess"] svg path, [data-supertokens~="showPassword"] svg path, [data-supertokens~="showPassword"] svg g{
    fill: #FFFFFF;
}

[data-supertokens~="providerButton"]{
    filter: invert(1);
    background-color: #D2D2D2 !important;
}
[data-supertokens~="providerButton"]:hover{
    background-color: #ffffff !important;
}

[data-supertokens="button"][type="button"]{
    background-color: #FFFFFF;
}

`;

const lightStyleAndPalette = `
[data-supertokens~="container"]{
    color: #000000;
    --palette-background: #FFFFFF;
}

[data-supertokens~="headerSubtitle"] [data-supertokens="link"]{
    text-decoration: none;
}

[data-supertokens~="providerButton"]{
    border: 1px solid;
}

[data-supertokens~="providerGoogle"] img.provider-logo{
    filter: none;
}

[data-supertokens~="privacyPolicyAndTermsAndConditions"] a[data-supertokens="link"]{
    color: inherit;
}

input::placeholder{
    color: rgb(117, 117, 117)!important;
}
`;

const getAuthStyles = (isDarkMode: boolean): string => {
    return `
    [data-supertokens~="container"]{
        font-size: 14px;
        width: 400px;
        box-sizing: border-box;
        background: var(--palette-background);
        box-shadow: 0px 0px 40px 0px #00000014;
        backdrop-filter: blur(100px);
        border-radius: 12px;
        text-align: left;
        padding: 40px;
        padding-block-end: 24px;
        --palette-secondary: 170, 170, 170;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    [data-supertokens~="headerSubtitle"]{
        margin-bottom: 28px;
    }
    
    [data-supertokens~="label"]{
        font-weight: normal;
        font-variation-settings: "wght" 600;
    }

    [data-supertokens~="container"] *{
        font-family: Inter, sans-serif!important;
    }

    [data-supertokens~="headerSubtitle"][data-supertokens="secondaryText"]{
        font-variation-settings: "wght" 500;
    }

    [data-supertokens~="headerSubtitle"] + [data-supertokens="divider"]{
        display: none;
    }

    [data-supertokens="link"]{
        text-decoration: underline;
    }

    [data-supertokens~="headerTitle"]{
        color: inherit;
        font-variation-settings: "wght" 800;
    }

    [data-supertokens~="backButtonPlaceholder"] {
   display: none;
    }

    [data-supertokens~="container"]>[data-supertokens="row"]{
        margin: 0;
        width: 100%;
        padding: 0;
    }

    [data-supertokens~="privacyPolicyAndTermsAndConditions"], [data-supertokens~="forgotPasswordLink"]{
        text-align: center;
        max-width: initial;
        color: rgb(var(--palette-secondary));
    }

    [data-supertokens~="privacyPolicyAndTermsAndConditions"] data-supertokens="link"]{
        color: inherit;
    }

    [data-supertokens~=button] {
        border: 0px;
        width: 100%;
        margin: 0 auto;
    }

    [data-supertokens~="providerButton"]{
        width: 100%;
        margin-inline: 0;
        max-width: unset!important;   
    }

    [data-supertokens~="providerGoogle"] img.provider-logo{
        filter: grayscale(100%) brightness(0);
        transform: scale(1.5);
    }

    [data-supertokens="superTokensBranding"]{
        display: none;
    }

    [data-supertokens="providerButtonLeft"]{
        margin-left: 0!important;
    }

    [data-supertokens~="formRow"]{
        padding-bottom: 14px;
    }

    [data-supertokens="formRow"] [data-supertokens="label"]{
        line-height: 17px;
    }

    [data-supertokens~="divider"] {
        border-bottom-color: ${isDarkMode ? "rgb(56,56,56)" : "rgb(232,232,232)"};
    }

    [data-supertokens~="dividerWithOr"]{
        flex: initial;
        margin-inline: 5px;
        margin-top: 9px;
        color: rgb(170,170,170);
    }
    [data-supertokens="dividerWithOr"] [data-supertokens="divider"]{
        margin-top: 0;
        margin-bottom: 0;
        padding-top:0;
        padding-bottom:0;
    }

    [data-supertokens="button"][type="submit"]{
        font-variation-settings: 'wght' 800;
    }

    [data-supertokens~="providerButton"] .text-and-arrow-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }

    [data-supertokens="providerContainer"]{
        padding-block: 7px;
    }

    [data-supertokens~="providerContainer"] [data-supertokens="providerButtonText"]{
        font-variation-settings: 'wght' 500;
    }

    [data-supertokens="providerButtonLogoCenter"] > img{
        width: 16px;
        height: 16px;
    }

    [data-supertokens~="resetPasswordEmailForm"] [data-supertokens~="headerTitle"] {
        flex-direction: column;
        align-items: flex-start;
    }

    [data-supertokens~="resetPasswordHeaderTitle"] span[data-supertokens~="backButtonPlaceholder"] {
        display: none;
    }

    [data-supertokens~="resetPasswordEmailForm"] [data-supertokens~="headerTitle"] [data-supertokens~="backButton"] {
        color: inherit;
        height: unset;
    }

    [data-supertokens~="resetPasswordEmailForm"] [data-supertokens~="headerTitle"] [data-supertokens~="backButton"] path {
        fill: currentColor;
    }

    .text-and-arrow-container [data-supertokens="providerButtonText"]{
        margin: 0;
    }

    .text-and-arrow-container img.right-arrow-icon{
        width: 9px;
        height: 8px;
    }

    .otp-resent-msg{
        text-align: center;
    }

    input[type=text]{
        background-color: initial;
        font-size: 14px;
        border-width: 0!important;
        height: initial;
        padding-left: 15px;
        width: inherit;
    }

    input::placeholder{
        color: rgb(var(--palette-secondary))!important;
    }

    ${isDarkMode ? darkStyleAndPalette : lightStyleAndPalette};

    @media (max-width: 440px) {
        [data-supertokens~=button] {
            width: 90%;
        }
    } `;
};
