import React from "react";

export default function CareersIcon({ theme }: { theme?: "dark" | "light" }) {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33493 0.282585C6.99688 0.437289 6.57659 0.750697 6.39715 0.981989C6.04772 1.43222 5.7329 2.30786 5.7329 2.82926V3.15067L3.53891 3.19337C1.39879 3.23502 1.33319 3.24373 0.864175 3.54866C-0.0572096 4.14795 -0.0224946 3.81207 0.0141357 11.788L0.0468158 18.8821L0.313762 19.25C0.883328 20.0347 0.384868 19.9998 11 19.9998C21.6151 19.9998 21.1167 20.0347 21.6862 19.25L21.9532 18.8821L21.9859 11.788C22.0225 3.81207 22.0572 4.14795 21.1358 3.54866C20.6672 3.24384 20.6003 3.2349 18.4751 3.19361L16.2951 3.15114L16.216 2.46338C16.1054 1.50339 15.5799 0.751874 14.7194 0.323526L14.0694 0L11.0085 0.00117648C8.03606 0.00223529 7.92976 0.0103529 7.33493 0.282585ZM13.9243 2.30244C14.0277 2.40421 14.1124 2.64244 14.1124 2.83185V3.17643H11H7.88762V2.83185C7.88762 2.1408 7.98853 2.11762 11 2.11762C13.3278 2.11762 13.7643 2.14527 13.9243 2.30244ZM19.8583 7.27544V9.25695L18.9306 9.68412C14.0111 11.9492 8.13158 11.9522 3.10066 9.69212L2.14169 9.2613V7.27768V5.29406H11H19.8583V7.27544ZM8.85844 8.28179C8.19862 8.64119 8.07819 9.38166 8.59664 9.8913L8.94655 10.2352H11H13.0535L13.4034 9.8913C13.9322 9.37142 13.8221 8.63319 13.1659 8.29967C12.6887 8.0572 9.29657 8.04308 8.85844 8.28179ZM2.71413 11.8355C7.72566 13.9594 13.5932 14.0425 18.7203 12.0627C19.3123 11.834 19.8107 11.6469 19.8275 11.6469C19.8444 11.6469 19.8583 13.0498 19.8583 14.7645V17.8821H11H2.14169V14.7645C2.14169 13.0498 2.1703 11.6469 2.20537 11.6469C2.24045 11.6469 2.46933 11.7317 2.71413 11.8355Z"
                fill={theme === "light" ? "#6E6A65" : "#A0A0A0"}
            />
        </svg>
    );
}
