import React from "react";

import "../../../style/payments.modal.css";

import Storage from "../../../utils/storage";
import { PAYMENT_DETAILS_REDIRECT_CONTEXT, usePaymentDetailsContext } from "../../../utils/payments";
import { usePaymentsModal } from "../../../context/PaymentsModalContext";
import saasAnalytics from "../../../dashboard/saas/saasAnalytics";

export default function PaymentsModalContent() {
    const { closeModal } = usePaymentsModal();

    return (
        <div className="modal-container max-width">
            <span className="close-btn" onClick={closeModal}>
                <img src="static/assets/dashboard/close-rounded.png" />
            </span>
            <div className="half-circle" />
            <PaymentModalBody />
        </div>
    );
}

export function PaymentModalBody() {
    usePaymentDetailsContext();
    const { paymentDetailsRedirectContext, closeModal } = usePaymentsModal();

    function handleContinue() {
        if (paymentDetailsRedirectContext) {
            saasAnalytics.addPaymentsDetailsClick(paymentDetailsRedirectContext.action_type);
            Storage.setItem(PAYMENT_DETAILS_REDIRECT_CONTEXT, JSON.stringify(paymentDetailsRedirectContext));
            window.location.replace(paymentDetailsRedirectContext.redirect_uri);
        } else {
            closeModal();
        }
    }

    return (
        <>
            <div className="wallet-container">
                <img src="static/assets/dashboard/wallet.svg" />
            </div>
            <h1 className="payments-modal-title">Payment details required</h1>
            <div className="modal-body">
                <div className="info-green-container">
                    <ul style={{ paddingLeft: "28px" }}>
                        <li>
                            {" "}
                            Before continuing we require you to add your payment details. Once you cross the free tier,
                            you will be charged automatically based on your usage. See the pricing details on our{" "}
                            <a href="/pricing" target="_blank">
                                pricing page.
                            </a>
                        </li>
                        <li>Note: We charge only for the features used in production.</li>
                    </ul>
                </div>
            </div>
            <div className="modal-action-container margin-top-20 justify-center">
                <button onClick={handleContinue} className="yellow-button continue-btn">
                    Add payment details
                </button>
            </div>
        </>
    );
}
