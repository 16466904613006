import React from "react";
import { Link } from "react-router-dom";

import "../../../ts/style/dashboard/saas-sidebar.css";
import { NavigationItem, NavigationItemName } from "./types";

export default function SaasSidebar({
    activeItem,
    navigationItems
}: {
    activeItem: NavigationItemName;
    navigationItems: NavigationItem[];
}) {
    return (
        <aside className="sidebar">
            <ul className="sidebar-list">
                {navigationItems.map(item => {
                    return (
                        <li key={item.title}>
                            <Link to={item.href} className={`${activeItem === item.name ? "active" : ""}`}>
                                {item.icon} <span>{item.title}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </aside>
    );
}
